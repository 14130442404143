import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useApi from 'hooks/useApi';

interface RegisterValues {
  [key: string]: any;
  recaptcha_v3_token?: string;
}

interface RegisterResponse {
  [key: string]: any;
}

type RegisterContext = Record<string, any>;

interface UseRegisterProps {
  register: (values: RegisterValues, context: RegisterContext) => void;
}

const useRegister = (
  onSuccess: (response: RegisterResponse, context: RegisterContext) => void,
  onError?: (response: any, context: RegisterContext) => void
): UseRegisterProps => {
  const { data, callApi } = useApi();
  const [formContext, setFormContext] = useState<RegisterContext>({});
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      onSuccess(data.response, formContext);
    }
  }, [onSuccess, data, formContext]);

  useEffect(() => {
    if (data.status === 'error') {
      if (onError) onError(data.response, formContext);
    }
  }, [onError, data, formContext]);

  const register = (values: RegisterValues, context: RegisterContext): void => {
    setFormContext(context);

    if (process.env.REACT_APP_DEBUG === 'true') {
      callApi('/demo/register', {
        method: 'post',
        data: values,
      });
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    executeRecaptcha('signup').then((token) => {
      callApi('/demo/register', {
        method: 'post',
        data: {
          ...values,
          recaptcha_v3_token: token,
        },
      });
    });
  };

  return { register };
};

export default useRegister;
