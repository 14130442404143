import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import LogoText from 'assets/logoText.svg';

// components
import AvatarForm from 'components/pageOnboarding/AvatarForm';
import ConfidentialityCard from 'components/pageOnboarding/ConfidentialityCard';
import EndCard from 'components/pageOnboarding/EndCard';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import GeneralIntroCards from 'components/pageOnboarding/GeneralIntroCards';
import IdentityForm from 'components/pageOnboarding/IdentityForm';
import IdentityFormIntroCard from 'components/pageOnboarding/IdentityFormIntroCard';
import ThemesForm from 'components/pageOnboarding/ThemesForm';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';

interface User {
  first_name: string;
  [key: string]: any;
}

interface IdentityFormData {
  nickname: string;
  pronouns: string;
  other: string;
}

interface AvatarFormData {
  avatar: string;
  avatar_color: string;
}

interface ThemesFormData {
  themes: string[];
}

interface PronounOption {
  value: string[] | string;
  label: string;
}

interface PronounOptions {
  [key: string]: PronounOption;
}

interface OnboardingProps {
  testUser?: User;
}

export default function Onboarding({ testUser }: OnboardingProps) {
  const { t } = useTranslation('pageOnboarding');
  const { data } = useUser();
  const user = testUser || data;

  const [key, setKey] = useState<number>(); // this resets scroll position to top
  const [onboardingSection, setOnboardingSection] = useState<number>(0);
  const [submittedIdentityForm, setSubmittedIdentityForm] =
    useState<IdentityFormData>({
      nickname: user.first_name,
      pronouns: '',
      other: '',
    });

  const [submittedAvatarForm, setSubmittedAvatarForm] =
    useState<AvatarFormData>({
      avatar: 'happy',
      avatar_color: '#7A7A76',
    });

  const [submittedThemesForm, setSubmittedThemesForm] =
    useState<ThemesFormData>({
      themes: [],
    });

  const pronouns: PronounOptions = {
    she: { value: ['she', 'her'], label: t('she') },
    he: { value: ['he', 'him'], label: t('he') },
    they: { value: ['they', 'them'], label: t('they') },
    other: { value: 'other', label: t('other') },
    refuse: { value: ['-'], label: t('prefer') },
  };

  const handleSectionCompletion = (currentSection: number): void => {
    setKey(Math.random());
    setOnboardingSection(currentSection + 1);
  };

  const handleSectionReturn = (currentSection: number): void => {
    setKey(Math.random());
    setOnboardingSection(currentSection - 1);
  };

  return (
    <ErrorBoundary>
      <Page.Page key={key}>
        <Page.OnboardingLogo>
          <Page.LogoText src={LogoText} />
        </Page.OnboardingLogo>
        {onboardingSection === 0 && (
          <GeneralIntroCards markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 1 && (
          <IdentityFormIntroCard markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 2 && (
          <IdentityForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            pronouns={pronouns}
            setSubmittedIdentityForm={setSubmittedIdentityForm as any}
            submittedIdentityForm={submittedIdentityForm}
          />
        )}
        {onboardingSection === 3 && (
          <ThemesForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            setSubmittedThemesForm={setSubmittedThemesForm}
            submittedThemesForm={submittedThemesForm}
          />
        )}
        {onboardingSection === 4 && (
          <AvatarForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            setSubmittedAvatarForm={setSubmittedAvatarForm}
            submittedAvatarForm={submittedAvatarForm}
          />
        )}
        {onboardingSection === 5 && (
          <ConfidentialityCard markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 6 && (
          <EndCard submittedIdentityForm={submittedIdentityForm} />
        )}
      </Page.Page>
    </ErrorBoundary>
  );
}
