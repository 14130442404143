import React from 'react';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import { Security } from 'untitledui-js';

interface PanelButtonProps {
  action?: () => void;
  demoTour?: string;
  icon: () => JSX.Element;
  inactive?: boolean;
  label: string;
}

const PanelButton = ({
  action,
  demoTour,
  icon,
  label,
  inactive,
}: PanelButtonProps) => (
  <Buttons.Primary
    className={demoTour}
    fullWidth
    inactive={inactive}
    onClick={action}
  >
    {inactive ? <Security.Lock01 size={'15'} /> : icon()}
    {label}
  </Buttons.Primary>
);

export default PanelButton;
