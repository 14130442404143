import React from 'react';
import { useTranslation } from 'react-i18next';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';
import * as Styled from '../_styles/Report.style';

interface SuggestionItemProps {
  item: {
    title: string;
    short_description: string;
  };
}

export default function SuggestionItem({
  item: suggestion,
}: SuggestionItemProps) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Content>
      <Text.SubheadingSemibold>{suggestion.title}</Text.SubheadingSemibold>
      <Text.P2>
        {suggestion.short_description}
        <b style={{ color: colours.blue[500] }}>{t('See more.')}</b>
      </Text.P2>
    </Styled.Content>
  );
}
