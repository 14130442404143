import React from 'react';
import { Route, Switch } from 'react-router-dom';

// components
import SidePanel from 'components/sidePanel/_index';

// constants
import routesConfig from 'constants/routesConfig.json';

interface SidePanelRoutesProps {
  getUser?: () => void;
}

function SidePanelRoutes({ getUser }: SidePanelRoutesProps): JSX.Element {
  return (
    <Switch>
      <Route exact path={routesConfig.JOURNEY_LIST_PAGE}>
        <SidePanel getUser={getUser} />
      </Route>
      <Route path={routesConfig.JOURNEY_ID_PAGE}>
        <SidePanel getUser={getUser} />
      </Route>
      <Route path={routesConfig.SLIDESHOW_PAGE}>
        <SidePanel getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.CONVO_PAGE}>
        <SidePanel getUser={getUser} />
      </Route>
      <Route path={`${routesConfig.CONVO_PAGE}/:id`}>
        <SidePanel getUser={getUser} />
      </Route>
      <Route path="*">
        <SidePanel getUser={getUser} />
      </Route>
    </Switch>
  );
}

export default SidePanelRoutes;
