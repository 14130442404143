import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Arrow } from 'untitledui-js';
import FlagCan from 'components/pageinsights/_assets/flag-canada.png';
import FlagUs from 'components/pageinsights/_assets/flag-us.png';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageinsights/_styles/Onboarding.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineData {
  topStat: string;
  topStatTrend: string;
  pastLabel: string;
  onboarded: number;
  total: number;
  torontoOnboarded: number;
  pittsburghOnboarded: number;
  montrealOnboarded: number;
  torontoTotal: number;
  pittsburghTotal: number;
  montrealTotal: number;
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function Onboarding(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      topStat: '12',
      topStatTrend: '12',
      pastLabel: t('pastLabel.past month'),
      onboarded: 2,
      total: 13,
      torontoOnboarded: 12,
      pittsburghOnboarded: 34,
      montrealOnboarded: 56,
      torontoTotal: 100,
      pittsburghTotal: 100,
      montrealTotal: 100,
    },
    threeMonth: {
      topStat: '23',
      topStatTrend: '23',
      pastLabel: t('pastLabel.past 3 months'),
      onboarded: 4,
      total: 13,
      torontoOnboarded: 22,
      pittsburghOnboarded: 44,
      montrealOnboarded: 66,
      torontoTotal: 100,
      pittsburghTotal: 100,
      montrealTotal: 100,
    },
    sixMonth: {
      topStat: '34',
      topStatTrend: '34',
      pastLabel: t('pastLabel.past six months'),
      onboarded: 6,
      total: 13,
      torontoOnboarded: 42,
      pittsburghOnboarded: 74,
      montrealOnboarded: 86,
      torontoTotal: 100,
      pittsburghTotal: 100,
      montrealTotal: 100,
    },
    oneYear: {
      topStat: '45',
      topStatTrend: '45',
      pastLabel: t('pastLabel.past year'),
      onboarded: 8,
      total: 13,
      torontoOnboarded: 62,
      pittsburghOnboarded: 94,
      montrealOnboarded: 96,
      torontoTotal: 100,
      pittsburghTotal: 100,
      montrealTotal: 100,
    },
    all: {
      topStat: '56',
      topStatTrend: '56',
      pastLabel: t('pastLabel.all time'),
      onboarded: 10,
      total: 13,
      torontoOnboarded: 94,
      pittsburghOnboarded: 98,
      montrealOnboarded: 100,
      torontoTotal: 100,
      pittsburghTotal: 100,
      montrealTotal: 100,
    },
  };

  const getContent = (region: string): React.ReactElement => (
    <Styled.SectionHighlighted>
      <Styled.Section>
        <Styled.SectionLabel>
          <Styled.LightText>
            {t('onboarding.Managers onboarded')}
          </Styled.LightText>
        </Styled.SectionLabel>

        <Styled.SectionRow>
          <Styled.Visual>
            <Styled.Onboarded
              quantity={Number(data[timeline][`${region}Onboarded`])}
              total={Number(data[timeline][`${region}Total`])}
            />
          </Styled.Visual>

          <Styled.Stats>
            <Styled.Stat>
              {data[timeline][`${region}Onboarded`]}/
              {data[timeline][`${region}Total`]}
            </Styled.Stat>
          </Styled.Stats>
        </Styled.SectionRow>
      </Styled.Section>

      <Styled.Section>
        {data[timeline][`${region}Onboarded`] ===
        data[timeline][`${region}Total`] ? (
          <Styled.GreenText>
            {t('onboarding.All managers onboarded')}
          </Styled.GreenText>
        ) : (
          <>
            <Styled.PrimaryText>
              {`${
                data[timeline][`${region}Total`] -
                data[timeline][`${region}Onboarded`]
              } ${t('onboarding.managers remaining')}`}
            </Styled.PrimaryText>
            <Buttons.Secondary centerText>
              {t('onboarding.Send reminder to join')}
            </Buttons.Secondary>
          </>
        )}
      </Styled.Section>
    </Styled.SectionHighlighted>
  );

  return (
    <Styled.Card>
      <Text.P2Semibold>{t('onboarding.Onboarding')}</Text.P2Semibold>
      <Styled.Section>
        <Styled.TopStat>{data[timeline].topStat}%</Styled.TopStat>
        <Styled.TopStatDetail>
          <Arrow.ArrowUp />
          <Styled.TopStatDetailNumber>
            {data[timeline].topStatTrend}
          </Styled.TopStatDetailNumber>
          <Styled.LightText>{data[timeline].pastLabel}</Styled.LightText>
        </Styled.TopStatDetail>
      </Styled.Section>

      <Styled.Accordions>
        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagCan} />
              <Text.P2Semibold>Toronto, Canada</Text.P2Semibold>
              <Text.P2Semibold>
                {Math.round(
                  (data[timeline].torontoOnboarded /
                    data[timeline].torontoTotal) *
                    100
                )}
                %
              </Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('toronto')}</AccordionDetails>
        </Styled.AccordionSection>

        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagUs} />
              <Text.P2Semibold>Pittsburgh, Pennsylvania, USA</Text.P2Semibold>
              <Text.P2Semibold>
                {Math.round(
                  (data[timeline].pittsburghOnboarded /
                    data[timeline].pittsburghTotal) *
                    100
                )}
                %
              </Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('pittsburgh')}</AccordionDetails>
        </Styled.AccordionSection>

        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagCan} />
              <Text.P2Semibold>Montréal, Canada</Text.P2Semibold>
              <Text.P2Semibold>
                {Math.round(
                  (data[timeline].montrealOnboarded /
                    data[timeline].montrealTotal) *
                    100
                )}
                %
              </Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('montreal')}</AccordionDetails>
        </Styled.AccordionSection>
      </Styled.Accordions>

      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
