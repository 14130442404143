import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  min-width: 350px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    min-width: unset;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const SectionHighlighted = styled('div')`
  background-color: ${colours.neutralWarm[100]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.mint[900]};
`;

export const TopStatDetail = styled(Text.P1Semibold)`
  color: ${colours.mint[900]};
`;

export const TopStatDetailWhite = styled(Text.P1Semibold)`
  color: ${colours.shade[0]};
`;

export const SectionHeader = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
  font-weight: 900;
`;

export const SectionRow = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const SectionLabel = styled('div')`
  display: flex;
  gap: 10px;
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const DarkText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
`;

export const Link = styled('span')`
  color: ${colours.blue[500]};
  cursor: pointer;
  text-decoration: underline;
`;

export const StatPainted = styled(Text.P2Semibold)`
  align-items: center;
  display: flex;
  gap: 3px;
  font-weight: 900;
`;

export const Logo = styled('img')`
  box-sizing: unset;
  height: 18px;
  max-width: 82px;
  width: fit-content;
`;

export default Card;
