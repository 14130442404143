import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// components
import ContentLayout from 'components/_pageLayout/ContentLayout';

// styles
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';
import * as Styled from 'components/_pageLayout/_styles/ContentLayout.style';
import * as Text from 'components/_styles/Text.style';

export default function NotFound(): JSX.Element {
  const { t } = useTranslation('page404');
  return (
    <PageLayout.Page>
      <ContentLayout>
        <Styled.Content>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
          >
            <Text.H1>{t('page404.header')}</Text.H1>
            <Text.P2>{t('page404.description')}</Text.P2>
            <Text.P2>
              <Link to="/">{t('page404.link')}</Link>
            </Text.P2>
          </div>
        </Styled.Content>
      </ContentLayout>
    </PageLayout.Page>
  );
}
