import styled from 'styled-components';
import device from 'constants/devices';

export const Row = styled('div')`
  display: flex;
  gap: 30px;
  width: 100%;
`;

export const Card = styled('div')`
  background-color: white;
  border-radius: 20px;
  display: flex;
  gap: 30px;
  overflow: hidden;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
  }
`;

export const Cover = styled('div')`
  aspect-ratio: 16 / 9;
  flex: 1;
  max-width: 300px;
  overflow: hidden;

  @media (max-width: ${device.laptop}) {
    max-width: unset;
  }
`;

export const CoverImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const InfoSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  width: 100%;
`;

export const Details = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
