import React from 'react';

// component
import Textbox from 'components/pageSlideshow/Textbox';

// style
import * as Styled from 'components/pageSlideshow/_styles/Slides.style';
import * as Text from 'components/_styles/Text.style';

interface SlideFirstProps {
  cover?: string;
  journeyTitle?: string;
  microlearningTitle?: string;
  setShowIntroSlide: (show: boolean) => void;
}

export default function SlideFirst(props: SlideFirstProps) {
  const { cover, journeyTitle, microlearningTitle, setShowIntroSlide } = props;
  const nextClick = () => setShowIntroSlide(false);

  return (
    <>
      <Styled.Slide>
        <Styled.SlideOverlay />
        <Styled.SlideImage src={cover} />
        <Styled.Titles>
          {journeyTitle && (
            <Text.CaptionSemiboldCap>{journeyTitle}</Text.CaptionSemiboldCap>
          )}
          {microlearningTitle && (
            <Text.H2Bold>{microlearningTitle}</Text.H2Bold>
          )}
        </Styled.Titles>
      </Styled.Slide>
      <Textbox ctaView={true} nextClick={nextClick} progress={-1} />
    </>
  );
}
