import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// external components
import AllChats from 'components/pageECoach/allChats/_index';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import Insights from 'components/pageinsights/_index';

// style
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';

// context
import { useConvoHistory } from 'contexts/ConvoHistoryContext';
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';
import {
  FeedbackLoadingStatus,
  useFeedbackConsumer,
} from 'hooks/useFeedbackConsumer';

// constants
import routesConfig from 'constants/routesConfig.json';

// local components
import ECoachSession from './ECoachSession';
import FeedbackForm from './FeedbackForm';

interface ChatGuideData {
  chatStartEnded: boolean;
  displayPromptHelper: boolean;
  inProgress: boolean;
  promptHelperGoal: string;
  promptHelperTheme: string;
}

const chatGuideSetup: ChatGuideData = {
  chatStartEnded: false,
  displayPromptHelper: false,
  inProgress: false,
  promptHelperGoal: '',
  promptHelperTheme: '',
};

interface RouteParams {
  id?: string;
}

export default function ECoachPage(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<RouteParams>();
  const { data: user } = useUser();
  const { id } = params;
  const pathname = location.pathname.toLowerCase();

  const [activeSession, setActiveSession] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [steps, setSteps] = useState<any[]>(activeSession?.steps || []);
  const [chatGuideData, setChatGuideData] =
    useState<ChatGuideData>(chatGuideSetup);
  const { getConversations } = useConvoHistory();
  const { data: session, callApi: getSession } = useApi();

  const { feedbackStatus, fetchFeedbackStatus, tryCreate } =
    useFeedbackConsumer();

  const resetChatGuideData = (): void => setChatGuideData(chatGuideSetup);

  const handleChatGuidance = (updates: Partial<ChatGuideData>): void => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.set('new-conversation', 'true');
    history.push({ search: urlSearchParams.toString() });
    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const handleNewChat = useCallback(
    (skipFeedbackFetching = false): void => {
      if (!skipFeedbackFetching && activeSession)
        fetchFeedbackStatus(activeSession.id);

      resetChatGuideData();
    },
    [activeSession, fetchFeedbackStatus]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isNewConversation = searchParams.get('new-conversation') === 'true';
    const shouldSkipResolution = searchParams.get('resolution') === 'false';

    const getSessionUrl = (sessionId: string): string => {
      const baseUrl = `${routesConfig.ECOACH.SESSIONS}${sessionId}`;
      return shouldSkipResolution ? `${baseUrl}?resolution=false` : baseUrl;
    };

    if (!id && !feedbackStatus?.data?.feedback_needed && !isNewConversation) {
      handleNewChat();
    }

    if (id && !activeSession) {
      getSession(getSessionUrl(id));
    }
  }, [
    activeSession,
    chatGuideData,
    feedbackStatus,
    getSession,
    handleNewChat,
    history,
    id,
    location,
  ]);

  useEffect(() => {
    if (session) setLoading(false);
    if (session.status === 'success') {
      getConversations();
      setActiveSession(session.response);
      history.replace({
        pathname: `${routesConfig.CONVO_PAGE}/${session.response.id}`,
      });
    }
  }, [getConversations, history, session]);

  useEffect(() => {
    if (feedbackStatus.loadingStatus !== FeedbackLoadingStatus.LOADED) return;
    if (!feedbackStatus.data.feedback_needed) setActiveSession(null);
  }, [feedbackStatus, setActiveSession]);

  const getPage = (): JSX.Element => {
    if (pathname === routesConfig.INSIGHTS_PAGE) {
      if (user.is_team_lead) return <Insights />;
      history.push(routesConfig.CONVO_PAGE);
    }

    if (pathname === routesConfig.ALL_CONVOS_PAGE)
      return (
        <AllChats
          activeSession={activeSession?.id}
          setLoadingTrue={() => setLoading(true)}
          resetChatGuideData={resetChatGuideData}
          setActiveSession={setActiveSession}
        />
      );

    return (
      <ECoachSession
        activeSession={activeSession}
        loading={loading}
        chatGuideData={chatGuideData}
        createSession={getSession}
        handleChatGuidance={handleChatGuidance}
        steps={steps}
        setSteps={setSteps}
      />
    );
  };

  return (
    <ErrorBoundary>
      <PageLayout.Page>
        {activeSession && (
          <FeedbackForm
            feedbackStatus={feedbackStatus}
            handleCreate={(input: any) => {
              tryCreate(activeSession.id, input);
              setActiveSession(null);
              handleNewChat();
            }}
          />
        )}
        {getPage()}
      </PageLayout.Page>
    </ErrorBoundary>
  );
}
