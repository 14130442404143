import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Actions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  padding: 30px;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
    padding: 15px;

    button {
      margin: 5px 0;
      width: 100%;
    }
  }
`;

export const Background = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: ${colours.neutralCool[900]};
    opacity: 0.5;
  }
`;

export const Card = styled('div')`
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
  overflow: hidden;
`;

export const Content = styled(DialogContent)`
  padding: 30px;

  @media (max-width: ${device.mobileL}) {
    padding: 15px;
  }
`;

export const ContentText = styled(DialogContentText)`
  color: ${colours.neutralCool[900]};
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
`;

export const Container = styled(Dialog)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Title = styled(DialogTitle)`
  color: ${colours.neutralCool[900]};
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 30px 30px 0 30px;

  @media (max-width: ${device.mobileL}) {
    padding: 15px 15px 0 15px;
  }
`;
