import styled from 'styled-components';
import Alert from '@mui/material/Alert';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const ScreenContainer = styled('div')`
  background-color: ${colours.neutralWarm[200]};
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  height: calc(100 * var(--vh));
  overflow: auto;
  padding: 10px 0 0 0;
  width: 100vw;

  @media (max-width: ${device.laptop}) {
    background-color: ${colours.neutralWarm[50]};
    flex-direction: column;
    gap: 0;
  }
`;

export const PageContainer = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 30px 30px 30px 0;

  @media (max-width: ${device.laptop}) {
    padding: 0;
  }
`;

export const Page = styled('div')`
  background-color: ${colours.neutralWarm[50]};
  border-radius: 20px;
  box-shadow: ${shadows.XL};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px;
  position: relative;
  scroll-behavior: smooth;

  @media (max-width: ${device.laptop}) {
    border-radius: 0;
    box-shadow: none;
    height: calc(100% - 60px);
    padding: 20px;
    margin-top: 55px;
  }
`;

export const MediumPageContainer = styled(PageContainer)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding: 60px;
`;

export const DemoMessage = styled(Alert)`
  align-items: center;
  background-color: ${colours.neutralWarm[300]};
  border-radius: 0;
  box-sizing: border-box;
  color: ${colours.neutralWarm[800]};
  display: flex;
  justify-content: center;
  padding: 0 30px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  svg {
    height: 20px;
    width: 20px;
  }
  svg path {
    fill: ${colours.neutralWarm[800]};
  }
`;
