import useApi from 'hooks/useApi';
import routesConfig from 'constants/routesConfig.json';

interface Chat {
  id: string | number;
}

const useChatStatus = () => {
  const { callApi } = useApi();

  const updateStatus = (chat: Chat | null, newStatus: string) => {
    if (!chat) return;
    callApi(`${routesConfig.ECOACH.SESSIONS}${chat.id}/resolution-status/`, {
      method: 'put',
      data: { resolution_status: newStatus },
    });
  };

  return { updateStatus };
};

export default useChatStatus;
