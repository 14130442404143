import styled from 'styled-components';
import * as Text from 'components/_styles/Text.style';

export const AboutDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AboutSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ActionArea = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
`;

export const Disclaimer = styled(Text.P1Underline)`
  cursor: pointer;
`;

export const InstructionRow = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;

  svg {
    height: 30px;
    min-width: 30px;
    width: 30px;
  }
`;

export const InstructionSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
