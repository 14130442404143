import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ConvoHistoryProvider } from './contexts/ConvoHistoryContext';
import { UserProvider } from './contexts/UserContext';
import App from './App';
// eslint-disable-next-line import/no-named-as-default
import reportWebVitals from './reportWebVitals';
import './i18n';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

const isPosthogEnabled = process.env.REACT_APP_POSTHOG_ENABLED === 'true';

if (isPosthogEnabled) {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || '', {
    api_host: process.env.REACT_APP_POSTHOG_HOST || '',
    person_profiles: 'identified_only',
    session_recording: {
      maskTextSelector: '*',
    },
  });
}

const children = (
  <UserProvider>
    <ConvoHistoryProvider>
      <App />
    </ConvoHistoryProvider>
  </UserProvider>
);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
    >
      {isPosthogEnabled ? (
        <PostHogProvider client={posthog}>{children}</PostHogProvider>
      ) : (
        children
      )}
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
