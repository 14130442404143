import React from 'react';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';
import StyledMarkdown from 'components/_pageLayout/StyledMarkdown';

export interface RAGOutput {
  rag: {
    summary: string;
    documents: any[];
  };
}

interface RAGCardProps {
  activeSession: {
    id: string;
    [key: string]: any;
  };
  output: RAGOutput;
}

export default function RAGCard({
  activeSession,
  output,
}: RAGCardProps): JSX.Element {
  return (
    <ChatBubble>
      <StyledMarkdown content={output.rag.summary} />
      <DocumentList
        documentList={output.rag.documents}
        session={activeSession.id}
      />
    </ChatBubble>
  );
}
