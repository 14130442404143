import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageECoach/_styles/Divider.style';

interface DividerWithTextProps {
  text?: string;
}

function DividerWithText({ text }: DividerWithTextProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const content = text || t('cardTemplates.DividerWithText.Or');
  return (
    <Styled.DividerWithText>
      <Styled.Divider />
      <Styled.DividerWithTextText>{content}</Styled.DividerWithTextText>
      <Styled.Divider />
    </Styled.DividerWithText>
  );
}

export default DividerWithText;
