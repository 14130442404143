import React from 'react';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/DetailsCard.style';
import * as Text from 'components/_styles/Text.style';

interface Journey {
  [key: string]: any;
}

interface DetailsCardProps {
  journey: Journey;
}

function DetailsCard({ journey }: DetailsCardProps): JSX.Element {
  const lang = useUserLanguage();
  const journeyDescription = journey[`description_${lang}`];

  return (
    <Styled.Card>
      {journeyDescription && (
        <Text.P3Semibold>{journeyDescription}</Text.P3Semibold>
      )}
    </Styled.Card>
  );
}

export default DetailsCard;
