import React from 'react';

import { Arrow } from 'untitledui-js';
import Loader from 'components/pageECoach/loaders/Loader';
import * as Styled from 'components/pageECoach/_styles/LoadingAwareItem.style';

interface LoadingAwareItemProps {
  itemId?: string;
  loadingId?: string;
  children?: React.ReactNode;
  handleClick: () => void;
  [key: string]: any;
}

export default function LoadingAwareItem({
  itemId,
  loadingId,
  children,
  handleClick,
  ...props
}: LoadingAwareItemProps): JSX.Element {
  const active = itemId === loadingId;
  const disabled = loadingId !== undefined && !active;

  return (
    <Styled.LoadingAwareItem
      key={itemId}
      disabled={disabled}
      onClick={() => !disabled && handleClick()}
      {...props}
    >
      {children}
      <div>{active ? <Loader inline /> : <Arrow.ChevronRight />} </div>
    </Styled.LoadingAwareItem>
  );
}
