import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  min-width: 450px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    min-width: 90%;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionHeader = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
  font-weight: 900;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.neutralWarm[800]};
`;

export const TopStatDetail = styled('div')`
  display: flex;
  gap: 3px;
  svg {
    height: 16px;
    width: 16px;
    stroke-width: 3;
  }
  svg path {
    stroke: ${colours.mint[600]};
  }
`;

export const TopStatDetailNumber = styled(Text.P1Semibold)`
  color: ${colours.mint[600]};
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const Charts = styled('div')`
  align-items: end;
  display: flex;
  gap: 0;
  padding-bottom: 40px;
`;

export const Checkpoint = styled('div')`
  border-left: 1px dashed ${colours.mint[800]};
  height: 100%;
  margin: 2px;
  position: relative;
`;

export const CheckpointChip = styled('div')`
  align-items: center;
  background-color: ${colours.mint[100]};
  border-radius: 30px;
  display: flex;
  gap: 5px;
  padding: 2px 7px;
  position: absolute;
  right: -10px;
  top: 0;
`;

export const CheckpointChipText = styled(Text.P1Semibold)`
  color: ${colours.mint[800]};
  font-size: 12px;
`;

export const CheckpointChipDot = styled('div')`
  background-color: ${colours.mint[800]};
  border-radius: 30px;
  height: 7px;
  width: 7px;
`;

export const CheckpointLabel = styled('div')`
  align-items: right;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: absolute;
  right: -0;
  bottom: -40px;
`;

export const CheckpointLabelStat = styled(Text.P1Semibold)`
  font-size: 12px;
  text-align: right;
`;

export const CheckpointLabelText = styled(Text.P1)`
  font-size: 12px;
  text-align: right;
  white-space: nowrap;
`;

export default Card;
