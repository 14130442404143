import { useCallback, useState } from 'react';

interface ApiState<T = unknown> {
  status: 'loading' | 'success' | 'error';
  statusCode: string | number;
  response: T | null;
}

type HttpMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'delete'
  | 'pdf'
  | 'photo'
  | 'picture'
  | 'powerpoint'
  | 'word';

interface ParsedBlob {
  metadata?: Record<string, unknown>;
  pdf?: Blob;
}

interface MethodObject {
  method: HttpMethod;
  data?: unknown;
  headers?: Record<string, string>;
}

type MethodParam = HttpMethod | MethodObject;

function parseMultipart(blob: Blob, boundary: string): Promise<ParsedBlob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const decoder = new TextDecoder();
      const text = decoder.decode(arrayBuffer);
      const parts = text.split(`--${boundary}`);

      const result: ParsedBlob = {};
      parts.forEach((part) => {
        if (part.includes('Content-Disposition')) {
          const [headers, body] = part.split('\r\n\r\n');
          if (headers.includes('name="metadata"')) {
            result.metadata = JSON.parse(body);
          } else if (headers.includes('name="file"')) {
            const startIndex = text.indexOf('\r\n\r\n') + 4; // Find the start of binary data
            const binaryData = arrayBuffer.slice(
              startIndex,
              arrayBuffer.byteLength - boundary.length - 8
            );
            result.pdf = new Blob([binaryData], { type: 'application/pdf' });
          }
        }
      });

      resolve(result);
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(blob);
  });
}

const useApi = <T = unknown>() => {
  const [data, setData] = useState<ApiState<T>>({
    status: 'loading',
    statusCode: '',
    response: null,
  });
  const authorization = localStorage.getItem('authorization');
  const authorizationToken = authorization
    ? `Token ${JSON.parse(authorization).token}`
    : null;

  const callApi = useCallback(
    (
      path: string = '',
      methodParam: MethodParam = 'get',
      params: unknown = null,
      additionalHeaders: Record<string, string> | null = null
    ): Promise<T | undefined> | null => {
      const url = process.env.REACT_APP_API_URL + path;

      // Handle method and params
      const method =
        typeof methodParam === 'string' ? methodParam : methodParam.method;
      const finalParams =
        typeof methodParam === 'string' ? params : methodParam.data;

      // Extract headers from methodParam if it's an object
      const headersFromMethodParam =
        typeof methodParam === 'object' && methodParam.headers
          ? methodParam.headers
          : {};

      let headers: Record<string, string> =
        authorizationToken && path !== '/login/'
          ? {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: authorizationToken,
            }
          : {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            };

      // Merge all headers, giving priority to additionalHeaders and headersFromMethodParam
      headers = {
        ...headers,
        ...headersFromMethodParam,
        ...(additionalHeaders || {}),
      };

      let requestOptions: {
        headers: Record<string, string>;
        method?: string;
        body?: string | FormData;
      };

      switch (method.toLowerCase()) {
        case 'post':
          requestOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(finalParams),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.status === 204) {
              setData({
                status: 'success',
                statusCode: response.status,
                response: {} as T,
              });
            } else if (response.ok) {
              response.json().then((formattedData) =>
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: formattedData as T,
                })
              );
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'pdf':
          requestOptions = {
            headers: {
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              const contentType = response.headers.get('Content-Type');
              // Check if this is a multipart response
              if (contentType && contentType.includes('multipart/form-data')) {
                const boundaryMatch = contentType.match(/boundary=(.*)$/);
                const boundary = boundaryMatch ? boundaryMatch[1] : null;

                if (boundary) {
                  response.blob().then((blob) => {
                    parseMultipart(blob, boundary).then((parsedBlob) => {
                      const pdfUrl = window.URL.createObjectURL(parsedBlob.pdf);
                      setData({
                        status: 'success',
                        statusCode: response.status,
                        response: {
                          url: pdfUrl,
                          metadata: parsedBlob.metadata,
                        } as T,
                      });
                    });
                  });
                } else {
                  throw new Error('Invalid multipart boundary');
                }
              }
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'photo':
          requestOptions = {
            headers: {
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const contentType = response.headers.get('Content-Type');
                const imageUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: contentType, // Will handle both image/jpeg and image/png
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: imageUrl } as T,
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'picture':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'POST',
            body: finalParams as FormData,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({
                status: 'success',
                statusCode: response.status,
                response: null,
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'put':
          requestOptions = {
            headers,
            method: 'PUT',
            body: JSON.stringify(finalParams),
          };
          return fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              if (response.status === 204) {
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: null,
                });
                return undefined as T;
              }
              return response.json().then((formattedData) => {
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: formattedData as T,
                });
                return formattedData as T;
              });
            }
            setData({
              status: 'error',
              statusCode: response.status,
              response: null,
            });
            return undefined as T;
          });
        case 'delete':
          requestOptions = {
            headers,
            method: 'DELETE',
            body: JSON.stringify(finalParams),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({
                status: 'success',
                statusCode: response.status,
                response: null,
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'powerpoint':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const pptUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: pptUrl } as T,
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        case 'word':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const wordUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: wordUrl } as T,
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
        default:
          requestOptions = {
            headers,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.json().then((formattedData) =>
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: formattedData as T,
                })
              );
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response: null,
              });
            }
          });
          break;
      }
      return null;
    },
    [authorizationToken]
  );

  return { data, callApi };
};

export default useApi;
