import React, { useEffect, useState } from 'react';

// component
import Textbox from 'components/pageSlideshow/Textbox';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageSlideshow/_styles/Slides.style';

interface Slide {
  [key: string]: any;
  textbox_en?: string[] | null;
  textbox_fr?: string[] | null;
  image_en?: string;
  image_fr?: string;
  video_en?: string;
  video_fr?: string;
}

interface SlideGeneralProps {
  markComplete: () => void;
  setShowIntroSlide: (show: boolean) => void;
  slides: Slide[];
}

export default function SlideGeneral(props: SlideGeneralProps) {
  const { markComplete, setShowIntroSlide, slides } = props;

  const lang = useUserLanguage();

  const [currentSlideProgress, setCurrentSlideProgress] = useState<number>(0);
  const [guideTotal, setGuideTotal] = useState<number>(0);
  const [guidePercent, setGuidePercent] = useState<number>(0);
  const [guideProgress, setGuideProgress] = useState<number>(0);
  const [description, setDescription] = useState<string[] | null | undefined>();
  const [image, setImage] = useState<string | undefined>();
  const [slideNumber, setSlideNumber] = useState<number>(0);
  const [video, setVideo] = useState<string | undefined>();

  const backClick = () => {
    setGuideProgress((prev) => prev - 1);
    if (currentSlideProgress === 0) {
      setSlideNumber((prev) => prev - 1);
      if (!slides[slideNumber - 1]) return;
      const prevSlideTextboxes = slides[slideNumber - 1][`textbox_${lang}`];
      if (prevSlideTextboxes === null || prevSlideTextboxes.length === 0)
        setCurrentSlideProgress(0);
      else setCurrentSlideProgress(prevSlideTextboxes.length - 1);
    } else setCurrentSlideProgress((prev) => prev - 1);
  };

  const nextClick = () => {
    setGuideProgress((prev) => prev + 1);
    if (
      description === null ||
      description === undefined ||
      description.length === 0 ||
      currentSlideProgress === description.length - 1
    ) {
      setSlideNumber((prev) => prev + 1);
      setCurrentSlideProgress(0);
    } else setCurrentSlideProgress((prev) => prev + 1);
  };

  useEffect(() => {
    if (guideProgress === -1) setShowIntroSlide(true);
    if (guideTotal !== 0 && guideProgress === guideTotal) markComplete();
  }, [markComplete, setShowIntroSlide, guideProgress, guideTotal]);

  useEffect(() => {
    let totalNextClicks = 0;
    slides.forEach((slide) => {
      if (!slide[`textbox_${lang}`]) totalNextClicks += 1;
      else totalNextClicks += slide[`textbox_${lang}`].length;
    });
    setGuideTotal(totalNextClicks);
  }, [lang, setGuideTotal, slides]);

  useEffect(() => {
    setGuidePercent(((guideProgress + 1) / guideTotal) * 100);
  }, [guideTotal, guideProgress, setGuidePercent]);

  useEffect(() => {
    if (!slides[slideNumber]) return;
    setDescription(slides[slideNumber][`textbox_${lang}`]);
    setImage(slides[slideNumber][`image_${lang}`]);
    setVideo(slides[slideNumber][`video_${lang}`]);
  }, [lang, setDescription, setImage, setVideo, slideNumber, slides]);

  return (
    <>
      <Styled.Slide>
        <Styled.SlideImage src={image} />
        {video && <Styled.SlideVideoEmbed controls src={video} />}
      </Styled.Slide>
      <Textbox
        backClick={backClick}
        contentText={description && description[currentSlideProgress]}
        ctaView={false}
        nextClick={nextClick}
        progress={guidePercent}
      />
    </>
  );
}
