import React from 'react';
import { TFunction } from 'i18next';

import { FormControl, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import useIndustries from 'hooks/demo/useIndustries';

interface IndustryFieldProps {
  currentLanguage: string;
  t: TFunction;
}

export default function IndustryField({
  currentLanguage,
  t,
}: IndustryFieldProps): JSX.Element {
  const industries = useIndustries(currentLanguage);

  const industriesOptions = React.useMemo(() => {
    if (!industries || industries.length === 0) {
      return [
        <MenuItem key="loading" value="loading">
          {t('Loading...') as string}
        </MenuItem>,
      ];
    }

    return industries.map((industry) => (
      <MenuItem key={industry.id} value={industry.id}>
        {industry.name}
      </MenuItem>
    ));
  }, [industries, t]);

  return (
    <FormControl fullWidth>
      <Field component={Select} name="industry" label={t('Industry')}>
        {industriesOptions}
      </Field>
    </FormControl>
  );
}
