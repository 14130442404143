import React from 'react';
import { TFunction } from 'i18next';

// assets
import { Communication, General, Shapes } from 'untitledui-js';

// styles
import * as Styled from 'components/pageDemoRegistration/_styles/SuggestionCard.style';
import * as Text from 'components/_styles/Text.style';

interface SuggestionCardsProps {
  t: TFunction;
}

interface Suggestion {
  title: string;
  description: string;
}

export default function SuggestionCards({
  t,
}: SuggestionCardsProps): JSX.Element {
  const suggestions: Suggestion[] = [
    {
      title: t('SuggestionCards.Create a conversation plan'),
      description: t(
        'SuggestionCards.Identify key talking points and plan for most likely responses.'
      ),
    },
    {
      title: t('SuggestionCards.Check out resources'),
      description: t(
        'SuggestionCards.Here are some resources offered by your workplace.'
      ),
    },
    {
      title: t('SuggestionCards.Try these exercises'),
      description: t(
        'SuggestionCards.These have proven helpful to other managers in similar situations.'
      ),
    },
  ];

  const getSuggestion = (suggestion: Suggestion): JSX.Element => (
    <Styled.SuggestionCard key={suggestion.title}>
      <Styled.Content>
        <Text.P2Semibold>{suggestion.title}</Text.P2Semibold>
        <Text.P2>{suggestion.description}</Text.P2>
      </Styled.Content>
      <Styled.ActionArea>
        <Styled.RobotAction>
          <Communication.MessageCircle01 />
        </Styled.RobotAction>
        <Styled.StarAction>
          <Shapes.Star01 />
        </Styled.StarAction>
        <Styled.XAction>
          <General.XClose />
        </Styled.XAction>
      </Styled.ActionArea>
    </Styled.SuggestionCard>
  );

  return (
    <Styled.Suggestions>
      {suggestions.map((suggestion) => getSuggestion(suggestion))}
    </Styled.Suggestions>
  );
}
