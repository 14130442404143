import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import userRoles from 'constants/userRoles.json';

interface ContainerProps {
  userrole: string;
}

export const PageOverflow = styled('div')`
  background-color: ${colours.neutralWarm[200]};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    background-color: ${colours.neutralWarm[100]};
    padding: 20px;
  }
`;

export const Containter = styled('div')<ContainerProps>`
  border-radius: 20px;
  cursor: ${(props) =>
    props.userrole === userRoles.MEMBER ? 'pointer' : 'default'};
  display: flex;
  min-height: ${(props) =>
    props.userrole === userRoles.MEMBER ? '740px' : '560px'};
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
    min-height: 664px;
    overflow: hidden;
  }
`;

export const Content = styled('div')`
  align-items: flex-end;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: space-between;
  padding: 60px;

  @media (max-width: ${device.laptop}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    padding: 30px;
  }
`;

export const Cover = styled('div')`
  flex: 1;
  height: 100%;
  inset: 0;
  position: absolute;
  z-index: 1;

  @media (max-width: ${device.laptop}) {
    max-height: 100%;
  }
`;

export const CoverImage = styled('img')`
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    width: calc(100vw - 20px);
  }
`;

export const InfoSection = styled('div')`
  align-items: flex-start;
  display: flex;
  gap: 20px;
  z-index: 1;

  h1 {
    color: ${colours.shade[0]};
  }

  p {
    color: ${colours.shade[0]};
  }

  @media (max-width: ${device.laptop}) {
    gap: 0;
  }
`;

export const JourneyTitle = styled('div')`
  align-items: flex-start;
  display: flex;
  gap: 20px;
`;

export const Titles = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
