import styled from 'styled-components';
import device from 'constants/devices';

export const Page = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1200px;
  width: 100%;
`;

export const Header = styled('div')`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    border-radius: 0;
    height: 400px;
  }
`;

export const Cover = styled('div')`
  height: 100%;
  inset: 0;
  position: absolute;
  z-index: 1;
`;

export const CoverImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const Top = styled('div')`
  align-items: center;
  display: flex;
  gap: 20px;
  padding: 30px;
  position: relative;
  z-index: 2;
`;

export const BackButton = styled('div')`
  align-items: center;
  background-color: white;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  svg path {
    stroke: black;
  }
`;

export const InfoSection = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px;
  position: relative;
  z-index: 2;

  h1 {
    color: white;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 30px;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    padding: 0;
  }
`;

export const TabContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;
