import styled from 'styled-components';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';
import { ToolbarButton } from './Toolbar.style';

export const OutlinedArea = styled('div')`
  display: flex;
  flex-direction: column;
  > :last-child {
    border-bottom: none;
  }
`;

export const ActionItem = styled('div')`
  cursor: pointer;
  display: flex;

  span {
    height: 17px;
    width: 17px;
  }

  svg path {
    stroke: ${colours.neutralWarm[700]};
  }
`;

export const Header = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  margin: 10px 0;
  width: fit-content;

  span {
    height: 17px;
    width: 17px;
  }

  svg path {
    stroke: ${colours.neutralWarm[700]};
  }
`;

export const NumberedContent = styled('div')`
  display: flex;
  flex: 1;
  gap: 10px;
`;

export const NumberBox = styled('div')`
  align-items: center;
  background-color: #ffdbdc;
  border-radius: 30px;
  display: flex;
  height: 17px;
  justify-content: center;
  margin-top: 3px;
  width: 17px;
`;

export const Number = styled(Text.P1Semibold)`
  color: ${colours.primary[700]};
  font-size: 12px;
`;

export const Content = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

export const List = styled('ul')`
  color: ${colours.neutralCool[800]};
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  gap: 10px;
  font-weight: 400;
  margin: 0;
`;

export const OrderedList = styled('ol')`
  color: ${colours.neutralCool[800]};
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
`;

export const BackButton = styled(ToolbarButton)`
  padding-right: 18px;
  background-color: ${colours.neutralCool[100]};
  color: ${colours.neutralCool[800]};
  :hover {
    background-color: ${colours.neutralCool[200]};
  }
`;
