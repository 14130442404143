import React from 'react';
import * as Styled from '../_styles/ThreeDots.style';

const ThreeDots = (): JSX.Element => (
  <Styled.LoadingContainer>
    <Styled.Dot />
    <Styled.Dot />
    <Styled.Dot />
  </Styled.LoadingContainer>
);

export default ThreeDots;
