import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';

interface ChatGuideData {
  promptHelperGoal: string | { label: string; prompt: string };
  promptHelperTheme: string;
  chatStartEnded: boolean;
}

interface PromptSetupProps {
  chatGuideData: ChatGuideData;
  onSubmit: (message: string) => void;
}

export default function PromptSetup({
  chatGuideData,
  onSubmit,
}: PromptSetupProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const challengeText = t(
    "promptHelper.PromptSetup.The challenge I'm facing at work is figuring out"
  );

  const setupInitialInput = (): string => {
    if (chatGuideData.promptHelperGoal === 'Something else')
      return `${challengeText} ${t(
        'promptHelper.PromptSetup.how to'
      )} ${chatGuideData.promptHelperTheme.toLowerCase()} `;

    const goal =
      typeof chatGuideData.promptHelperGoal === 'object'
        ? chatGuideData.promptHelperGoal.prompt
        : '';

    return `${challengeText} ${t(`promptHelper.${goal}`) || ''}`;
  };

  return (
    <>
      <ChatBubble
        simpleString={t(
          "promptHelper.PromptSetup.I've filled in the chat bar based on your answers. Feel free to add more details and submit your challenge."
        )}
      />
      {chatGuideData.chatStartEnded === false && (
        <Chatbar
          autoFocus
          initialInput={setupInitialInput()}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
