import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  min-width: 250px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    min-width: unset;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.neutralWarm[800]};
`;

export const TopStatDetail = styled('div')`
  display: flex;
  gap: 3px;
  svg {
    height: 16px;
    width: 16px;
    stroke-width: 3;
  }
  svg path {
    stroke: ${colours.mint[600]};
  }
`;

export const TopStatDetailNumber = styled(Text.P1Semibold)`
  color: ${colours.mint[600]};
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export default Card;
