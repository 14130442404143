import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from 'components/pageinsights/_styles/TimelineToggle.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineToggleProps {
  timeline: string;
  setTimeline: (timeline: string) => void;
}

export default function TimelineToggle({
  timeline,
  setTimeline,
}: TimelineToggleProps): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  return (
    <Styled.ToggleButtonGroup>
      <Styled.ToggleButton
        onClick={() => setTimeline('oneMonth')}
        selected={timeline === 'oneMonth'}
        aria-label="1M"
      >
        <Text.P2Semibold>1M</Text.P2Semibold>
      </Styled.ToggleButton>
      <Styled.ToggleButton
        onClick={() => setTimeline('threeMonth')}
        selected={timeline === 'threeMonth'}
        aria-label="3M"
      >
        <Text.P2Semibold>3M</Text.P2Semibold>
      </Styled.ToggleButton>
      <Styled.ToggleButton
        onClick={() => setTimeline('sixMonth')}
        selected={timeline === 'sixMonth'}
        aria-label="6M"
      >
        <Text.P2Semibold>6M</Text.P2Semibold>
      </Styled.ToggleButton>
      <Styled.ToggleButton
        onClick={() => setTimeline('oneYear')}
        selected={timeline === 'oneYear'}
        aria-label={t('timeline.1Y')}
      >
        <Text.P2Semibold>{t('timeline.1Y')}</Text.P2Semibold>
      </Styled.ToggleButton>
      <Styled.ToggleButton
        onClick={() => setTimeline('all')}
        selected={timeline === 'all'}
        aria-label={t('timeline.ALL')}
      >
        <Text.P2Semibold>{t('timeline.ALL')}</Text.P2Semibold>
      </Styled.ToggleButton>
    </Styled.ToggleButtonGroup>
  );
}
