import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

interface Journey {
  id: string | number;
  title_en: string;
  title_fr: string;
  [key: string]: any;
}

interface Microlearning {
  id: string | number;
  title_en: string;
  title_fr: string;
  [key: string]: any;
}

interface MicrolearningCardButtonsProps {
  cover: string;
  journey: Journey;
  microlearning: Microlearning;
}

function MicrolearningCardButtons({
  cover,
  journey,
  microlearning,
}: MicrolearningCardButtonsProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToSlideshow = useCallback(() => {
    history.push({
      pathname: routesConfig.SLIDESHOW_PAGE,
      state: {
        cover,
        journeyId: journey.id,
        journeyTitleEN: journey.title_en,
        journeyTitleFR: journey.title_fr,
        microlearningId: microlearning.id,
        microlearningTitleEN: microlearning.title_en,
        microlearningTitleFR: microlearning.title_fr,
      },
    });
  }, [cover, history, journey, microlearning]);

  return (
    <Buttons.Primary onClick={redirectToSlideshow}>
      {t('pageJourneyId:learnMore')}
    </Buttons.Primary>
  );
}

export default MicrolearningCardButtons;
