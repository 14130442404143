import { useEffect } from 'react';

const useViewportHeight = (): void => {
  useEffect(() => {
    const setVH = (): void => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, []);
};

export default useViewportHeight;
