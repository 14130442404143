import { useCallback, useEffect, useState } from 'react';
import routesConfig from 'constants/routesConfig.json';
import useWebsocketSetup from './useWebsocketSetup';
import { OutputType } from '../components/pageECoach/utils';

export enum LoadingStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
  IDLE = 'idle',
}

type LoadingStatusType = (typeof LoadingStatus)[keyof typeof LoadingStatus];

// Generic type for any item state with loading status
type ItemState<T> = {
  status: LoadingStatusType;
  data: T | null;
};

// Make all properties required and readonly for message types
type WSMessage = Readonly<{
  action: string;
  data: unknown;
}>;

type SendMessagePayload = Readonly<{
  action: string;
  request_id: number;
  pk: string;
  item_id: string;
}>;

// Make all properties required and some optional
type StepOutput = Readonly<
  {
    output_type: number;
  } & Partial<{
    conversation_items: unknown[];
    suggestion_items: unknown[];
  }>
>;

export const useEcoachItemsConsumer = (
  sessionId: string,
  stepOutput: StepOutput
) => {
  const [items, setItems] = useState<ItemState<any[]>>({
    status: LoadingStatus.IDLE,
    data: null,
  });

  const [selectedItem, setSelectedItem] = useState<ItemState<any>>({
    status: LoadingStatus.IDLE,
    data: null,
  });

  const getConsumerEndpoint = useCallback((outputType: number): string => {
    switch (outputType) {
      case OutputType.CONVERSATION_PLAN:
        return routesConfig.ECOACH.CONVERSATION_ITEMS_WS;
      case OutputType.SUGGESTIONS:
        return routesConfig.ECOACH.SUGGESTION_ITEMS_WS;
      default:
        // FIXME What to do here?
        return '???';
    }
  }, []);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocketSetup(
    getConsumerEndpoint(stepOutput.output_type)
  );

  // Load initial items
  useEffect(() => {
    let outputItems: any[] = [];
    switch (stepOutput.output_type) {
      case OutputType.CONVERSATION_PLAN:
        outputItems = stepOutput.conversation_items || [];
        break;
      case OutputType.SUGGESTIONS:
        outputItems = stepOutput.suggestion_items || [];
        break;
      default:
        outputItems = [];
    }
    setItems({
      status: LoadingStatus.LOADED,
      data: outputItems,
    });
  }, [
    stepOutput.output_type,
    stepOutput.suggestion_items,
    stepOutput.conversation_items,
  ]);

  // Receive WS response
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage as WSMessage;

    if (action === 'get') {
      setSelectedItem({ status: LoadingStatus.LOADED, data });
    }
  }, [lastJsonMessage]);

  const selectItem = (itemId: string | null) => {
    if (!itemId) {
      setSelectedItem({ status: LoadingStatus.IDLE, data: null });
      return;
    }

    setSelectedItem({
      status: LoadingStatus.LOADING,
      data: { id: itemId },
    });

    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({
        action: 'get',
        request_id: Date.now(),
        pk: sessionId,
        item_id: itemId,
      } as SendMessagePayload);
    }
  };

  return { items, selectedItem, selectItem };
};
