import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

function PrivacyCard(): JSX.Element {
  const { t } = useTranslation('pageAbout');
  return (
    <>
      <Styled.Content>
        <Styled.AboutSection>
          <Text.P3Semibold>{t('privacy.subheader')}</Text.P3Semibold>
          <Styled.AboutDescription>
            <Text.P2>{t('privacy.disclaimer1')}</Text.P2>
            <Text.P2>{t('privacy.disclaimer2')}</Text.P2>
            <Text.P2>{t('privacy.disclaimer3')}</Text.P2>
            <Text.P2>{t('privacy.disclaimer4')}</Text.P2>
          </Styled.AboutDescription>
        </Styled.AboutSection>
      </Styled.Content>
    </>
  );
}

export default PrivacyCard;
