import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageOnboarding/_styles/IdentityForm.style';
import * as StyledCard from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

interface PronounOption {
  label: string;
  value: string[] | string;
}

interface SubmittedIdentityForm {
  nickname: string;
  pronouns: string | string[];
  other: string;
}

interface IdentityFormProps {
  handleSectionReturn: (section: number) => void;
  markComplete: (section: number) => void;
  pronouns: Record<string, PronounOption>;
  setSubmittedIdentityForm: (form: SubmittedIdentityForm) => void;
  submittedIdentityForm: SubmittedIdentityForm;
}

function IdentityForm(props: IdentityFormProps) {
  const {
    handleSectionReturn,
    markComplete,
    pronouns,
    setSubmittedIdentityForm,
    submittedIdentityForm,
  } = props;

  const { data, callApi } = useApi();
  const { t } = useTranslation('pageOnboarding');
  const posthog = usePostHog();

  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(true);
  const [nickname, setNickname] = useState<string>(
    submittedIdentityForm.nickname || ''
  );
  const [selectedPronouns, setPronouns] = useState<string>(
    Array.isArray(submittedIdentityForm.pronouns) &&
      submittedIdentityForm.pronouns.length > 0
      ? submittedIdentityForm.pronouns[0]
      : ''
  );
  const [otherPronouns, setOtherPronouns] = useState<string>('');
  const [selectOpen, setselectOpen] = useState<string>('false');

  const header = t('Tell me about you');

  const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNickname(event.target.value);

  const handlePronounChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPronouns(event.target.value);

  const handleOtherPronounChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setOtherPronouns(event.target.value);

  const backPageClick = () => handleSectionReturn(2);

  const nextPageClick = () => {
    const pronounSubmission =
      otherPronouns.length > 0
        ? otherPronouns.replace(' ', '').split(',')
        : pronouns[selectedPronouns].value;
    callApi('/users/identity/', {
      method: 'put',
      data: {
        nickname,
        pronouns: pronounSubmission,
      },
    });
    setSubmittedIdentityForm({
      nickname,
      pronouns: pronouns[selectedPronouns].label,
      other: otherPronouns,
    });
    posthog?.capture('user_set_their_identity');
  };

  const openSelect = () => setselectOpen('true');
  const closeSelect = () => setselectOpen('false');

  useEffect(() => {
    if (nickname.length === 0) return setNextPageDisabled(true);
    if (selectedPronouns.length === 0 && selectedPronouns !== 'other')
      return setNextPageDisabled(true);
    if (selectedPronouns === 'other' && otherPronouns.length === 0)
      return setNextPageDisabled(true);
    return setNextPageDisabled(false);
  }, [nickname, selectedPronouns, otherPronouns]);

  useEffect(() => {
    if (
      !['she', 'he', 'they', 'other', 'refuse'].includes(selectedPronouns) &&
      selectedPronouns.length !== 0
    ) {
      setOtherPronouns(
        Array.isArray(submittedIdentityForm.pronouns)
          ? submittedIdentityForm.pronouns.join(',')
          : submittedIdentityForm.pronouns
      );
      setPronouns('other');
    }
  }, [selectedPronouns, submittedIdentityForm.pronouns]);

  useEffect(() => {
    if (data.status === 'success') markComplete(2);
  }, [data.status, markComplete]);

  return (
    <StyledCard.Card>
      <StyledCard.TopSection>
        <StyledCard.InnerSection>
          <Text.H5Bold>{header}</Text.H5Bold>
          <Styled.Input
            autoComplete={t('nickname')}
            autoFocus
            id="nickname"
            InputLabelProps={{
              style: { color: 'grey' },
            }}
            label={t('nickname')}
            margin="normal"
            name="nickname"
            onChange={handleNicknameChange}
            required
            value={nickname}
          />
          <Styled.SelectInput
            fullWidth
            id="pronouns"
            InputLabelProps={{ style: { color: 'grey' } }}
            label={t('pronouns')}
            margin="normal"
            onChange={handlePronounChange}
            required
            select
            selectopen={selectOpen}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    backgroundColor: '#f5f5f3',
                    boxShadow: 'none',
                    borderRadius: '0 0 25px 25px',
                    top: '440px',
                  },
                },
              },
              onOpen: openSelect,
              onClose: closeSelect,
            }}
            value={selectedPronouns}
          >
            <Styled.ListItem value={'she'}>
              {pronouns.she.label}
            </Styled.ListItem>
            <Styled.ListItem value={'he'}>{pronouns.he.label}</Styled.ListItem>
            <Styled.ListItem value={'they'}>
              {pronouns.they.label}
            </Styled.ListItem>
            <Styled.ListItem value={'other'}>
              {pronouns.other.label}
            </Styled.ListItem>
            <Styled.ListItem value={'refuse'}>
              {pronouns.refuse.label}
            </Styled.ListItem>
          </Styled.SelectInput>
          {selectedPronouns === 'other' && (
            <Styled.OtherPronounsInput
              autoComplete={t('pronounInstructions')}
              fullWidth
              id="other-pronouns"
              label={t('pronounInstructions')}
              margin="normal"
              name="other-pronouns"
              onChange={handleOtherPronounChange}
              autoFocus
              InputLabelProps={{
                style: { color: 'grey' },
              }}
              value={otherPronouns}
            />
          )}
        </StyledCard.InnerSection>
      </StyledCard.TopSection>
      <StyledCard.Navigation>
        <StyledCard.BackButton aria-label={t('back')} onClick={backPageClick}>
          <NavigateBeforeIcon />
        </StyledCard.BackButton>
        {nextPageDisabled ? (
          <Buttons.SecondaryIcon inactive>
            <NavigateNextIcon />
          </Buttons.SecondaryIcon>
        ) : (
          <StyledCard.NavButton aria-label={t('next')} onClick={nextPageClick}>
            <NavigateNextIcon />
          </StyledCard.NavButton>
        )}
      </StyledCard.Navigation>
    </StyledCard.Card>
  );
}

export default IdentityForm;
