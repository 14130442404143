import React from 'react';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Modal from 'components/_styles/Modal.style';

interface ConfirmDialogProps {
  cancelLabel?: string;
  contentText?: string;
  handleClose: () => void;
  isOpen: boolean;
  successCallback?: () => void;
  successLabel?: string;
  title?: string;
}

const ConfirmDialog = ({
  cancelLabel,
  contentText,
  handleClose,
  isOpen,
  successCallback,
  successLabel,
  title,
}: ConfirmDialogProps): JSX.Element => (
  <Modal.Container
    onClose={handleClose}
    open={isOpen}
    PaperComponent={Modal.Card}
  >
    <Modal.Title id="alert-dialog-title">{title}</Modal.Title>
    <Modal.Content style={{ marginBottom: '30px' }}>
      <Modal.ContentText id="alert-dialog-description">
        {contentText}
      </Modal.ContentText>
    </Modal.Content>
    <Modal.Actions sx={{ justifyContent: 'flex-start' }}>
      {successCallback && (
        <Buttons.Secondary onClick={successCallback}>
          {successLabel}
        </Buttons.Secondary>
      )}
      <Buttons.Tertiary onClick={handleClose}>{cancelLabel}</Buttons.Tertiary>
    </Modal.Actions>
  </Modal.Container>
);

export default ConfirmDialog;
