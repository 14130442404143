import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Text from 'components/_styles/Text.style';
import * as Styled from '../_styles/Report.style';

interface SuggestionItem {
  description: string;
  why_it_matters: string[];
  potential_challenges: string[];
}

interface SuggestionItemDetailsProps {
  item: SuggestionItem;
}

export default function SuggestionItemDetails({
  item: suggestion,
}: SuggestionItemDetailsProps) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('suggestion_item_loaded');
  }, [posthog]);

  if (!suggestion) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '15px 0 15px 30px',
      }}
    >
      <Text.P2>{suggestion?.description}</Text.P2>
      <Styled.Content>
        <Text.P2Semibold>
          {t('suggestions.SuggestionItemDetails.Why this matters')}
        </Text.P2Semibold>
        <Styled.List>
          {suggestion?.why_it_matters.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>
          {t('suggestions.SuggestionItemDetails.Potential challenges')}
        </Text.P2Semibold>
        <Styled.List>
          {suggestion?.potential_challenges.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </div>
  );
}
