import React from 'react';
import { TFunction } from 'i18next';

import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import useCompanySizeChoices from 'hooks/demo/useCompanySizeChoices';

interface CompanySizeFieldProps {
  currentLanguage: string;
  t: TFunction;
}

interface CompanySizeChoice {
  code: string;
  display: string;
}

export default function CompanySizeField({
  currentLanguage,
  t,
}: CompanySizeFieldProps): JSX.Element {
  const companySizeChoices = useCompanySizeChoices(
    currentLanguage
  ) as unknown as CompanySizeChoice[];

  const companySizeChoicesOptions = React.useMemo(() => {
    if (!companySizeChoices || companySizeChoices.length === 0) {
      return [
        <MenuItem key="loading" value="loading">
          Loading...
        </MenuItem>,
      ];
    }
    return companySizeChoices.map((companySize) => (
      <MenuItem key={companySize.code} value={companySize.code}>
        {companySize.display}
      </MenuItem>
    ));
  }, [companySizeChoices]);

  return (
    <Field
      component={Select}
      name="additional_infos.company_size"
      label={t('Company Size')}
    >
      {companySizeChoicesOptions}
    </Field>
  );
}
