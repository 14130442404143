import styled, { css } from 'styled-components';
import device from 'constants/devices';

interface LoadingAwareItemProps {
  disabled?: boolean;
}

export const LoadingAwareItem = styled('div').attrs<LoadingAwareItemProps>(
  (props) => ({
    className: props.disabled ? 'disabled' : '',
  })
)<LoadingAwareItemProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  padding: 20px 0;

  @media (max-width: ${device.tablet}) {
    gap: 20px;
  }

  @media (max-width: ${device.tablet}) {
    gap: 5px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const Content = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;
