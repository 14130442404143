interface ShadowSizes {
  XS: string;
  S: string;
  M: string;
  L: string;
  XL: string;
}

const shadows: ShadowSizes = {
  XS: '0 16px 18px -5px rgba(0, 0, 0, 5%)',
  S: '0 25px 45px -25px rgba(0, 0, 0, 10%)',
  M: '0 30px 60px -25px rgba(0, 0, 0, 12%)',
  L: '0 50px 70px -35px rgba(0, 0, 0, 15%)',
  XL: '0 70px 120px -30px rgba(0, 0, 0, 20%)',
};

export default shadows;
