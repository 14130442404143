import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

// components
import AboutPage from 'components/pageAbout/_index';
import DocumentPage from 'components/pageECoach/documentDisplay/DocumentPage';
import DemoSettings from 'components/pageDemoSettings/DemoSettings';
import ECoachPage from 'components/pageECoach/_index';
import InsightsPage from 'components/pageinsights/_index';
import JourneyIdPage from 'components/pageJourneyId/_index';
import JourneyListPage from 'components/pageJourneyList/_index';
import NotFound from 'components/page404/page404';
import Onboarding from 'components/pageOnboarding/_index';
import SlideshowPage from 'components/pageSlideshow/_index';

// context
import { useUser } from 'contexts/UserContext';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

interface RouteParams {
  id?: string;
}

function PageRoutes(): JSX.Element | null {
  const { data: user, userRole } = useUser();

  if (!userRole || !user) return null;
  return (
    <Switch>
      <Route exact path={routesConfig.ROOT_PAGE}>
        {userRole === userRoles.MEMBER &&
        !user.is_demo &&
        user.member?.onboarding_completed_at === null ? (
          <Redirect to={routesConfig.ONBOARDING_PAGE} />
        ) : (
          <ECoachPage />
        )}
      </Route>

      <Route exact path={`${routesConfig.ABOUT_PAGE}`} component={AboutPage} />

      <Route
        exact
        path={`${routesConfig.ALL_CONVOS_PAGE}`}
        component={ECoachPage}
      />

      <Route
        exact
        path={`${routesConfig.CONVO_PAGE}`}
        render={() =>
          user.is_demo && !user.is_internal ? (
            <Redirect to={`${routesConfig.ALL_CONVOS_PAGE}`} />
          ) : (
            <ECoachPage />
          )
        }
      />

      <Route
        exact
        path={`${routesConfig.INSIGHTS_PAGE}`}
        component={InsightsPage}
      />

      <Route
        exact
        path={`${routesConfig.CONVO_PAGE}/:id?`}
        component={ECoachPage}
      />

      <Route
        exact
        path={`${routesConfig.DOCUMENT_PAGE}/:id/pdf`}
        component={DocumentPage}
      />

      <Route
        exact
        path={routesConfig.JOURNEY_LIST_PAGE}
        component={JourneyListPage}
      />

      <Route
        exact
        path={`${routesConfig.JOURNEY_ID_PAGE}/:id`}
        component={JourneyIdPage}
      />

      <Route
        exact
        path={routesConfig.SLIDESHOW_PAGE}
        component={SlideshowPage}
      />

      <Route
        exact
        path={`${routesConfig.ONBOARDING_PAGE}`}
        render={() =>
          user.is_demo ? (
            <Redirect to={`${routesConfig.ALL_CONVOS_PAGE}`} />
          ) : (
            <Onboarding />
          )
        }
      />

      <Route
        exact
        path={routesConfig.DEMO_SETTINGS_PAGE}
        component={DemoSettings}
      />

      <Route path={routesConfig.LEGACY.ALL_CONVOS_PAGE}>
        <Redirect to={routesConfig.ALL_CONVOS_PAGE} />
      </Route>

      <Route path={routesConfig.LEGACY.INSIGHTS_PAGE}>
        <Redirect to={routesConfig.INSIGHTS_PAGE} />
      </Route>

      <Route path={`${routesConfig.LEGACY.ECOACH_PAGE}/:id?`}>
        {({ match }: RouteComponentProps<RouteParams>) => (
          <Redirect
            to={`${routesConfig.CONVO_PAGE}${
              match?.params?.id ? `/${match.params.id}` : ''
            }`}
          />
        )}
      </Route>

      <Route path={routesConfig.LEGACY.ECOACH_PAGE}>
        <Redirect to={routesConfig.ROOT_PAGE} />
      </Route>

      <Route component={NotFound} exact path="*" />
    </Switch>
  );
}

export default PageRoutes;
