import React from 'react';
import { useTranslation } from 'react-i18next';
import { General } from 'untitledui-js';
import * as Buttons from 'components/_styles/Buttons.style';
import { useHistory } from 'react-router-dom';

interface CloseTourProps {
  onClick: () => void;
  currentStep: number;
}

const CloseTour: React.FC<CloseTourProps> = ({ onClick, currentStep }) => {
  const { t } = useTranslation('pageDemoRegistration');
  const history = useHistory();

  const closeTour = () => {
    onClick();
    history.push({ search: '' });
  };

  if (currentStep !== 9)
    return (
      <General.XClose
        onClick={closeTour}
        size="15"
        style={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
      />
    );
  return (
    <Buttons.Primary
      centerText
      onClick={closeTour}
      style={{
        position: 'absolute',
        bottom: '15px',
        marginTop: '10px',
        marginBottom: '50px',
        width: '85%',
      }}
    >
      {t('tour.Get started')}
    </Buttons.Primary>
  );
};

export default CloseTour;
