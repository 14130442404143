import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

// hooks
import { useUser } from 'contexts/UserContext';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyList/_styles/JourneyCard.style';
import * as Text from 'components/_styles/Text.style';

interface JourneyBase {
  id: string;
  cover: string;
  microlearnings?: Array<any>;
  [key: string]: any;
}

type Journey = Partial<JourneyBase>;

interface JourneyCardProps {
  journey: Journey;
}

function JourneyCard({ journey }: JourneyCardProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { userRole } = useUser();
  const lang = useUserLanguage();

  const onCardClick = (): void => {
    if (userRole === userRoles.MEMBER)
      history.push({
        pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}`,
      });
  };

  const numMicrolearnings = journey.microlearnings?.length;
  const journeyTitle = journey[`title_${lang}`];
  return (
    <ErrorBoundary>
      <Styled.Containter onClick={onCardClick} userrole={userRole}>
        <Styled.Cover>
          <Styled.CoverImage src={journey.cover} />
        </Styled.Cover>
        <Styled.Content>
          <Styled.InfoSection>
            <Styled.Titles>
              {numMicrolearnings > 0 && (
                <Text.CaptionSemiboldCap>
                  {t('pageJourneyList:microlearnings', {
                    count: numMicrolearnings,
                  })}
                </Text.CaptionSemiboldCap>
              )}
              <Styled.JourneyTitle>
                {journeyTitle && <Text.H1Bold>{journeyTitle}</Text.H1Bold>}
              </Styled.JourneyTitle>
            </Styled.Titles>
          </Styled.InfoSection>
        </Styled.Content>
      </Styled.Containter>
    </ErrorBoundary>
  );
}

export default JourneyCard;
