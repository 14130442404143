import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Text from 'components/_styles/Text.style';
import * as Styled from '../_styles/LabourCostReductions1.style';

interface TimelineData {
  pastLabel: string;
  topStat: string;
  timeReducedPerManager: string;
  timeReducedPerDistrictManager: string;
  timeReducedForHRTeam: string;
  costReductionsPerManager: string;
  costReductionsPerDM: string;
  costReductionsForHR: string;
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function LabourCostReductions1(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      pastLabel: t('pastLabel.past month'),
      topStat: '61%',
      timeReducedPerManager: '28',
      timeReducedPerDistrictManager: '16',
      timeReducedForHRTeam: '13',
      costReductionsPerManager: '$1513',
      costReductionsPerDM: '$3521',
      costReductionsForHR: '$2484',
    },
    threeMonth: {
      pastLabel: t('pastLabel.past 3 months'),
      topStat: '76%',
      timeReducedPerManager: '73',
      timeReducedPerDistrictManager: '56',
      timeReducedForHRTeam: '45',
      costReductionsPerManager: '$3134',
      costReductionsPerDM: '$6432',
      costReductionsForHR: '$4213',
    },
    sixMonth: {
      pastLabel: t('pastLabel.past six months'),
      topStat: '79%',
      timeReducedPerManager: '122',
      timeReducedPerDistrictManager: '201',
      timeReducedForHRTeam: '103',
      costReductionsPerManager: '$5321',
      costReductionsPerDM: '$8621',
      costReductionsForHR: '$6532',
    },
    oneYear: {
      pastLabel: t('pastLabel.past year'),
      topStat: '83%',
      timeReducedPerManager: '327',
      timeReducedPerDistrictManager: '556',
      timeReducedForHRTeam: '387',
      costReductionsPerManager: '$9348',
      costReductionsPerDM: '$12,523',
      costReductionsForHR: '$9227',
    },
    all: {
      pastLabel: t('pastLabel.all time'),
      topStat: '92%',
      timeReducedPerManager: '431',
      timeReducedPerDistrictManager: '657',
      timeReducedForHRTeam: '453',
      costReductionsPerManager: '$12,431',
      costReductionsPerDM: '$14,245',
      costReductionsForHR: '$11,342',
    },
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('labourCostReductions1.Labour cost reductions')}
      </Text.P2Semibold>
      <Styled.Section>
        <Styled.TopStat>{data[timeline].topStat}</Styled.TopStat>
        <Styled.TopStatDetail>
          {t(
            'labourCostReductions1.Return on investment per manager per month'
          )}
        </Styled.TopStatDetail>
      </Styled.Section>

      <Styled.Divider />

      <Styled.Section>
        <Styled.SectionHeader>
          {t('labourCostReductions1.Time reduced')}
        </Styled.SectionHeader>
        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.Per manager')}
          </Styled.LightText>
          <Styled.DarkText>
            {t('labourCostReductions1.hrs', {
              number: data[timeline].timeReducedPerManager,
            })}
          </Styled.DarkText>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.Per district manager')}
          </Styled.LightText>
          <Styled.DarkText>
            {t('labourCostReductions1.hrs', {
              number: data[timeline].timeReducedPerDistrictManager,
            })}
          </Styled.DarkText>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.For HR team')}
          </Styled.LightText>
          <Styled.DarkText>
            {t('labourCostReductions1.hrs', {
              number: data[timeline].timeReducedForHRTeam,
            })}
          </Styled.DarkText>
        </Styled.SectionRow>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionHeader>
          {t('labourCostReductions1.Cost reductions')}
        </Styled.SectionHeader>
        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.Per manager')}
          </Styled.LightText>
          <Styled.DarkText>
            {data[timeline].costReductionsPerManager}
          </Styled.DarkText>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.Per district manager')}
          </Styled.LightText>
          <Styled.DarkText>
            {data[timeline].costReductionsPerDM}
          </Styled.DarkText>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>
            {t('labourCostReductions1.For HR team')}
          </Styled.LightText>
          <Styled.DarkText>
            {data[timeline].costReductionsForHR}
          </Styled.DarkText>
        </Styled.SectionRow>
      </Styled.Section>
      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
