import React from 'react';
import ChatBubble from 'components/pageECoach/ChatBubble';
import { LoadingStatus } from 'hooks/useEcoachItemsConsumer';
import * as Text from 'components/_styles/Text.style';

interface ECoachResponseProps {
  status?: string;
  loadingTitle?: string;
  loader?: React.ReactNode;
  children: React.ReactNode;
}

export default function ECoachResponse({
  status,
  loadingTitle,
  loader,
  children,
}: ECoachResponseProps): JSX.Element {
  return (
    <ChatBubble>
      {status === LoadingStatus.LOADED ? (
        children
      ) : (
        <>
          {loadingTitle && (
            <Text.SubheadingSemibold>{loadingTitle}</Text.SubheadingSemibold>
          )}
          {loader && loader}
        </>
      )}
    </ChatBubble>
  );
}
