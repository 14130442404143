import React, { useEffect } from 'react';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import JourneyCard from 'components/pageJourneyList/JourneyCard';

// hooks
import useApi from 'hooks/useApi';

// style
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';

interface Journey {
  id: string;
  [key: string]: any;
}

export default function JourneyListPage(): JSX.Element {
  const { data, callApi: getJourneyList } = useApi();

  const journeyList = data;

  useEffect(() => {
    getJourneyList('/members/journeys/');
  }, [getJourneyList]);

  if (journeyList.status === 'loading') return <ApiLoading />;

  return (
    <ErrorBoundary>
      <PageLayout.Page>
        {journeyList.response.map((journey: Journey) => (
          <JourneyCard journey={journey} key={journey.id} />
        ))}
      </PageLayout.Page>
    </ErrorBoundary>
  );
}
