/* eslint-disable react/prop-types */

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TourProvider, useTour, StepType } from '@reactour/tour';

// components
import CloseTour from 'components/demo/CloseTour';
import PageRoutes from 'components/_routes/PageRoutes';
import SidePanelRoutes from 'components/_routes/SidePanelRoutes';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useDemoProductTourSteps from 'hooks/useDemoProductTourSteps';
import useViewportHeight from 'hooks/useViewportHeight';

// styles
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';

export default function PageLayout(): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  const { data: user, callApi: getUser } = useUser();

  const steps = useDemoProductTourSteps(history);

  useViewportHeight();

  const inECoach = location.pathname.toLowerCase().includes('ecoach');
  const inOnboarding = location.pathname.includes('onboarding');

  return (
    <TourProvider
      key={user?.language}
      steps={steps as StepType[]}
      components={{
        Close: ({ onClick }) => {
          const { currentStep } = useTour();
          return <CloseTour onClick={onClick} currentStep={currentStep} />;
        },
      }}
      disableInteraction
      onClickMask={() => {}} // prevent tour from closing on background click
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#FF4D52',
          borderRadius: '20px',
          paddingTop: '40px',
        }),
        maskArea: (base) => ({ ...base, rx: 20 }),
        maskWrapper: (base) => ({ ...base, opacity: '0.5' }),
        badge: (base) => ({ ...base, display: 'none' }), // tour step number
        controls: (base) => ({ ...base, marginTop: 50 }),
        close: (base) => ({ ...base, left: 'auto', right: 20, top: 20 }),
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Container.ScreenContainer id="screen" inDemo={user.is_demo}>
          {!inOnboarding && <SidePanelRoutes getUser={getUser} />}
          <Container.PageContainer inECoach={inECoach}>
            <PageRoutes />
          </Container.PageContainer>
        </Container.ScreenContainer>
      </div>
    </TourProvider>
  );
}
