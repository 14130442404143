import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';

// styles
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

// SVG component
const GreenCheckIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2197_36490)">
      <path
        d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1178 55.8823 0 36 0C16.1178 0 0 16.1178 0 36C0 55.8823 16.1178 72 36 72Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1178 55.8823 0 36 0C16.1178 0 0 16.1178 0 36C0 55.8823 16.1178 72 36 72Z"
        fill="#41C47A"
      />
      <path
        d="M49.2 28.2L32.7 44.7L25.2 37.2"
        stroke="#218F50"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2197_36490">
        <rect width="72" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

function AboutCard(): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  const instructionRows = [
    t('convoPrep.AboutCard.Identify key talking points'),
    t('convoPrep.AboutCard.Set realistic expectations'),
    t('convoPrep.AboutCard.Plan for most likely responses'),
  ];

  const getRow = (row: string, key: number): JSX.Element => (
    <Styled.InstructionRow key={key}>
      <GreenCheckIcon />
      <Text.P2>{row}</Text.P2>
    </Styled.InstructionRow>
  );

  return (
    <ChatBubble>
      <Styled.Content>
        <Text.P2>
          {t('convoPrep.AboutCard.Create a clear conversation plan.')}
        </Text.P2>
        <Styled.InstructionSection>
          {instructionRows.map((row, key) => getRow(row, key))}
        </Styled.InstructionSection>
      </Styled.Content>
    </ChatBubble>
  );
}

export default AboutCard;
