export const UserInputType = {
  // Make sure to also update the UserInputType enum the backend project.
  // Do not change the values of the existing types, append new ones at the end.
  INITIAL_PROMPT: 0,
  CHALLENGE_VERIFICATION_ANSWER: 1,
  CHALLENGE_DESCRIPTION_FEEDBACK: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER: 3,
  BACK_AND_FORTH_CONVERSATION_INPUT: 4,
  CONVERSATION_PLAN_INPUT: 5,
  RESOLUTION_TAKEN_ACTION_ANSWER: 6,
  RESOLUTION_IS_RESOLVED_ANSWER: 7,
  RESOLUTION_SUMMARY_INPUT: 8,
  UNBLOCKER_ANSWER: 9,
  UNBLOCKER_FOLLOW_UP_ANSWER: 10,
  CONVERSATION_PREP_START: 11,
  CONVERSATION_PREP_WITH_WHO: 12,
  CONVERSATION_PREP_GOAL: 13,
  CONVERSATION_PREP_WORRIED_BY: 14,
  RESOLUTION_CONFIDENCE_ANSWER: 15,
  OUTCOME_VERIFICATION_ANSWER: 16,
} as const;

export const OutputType = {
  // Make sure to also update the OutputType enum the backend project.
  // Do not change the values of the existing types, append new ones at the end.
  CHALLENGE_VERIFICATION_QUESTION: 0,
  CHALLENGE_DESCRIPTION: 1,
  CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_DECLINE_FOLLOW_UP: 3,
  CHALLENGE_VERIFICATION_SUGGESTION_TRANSITION: 4,
  BACK_AND_FORTH_CONVERSATION_RESPONSE: 5,
  SUGGESTIONS: 6,
  RAG_SUMMARY: 7,
  CONVERSATION_PLAN: 8,
  RESOLUTION_TAKEN_ACTION_QUESTION: 9,
  RESOLUTION_IS_RESOLVED_QUESTION: 10,
  RESOLUTION_RESOLVED_TAKEN_ACTION_QUESTION: 11,
  UNBLOCKER_QUESTION: 12,
  UNBLOCKER_FOLLOW_UP_QUESTION: 13,
  UNBLOCKER_SUGGESTIONS: 14,
  CONVERSATION_PLAN_ABOUT: 15,
  CONVERSATION_PREP_WITH_WHO: 16,
  CONVERSATION_PREP_GOAL: 17,
  CONVERSATION_PREP_WORRIED_BY: 18,
  OUTCOME_CONFIDENCE_QUESTION: 19,
  OUTCOME_VERIFICATION_QUESTION: 20,
  SESSION_RESOLUTION_STARTED: 21,
  SESSION_RESOLVED: 22,
  SESSION_SKIPPED: 23,
} as const;

export type UserInputTypeValues = number;
export type OutputTypeValues = number;
