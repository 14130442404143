import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageOnboarding/_styles/ThemesForm.style';
import * as StyledCard from 'components/pageOnboarding/_styles/Cards.style';
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import * as Text from 'components/_styles/Text.style';

interface ThemesFormProps {
  handleSectionReturn: (section: number) => void;
  markComplete: (section: number) => void;
  setSubmittedThemesForm: (form: { themes: string[] }) => void;
  submittedThemesForm: { themes: string[] };
}

function ThemesForm(props: ThemesFormProps) {
  const {
    handleSectionReturn,
    markComplete,
    setSubmittedThemesForm,
    submittedThemesForm,
  } = props;

  const { data, callApi } = useApi();
  const { callApi: callCommunicationApi } = useApi();
  const { t } = useTranslation('pageOnboarding');

  const [selectedThemes, setSelectedThemes] = useState<string[]>(
    submittedThemesForm.themes
  );

  const themes: Record<string, string> = {
    communication: t('pageOnboarding:communication'),
    mental_health: t('pageOnboarding:mental'),
    burnout: t('pageOnboarding:burnout'),
    workplace_conflicts: t('pageOnboarding:workplace'),
    remote_work: t('pageOnboarding:remote'),
    diversity: t('pageOnboarding:diversity'),
    team_building: t('pageOnboarding:team'),
  };

  const nextPageClick = () => {
    const params = { themes: selectedThemes };
    callApi('/members/themes/ ', {
      method: 'put',
      data: params,
    });

    // TODO: remove need for communication form from backend
    const communicationParams = {
      communication_preference: 'phone_call',
      feedback_mode: 'virtual',
      feedback_timing: 'delayed',
    };
    callCommunicationApi('/members/communication-preferences/ ', {
      method: 'put',
      data: communicationParams,
    });
    setSubmittedThemesForm(params);
  };

  useEffect(() => {
    if (data.status === 'success') markComplete(3);
  }, [data.status, markComplete]);

  const backPageClick = () => handleSectionReturn(3);

  const handleThemeSelect = (theme: string) => {
    if (selectedThemes.includes(theme))
      setSelectedThemes((prev) => prev.filter((element) => element !== theme));
    if (selectedThemes.length === 3) return;
    if (!selectedThemes.includes(theme))
      setSelectedThemes((prev) => [...prev, theme]);
  };

  const header = t(
    'Which themes reflect the challenges or priorities you face in your daily leadership?'
  );
  const body = t('Select up to 3 areas of focus.');

  const getTheme = (theme: string) => {
    if (selectedThemes.includes(theme))
      return (
        <Styled.SelectedButton
          aria-label={themes[theme]}
          endIcon={<FavoriteIcon />}
          key={theme}
          onClick={() => handleThemeSelect(theme)}
        >
          {themes[theme]}
        </Styled.SelectedButton>
      );
    return (
      <Styled.ButtonSelect
        aria-label={themes[theme]}
        key={theme}
        onClick={() => handleThemeSelect(theme)}
      >
        {themes[theme]}
      </Styled.ButtonSelect>
    );
  };

  return (
    <StyledCard.Card>
      <StyledCard.TopSection>
        <StyledCard.InnerSection>
          <Text.H5Bold>{header}</Text.H5Bold>
          <Page.OnboardingP style={{ marginBottom: '40px' }}>
            {body}
          </Page.OnboardingP>
          <Styled.ThemeArea>
            {Object.keys(themes).map((key) => getTheme(key))}
          </Styled.ThemeArea>
        </StyledCard.InnerSection>
      </StyledCard.TopSection>
      <StyledCard.Navigation>
        <StyledCard.BackButton aria-label={t('back')} onClick={backPageClick}>
          <NavigateBeforeIcon />
        </StyledCard.BackButton>
        <StyledCard.NavButton aria-label={t('next')} onClick={nextPageClick}>
          <NavigateNextIcon />
        </StyledCard.NavButton>
      </StyledCard.Navigation>
    </StyledCard.Card>
  );
}

export default ThemesForm;
