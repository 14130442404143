import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

interface StatusProps {
  status?: string;
}

export const Tabs = styled('div')`
  box-shadow: inset 0px -2px 0px ${colours.neutralWarm[300]};
  display: flex;
  gap: 30px;
  height: 50px;
  margin-top: 0 !important;

  @media (max-width: ${device.laptop}) {
    gap: 10px;
    overflow-x: auto;
  }
`;

export const Tab = styled('div')`
  align-items: left;
  background-color: none;
  border-bottom: ${(props) =>
    props.isActive ? `2px solid ${colours.primary[600]}` : 'none'};
  cursor: pointer;
  display: flex;
  gap: 15px;
  padding: 0 5px;
  white-space: nowrap;

  h6 {
    color: ${(props) =>
      props.isActive
        ? `${colours.primary[600]}`
        : `${colours.neutralWarm[800]}`};
  }

  @media (max-width: ${device.laptop}) {
    align-items: center;
    background-color: ${(props) =>
      props.isActive ? `${colours.neutralWarm[200]}` : 'none'};
    border-radius: 5px 5px 0 0;
    padding: 0 15px;
  }
`;

export const NewConvoButton = styled('div')`
  display: none;

  @media (max-width: ${device.laptop}) {
    display: block;
  }
`;

export const Title = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;

  h3 {
    color: ${colours.neutralWarm[800]};
  }

  svg path {
    stroke: ${colours.neutralWarm[800]};
  }
`;

export const ToggleGroup = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  width: fit-content;

  svg {
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }
`;

export const Card = styled(Cards.Card)`
  align-items: stretch;
  border: 0.5px solid ${colours.neutralWarm[200]};
  box-shadow: none;
  cursor: pointer;
  flex: 1;
  height: fit-content;
  justify-content: space-between;
  overflow: visible !important;

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const ActiveConvosCard = styled(Card)`
  align-items: stretch;
  overflow: hidden;

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const BottomBlur = styled('div')`
  background-image: linear-gradient(
    rgba(255, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 1)
  );
  bottom: 0;
  height: 60px;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Date = styled(Text.P1Semibold)`
  color: ${colours.neutralCool[600]} !important;
`;

export const Bold = styled('b')`
  font-weight: 500;
`;

export const DeleteIcon = styled('div')`
  padding: 0 0 10px 10px;
  &:hover {
    svg path {
      stroke: ${colours.primary[500]};
    }
  }
`;

export const Notification = styled('div')`
  align-items: center;
  background-color: ${colours.primary[600]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  gap: 5px;
  justify-content: space-between;
  padding: 20px;

  p {
    color: ${colours.shade[0]};
  }

  svg {
    cursor: pointer;
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${colours.shade[0]};
  }
`;

export const Status = styled('div')<StatusProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  width: fit-content;

  p {
    color: ${(props) =>
      props.status === 'resolved'
        ? colours.mint[800]
        : colours.neutralCool[600]};
  }

  svg {
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${(props) =>
      props.status === 'resolved'
        ? colours.mint[800]
        : colours.neutralCool[600]};
  }
`;

export const BottomSection = styled('div')`
  display: flex;
  gap: 15px;
  justify-content: space-between;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

export const StatusContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StatusChip = styled('div')`
  background-color: ${colours.neutralCool[600]};
  border-radius: 3px;
  height: fit-content;
  padding: 3px 10px;
  width: fit-content;

  p {
    color: white;
  }
`;

export const ResolvedStatusChip = styled(StatusChip)`
  background-color: ${colours.mint[200]};

  p {
    color: ${colours.mint[800]};
  }
`;

export const InProgressStatusChip = styled(StatusChip)`
  background-color: ${colours.warning[200]};

  p {
    color: ${colours.neutralCool[800]};
  }
`;

export const DraftStatusChip = styled(StatusChip)`
  background-color: ${colours.neutralWarm[200]};

  p {
    color: ${colours.neutralWarm[900]};
  }
`;

export const EmptyStateContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  justify-content: center;
  margin: 100px 0;
  width: 100%;

  h6,
  p {
    color: ${colours.neutralWarm[700]};
  }
`;

export const EmptyStateIcon = styled('img')`
  width: 220px;
`;
