import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';
import PromptSetup from 'components/pageECoach/promptHelper/PromptSetup';
import QuestionGoal from 'components/pageECoach/promptHelper/QuestionGoal';
import QuestionNoTheme from 'components/pageECoach/promptHelper/QuestionNoTheme';
import QuestionTheme from 'components/pageECoach/promptHelper/QuestionTheme';

// hooks and helpers
import useAutoScroll from 'hooks/useAutoScroll';
import { PromptOption } from './prompts';

interface ChatGuideData {
  promptHelperGoal: PromptOption | string | null;
  promptHelperTheme: string | null;
  chatStartEnded: boolean;
}

interface PromptHelperProps {
  chatGuideData: ChatGuideData;
  handleChatGuidance: (data: Partial<ChatGuideData>) => void;
  onSubmit: (message: string) => void;
}

export default function PromptHelper({
  chatGuideData,
  handleChatGuidance,
  onSubmit,
}: PromptHelperProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const messagesEndRef = useAutoScroll(chatGuideData);

  const handleSelectedGoal = (selection: PromptOption | string) =>
    handleChatGuidance({ promptHelperGoal: selection });

  const handleSelectedTheme = (selection: string) =>
    handleChatGuidance({ promptHelperTheme: selection });

  const selectedGoal = chatGuideData.promptHelperGoal;
  const selectedTheme = chatGuideData.promptHelperTheme;

  return (
    <>
      <ChatBubble
        simpleString={t(
          "promptHelper.I need advice on a challenge I'm currently facing."
        )}
        sentByUser
      />
      <QuestionTheme
        handleSelectedTheme={handleSelectedTheme}
        selectedTheme={selectedTheme}
      />
      {selectedTheme && selectedTheme === 'Something else' && (
        <QuestionNoTheme />
      )}
      {selectedTheme && selectedTheme !== 'Something else' && (
        <QuestionGoal
          handleSelectedGoal={handleSelectedGoal}
          selectedGoal={selectedGoal}
          selectedTheme={selectedTheme}
        />
      )}
      {selectedTheme && selectedTheme !== 'Something else' && selectedGoal ? (
        <PromptSetup chatGuideData={chatGuideData} onSubmit={onSubmit} />
      ) : (
        <Chatbar autoFocus onSubmit={onSubmit} />
      )}
      <div ref={messagesEndRef} style={{ margin: 0 }} />
    </>
  );
}
