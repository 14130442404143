import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// assets
import { Arrow, General } from 'untitledui-js';

// components
import StatusDropdown from 'components/pageECoach/allChats/StatusDropdown';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as StyledDropdown from 'components/pageECoach/_styles/StatusDropdown.style';
import * as Text from 'components/_styles/Text.style';

// utils
import routesConfig from 'constants/routesConfig.json';

type ResolutionStatus = 'active' | 'resolved' | 'skipped';

interface Chat {
  id: string;
  resolution_status: string;
  [key: string]: any;
}

interface StatusProps {
  chat: Chat;
  clickSession?: (sessionId: string) => void;
  getConversations?: () => void;
}

export default function Status({
  chat,
  clickSession,
  getConversations,
}: StatusProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const { callApi: updateStatus } = useApi();
  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [hidden, setHidden] = useState<boolean>(true);
  const [status, setStatus] = useState<string>(chat.resolution_status);
  const [loadingOption, setLoadingOption] = useState<ResolutionStatus | null>(
    null
  );

  const handleDropdown = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setHidden((prev) => !prev);
  };

  const handleStatusChange = async (
    newStatus: ResolutionStatus,
    e?: React.MouseEvent
  ): Promise<void> => {
    if (e) e.stopPropagation();
    setLoadingOption(newStatus);
    try {
      // Wait for the status update
      await updateStatus(
        `${routesConfig.ECOACH.SESSIONS}${chat.id}/resolution-status/`,
        {
          method: 'put',
          data: { resolution_status: newStatus },
        }
      );
      if (!e && clickSession) clickSession(chat.id);
      setStatus(newStatus);
      setHidden((prev) => !prev);

      if (newStatus === 'skipped') {
        if (getConversations) getConversations();
      } else if (newStatus === 'resolved') {
        history.push(`${routesConfig.CONVO_PAGE}/${chat.id}`);
      } else {
        history.push(`${routesConfig.CONVO_PAGE}/${chat.id}?resolution=false`);
      }
    } catch (error) {
      console.error('Failed to update status:', error);
    }
    setLoadingOption(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setHidden(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setHidden]);

  switch (status) {
    case 'active':
      return (
        <>
          <StyledDropdown.Dropdown ref={dropdownRef}>
            <StyledDropdown.DropdownButton
              hidden={hidden}
              onClick={handleDropdown}
              fullWidth
            >
              {t('allChats.Status.Choose next step')}
              <Arrow.ChevronDown />
            </StyledDropdown.DropdownButton>
            {!hidden && (
              <StatusDropdown
                handleStatusChange={handleStatusChange}
                loadingOption={loadingOption}
              />
            )}
          </StyledDropdown.Dropdown>
        </>
      );

    case 'resolved':
      return (
        <AllChats.Status status={chat.resolution_status}>
          <General.CheckCircleBroken />
          <Text.P2Semibold>{t('allChats.Status.Resolved')}</Text.P2Semibold>
        </AllChats.Status>
      );

    default:
      return (
        <AllChats.Status status={chat.resolution_status}>
          <General.CheckCircleBroken />
          <Text.P2Semibold>
            {t('allChats.Status.No action needed')}
          </Text.P2Semibold>
        </AllChats.Status>
      );
  }
}
