import styled from 'styled-components';
import device from 'constants/devices';

interface ECoachSectionProps {
  hidden?: boolean;
}

export const Section = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const ECoachSection = styled(Section)<ECoachSectionProps>`
  display: ${(props) =>
    props.hidden?.toString() === 'true' ? 'none' : 'flex'};
  padding-left: 20px;
  padding-right: 20%;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;

export const UserSection = styled(Section)`
  align-items: flex-end;
  display: flex;
  padding-left: 20%;
  padding-right: 20px;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;
