import React, { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';

// styles
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import { UserInputType } from './utils';

interface AlternativeOption {
  action: () => void;
  text: string;
}

interface ECoachConfirmationInputProps {
  sendUserInput: (text: string, type: number) => void;
  inputType: number;
  alternativeOption?: AlternativeOption;
  positiveText?: string;
  negativeText?: string;
}

export default function ECoachConfirmationInput({
  sendUserInput,
  inputType,
  alternativeOption,
  positiveText = 'Yes',
  negativeText = 'No',
}: ECoachConfirmationInputProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const getPosthogEvent = useCallback(() => {
    switch (inputType) {
      case UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER:
        return 'eCoach_challenge_description';
      case UserInputType.RESOLUTION_TAKEN_ACTION_ANSWER:
        return 'eCoach_taken_resolution_action';
      case UserInputType.RESOLUTION_IS_RESOLVED_ANSWER:
        return 'eCoach_case_is_resolved';
      default:
        return '';
    }
  }, [inputType]);

  const onConfirm = useCallback(() => {
    sendUserInput(t(positiveText), inputType);
    posthog?.capture(`user_confirms_${getPosthogEvent()}`);
  }, [getPosthogEvent, inputType, positiveText, posthog, sendUserInput, t]);

  const onDecline = useCallback(() => {
    sendUserInput(t(negativeText), inputType);
    posthog?.capture(`user_declines_${getPosthogEvent()}`);
  }, [getPosthogEvent, inputType, negativeText, posthog, sendUserInput, t]);

  const onAlternative = useCallback(() => {
    alternativeOption?.action();
    posthog?.capture(`user_clicks_${alternativeOption?.text}`);
    sendUserInput(t('Skip'), inputType);
  }, [alternativeOption, inputType, posthog, sendUserInput, t]);

  return (
    <ChatBubble sentByUser>
      <StyledPromptControls.ButtonsColumn>
        <ClickableInput
          inputAction={onConfirm}
          inputText={t(positiveText)}
          inputType="light"
        />
        <ClickableInput
          inputAction={onDecline}
          inputText={t(negativeText)}
          inputType="light"
        />
        {alternativeOption && (
          <ClickableInput
            inputAction={onAlternative}
            inputText={alternativeOption.text}
            inputType="light"
          />
        )}
      </StyledPromptControls.ButtonsColumn>
    </ChatBubble>
  );
}
