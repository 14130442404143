import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import routesConfig from 'constants/routesConfig.json';

// assets
import { General } from 'untitledui-js';

// components
import DemoDialog from 'components/pageECoach/allChats/DemoDialog';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

interface StatusTabsProps {
  activeTab: string;
  setActiveTab: (session: string | null) => void;
}

interface UserData {
  is_team_lead?: boolean;
  is_demo?: boolean;
  is_internal?: boolean;
}

export default function StatusTabs({
  activeTab,
  setActiveTab,
}: StatusTabsProps): React.ReactElement {
  const { t } = useTranslation('pageEcoach');
  const history = useHistory();
  const posthog = usePostHog();
  const { data: user } = useUser() as { data: UserData };

  const [isDemoDialogOpen, setIsDemoDialogOpen] = useState(false);

  const handleTabSwitch = (tab: string) => {
    posthog?.capture(
      `status_tab_switch_to_${tab.replace(' ', '_').toLowerCase()}`
    );
    setActiveTab(tab);
  };

  const closeDemoModal = () => setIsDemoDialogOpen(false);
  const newConversation = useCallback(() => {
    if (user.is_demo && !user.is_internal) setIsDemoDialogOpen(true);
    else history.push(`${routesConfig.CONVO_PAGE}`);
  }, [history, user]);

  return (
    <>
      <Styled.Tabs>
        <Styled.Tab
          isActive={activeTab === 'All conversations'}
          onClick={() => handleTabSwitch('All conversations')}
        >
          <Text.H6Bold>
            {t('allChats.StatusTabs.All conversations')}
          </Text.H6Bold>
        </Styled.Tab>

        <Styled.Tab
          isActive={activeTab === 'In progress'}
          onClick={() => handleTabSwitch('In progress')}
        >
          <Text.H6Bold>{t('allChats.StatusTabs.In progress')}</Text.H6Bold>
        </Styled.Tab>

        <Styled.Tab
          isActive={activeTab === 'Resolved'}
          onClick={() => handleTabSwitch('Resolved')}
        >
          <Text.H6Bold>{t('allChats.StatusTabs.Resolved')}</Text.H6Bold>
        </Styled.Tab>

        <Styled.Tab
          isActive={activeTab === 'Drafts'}
          onClick={() => handleTabSwitch('Drafts')}
        >
          <Text.H6Bold>{t('allChats.StatusTabs.Drafts')}</Text.H6Bold>
        </Styled.Tab>
      </Styled.Tabs>

      <Styled.NewConvoButton>
        <Buttons.Primary onClick={newConversation}>
          <General.Plus />
          {t('allChats.New conversation')}
        </Buttons.Primary>

        <DemoDialog
          closeModal={closeDemoModal}
          isOpenConfirmDialog={isDemoDialogOpen}
        />
      </Styled.NewConvoButton>
    </>
  );
}
