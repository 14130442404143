import { useUser } from 'contexts/UserContext';

interface User {
  language: string;
}

function useUserLanguage(): string {
  const { data: user } = useUser() as { data: User | null };
  if (user) return user.language;
  return 'en';
}

export default useUserLanguage;
