import React from 'react';
import Markdown from 'mui-markdown';
import * as Styled from 'components/_pageLayout/_styles/StyledMarkdown.style';

interface StyledMarkdownProps {
  content?: string;
}

const StyledMarkdown = ({ content }: StyledMarkdownProps): JSX.Element => (
  <Styled.MarkdownContainer>
    <Markdown>{content}</Markdown>
  </Styled.MarkdownContainer>
);

export default StyledMarkdown;
