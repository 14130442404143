import React from 'react';
import { useTranslation } from 'react-i18next';

// constants
import colours from 'constants/colours';

// styles
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';

interface ConversationStep {
  title: string;
  short_description: string;
  [key: string]: any;
}

interface ConversationItemProps {
  item: ConversationStep;
}

export default function ConversationItem({
  item: conversationStep,
}: ConversationItemProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Content>
      <Text.SubheadingSemibold>
        {conversationStep.title}
      </Text.SubheadingSemibold>
      <Text.P2>
        {conversationStep.short_description}
        <b style={{ color: colours.blue[500] }}>{t('See more.')}</b>
      </Text.P2>
    </Styled.Content>
  );
}
