import React from 'react';
import { TFunction } from 'i18next';

// assets
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as LogoText } from 'assets/logoText.svg';
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import SuggestionCards from 'components/pageDemoRegistration/introCard/SuggestionCards';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as Text from 'components/_styles/Text.style';
import * as Styled from 'components/pageDemoRegistration/_styles/IntroCard.style';

interface IntroCardProps {
  t: TFunction;
}

export default function IntroCard({ t }: IntroCardProps): JSX.Element {
  // Helper function to safely cast translation results
  const translate = (key: string): string => t(key) as unknown as string;

  const instructionRows: string[] = [
    translate('IntroCard.Describe a workplace scenario you want advice on'),
    translate('IntroCard.Discuss it with Nurau'),
    translate('IntroCard.Receive a custom plan with practical approaches'),
  ];

  const scrollToForm = (): void => {
    const form = document.getElementById('registration');
    form?.scrollIntoView({ behavior: 'smooth' });
  };

  const topBar = (): JSX.Element => (
    <Styled.TopBar>
      <Styled.LogoText>
        <LogoText />
      </Styled.LogoText>
      <Styled.LogoIcon>
        <Logo />
      </Styled.LogoIcon>
      <Text.P1Semibold>{translate('IntroCard.Nurau Demo')}</Text.P1Semibold>
    </Styled.TopBar>
  );

  const headlineBlock = (): JSX.Element => (
    <Styled.Block>
      <Text.H2Bold>
        {translate('IntroCard.AI-powered coaching for managers')}
      </Text.H2Bold>
      <Text.P3>
        {translate(
          'IntroCard.Based on your company policies and the latest science, Nurau is designed to offer you tailor-made strategies to resolve complex workplace dynamics, empowering you to take decisive action when it matters most.'
        )}
      </Text.P3>
    </Styled.Block>
  );

  const howToBlock = (): JSX.Element => (
    <Styled.Block>
      <Text.H4Bold>{translate('IntroCard.Collaborate with Nurau')}</Text.H4Bold>
      <Styled.HowToRows>
        {instructionRows.map((row, key) => (
          <Styled.HowToRow key={key}>
            <GreenCheckIcon />
            <Text.P3>{row}</Text.P3>
          </Styled.HowToRow>
        ))}
      </Styled.HowToRows>
    </Styled.Block>
  );

  const eCoachBlock = (): JSX.Element => {
    const userPrompt = (): JSX.Element => (
      <ChatBubbleStyled.EditInputSection>
        <ChatBubbleStyled.UserMessage>
          <Styled.TemplateText>
            <ChatBubbleStyled.DescriptionText>
              {translate('IntroCard.What should I do when ...')}
            </ChatBubbleStyled.DescriptionText>
            <Styled.TemplateTextRow />
            <Styled.TemplateTextRow />
          </Styled.TemplateText>
        </ChatBubbleStyled.UserMessage>
      </ChatBubbleStyled.EditInputSection>
    );
    const userPrompt2 = (): JSX.Element => (
      <ChatBubbleStyled.EditInputSection>
        <ChatBubbleStyled.UserMessage>
          <Styled.PromptControl>
            <Text.P1Semibold>
              {translate('IntroCard.Offer me more suggestions')}
            </Text.P1Semibold>
          </Styled.PromptControl>
          <Styled.PromptControl>
            <Text.P1Semibold>
              {translate('IntroCard.Discuss my situation with me')}
            </Text.P1Semibold>
          </Styled.PromptControl>
        </ChatBubbleStyled.UserMessage>
      </ChatBubbleStyled.EditInputSection>
    );

    return (
      <Styled.ECoachCard>
        <Styled.ChatSection type="eCoach">
          <ChatBubble>
            <Styled.TextBox>
              <Text.P2Semibold>
                {translate(
                  "IntroCard.What's a challenge you're facing at work?"
                )}
              </Text.P2Semibold>
              <Text.P1>
                {translate(
                  'IntroCard.The more details you give, the better your results will be. Always confidential.'
                )}
              </Text.P1>
            </Styled.TextBox>
          </ChatBubble>
        </Styled.ChatSection>
        <Styled.ChatSection type="user">{userPrompt()}</Styled.ChatSection>
        <Styled.ChatSection type="eCoach">
          <ChatBubble>
            <Styled.TextBox>
              <Text.P2>
                {translate('IntroCard.Here are some suggestions for you')}
              </Text.P2>
            </Styled.TextBox>
          </ChatBubble>
        </Styled.ChatSection>
        <SuggestionCards t={t} />
        <Styled.ChatSection type="user">{userPrompt2()}</Styled.ChatSection>
      </Styled.ECoachCard>
    );
  };

  return (
    <Styled.Card>
      {topBar()}
      <Styled.Content>
        <Styled.LeftSide>
          {headlineBlock()}
          {howToBlock()}
          <Buttons.Primary centerText fullWidth onClick={scrollToForm}>
            {translate('IntroCard.Try the free demo')}
          </Buttons.Primary>
        </Styled.LeftSide>
        <Styled.RightSide>{eCoachBlock()}</Styled.RightSide>
      </Styled.Content>
    </Styled.Card>
  );
}
