import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import LogoText from 'assets/logoBlackText.svg';

// components
import StyledMarkdown from 'components/_pageLayout';

// hooks
import { useUser } from 'contexts/UserContext';

// styles
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';

interface ChatBubbleProps {
  children?: React.ReactNode;
  sentByUser?: boolean;
  simpleString?: string;
}

function ChatBubble({ children, sentByUser, simpleString }: ChatBubbleProps) {
  const { data: user } = useUser();
  const { t } = useTranslation();
  const name = user?.nickname || user?.first_name || t('ChatBubble.You');

  if (!sentByUser)
    return (
      <Page.ECoachSection>
        <ChatBubbleStyled.Section eCoach={true}>
          <ChatBubbleStyled.LogoText src={LogoText} />
          <ChatBubbleStyled.ECoachMessage>
            {simpleString ? (
              <StyledMarkdown content={simpleString} />
            ) : (
              children
            )}
          </ChatBubbleStyled.ECoachMessage>
        </ChatBubbleStyled.Section>
      </Page.ECoachSection>
    );

  if (sentByUser && !simpleString)
    return (
      <Page.UserSection>
        <ChatBubbleStyled.Section>{children}</ChatBubbleStyled.Section>
      </Page.UserSection>
    );

  return (
    <Page.UserSection>
      <ChatBubbleStyled.Section>
        <ChatBubbleStyled.UserMessage>
          <ChatBubbleStyled.Label>{name}</ChatBubbleStyled.Label>
          <ChatBubbleStyled.DescriptionText>
            {simpleString}
          </ChatBubbleStyled.DescriptionText>
        </ChatBubbleStyled.UserMessage>
      </ChatBubbleStyled.Section>
    </Page.UserSection>
  );
}

export default ChatBubble;
