import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import {
  Stack,
  Button,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from '@mui/material';

// context
import { useUser } from 'contexts/UserContext';
import { useConvoHistory } from 'contexts/ConvoHistoryContext';

// hooks
import useApi from 'hooks/useApi';
import useIndustries from 'hooks/demo/useIndustries';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Text from 'components/_styles/Text.style';
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';
import colours from 'constants/colours';

interface ApiResponse {
  status: string;
}

const DemoSettings: React.FC = () => {
  const history = useHistory();
  const { data: user } = useUser();
  const { getConversations } = useConvoHistory();
  const { callApi: resetDemoSessions, data: resetResponse } =
    useApi<ApiResponse>();
  const { callApi: updateIndustry, data: updateResponse } =
    useApi<ApiResponse>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showIndustrySuccess, setShowIndustrySuccess] =
    useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedIndustry, setSelectedIndustry] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  // Get industries based on user's language
  const industries = useIndustries(user?.language || 'en');

  // Redirect non-internal users away from this page
  useEffect(() => {
    if (!user?.is_internal) {
      history.push('/');
    }
  }, [user, history]);

  // Check for successful reset
  useEffect(() => {
    if (resetResponse?.status === 'success') {
      setSuccessMessage(
        'Demo reset successful! Conversations have been reset.'
      );
      setShowSuccess(true);
      setIsResetting(false);
      // Refresh conversations list
      getConversations();
    }
  }, [resetResponse, getConversations]);

  // Check for successful industry update
  useEffect(() => {
    if (updateResponse?.status === 'success') {
      setSuccessMessage('Industry updated successfully!');
      setShowIndustrySuccess(true);
      setIsUpdating(false);
    }
  }, [updateResponse]);

  // Reset tour and demo sessions
  const resetTour = async (): Promise<void> => {
    // Reset tour in localStorage
    window.localStorage.removeItem('hasCompletedTour');

    // Reset demo sessions via API
    setIsResetting(true);
    resetDemoSessions(`${routesConfig.ECOACH.SESSIONS}demo/reset/`, {
      method: 'put',
      data: { user },
    });
  };

  // Handle industry change
  const handleIndustryChange = (event: SelectChangeEvent<string>): void => {
    setSelectedIndustry(event.target.value);
  };

  // Handle industry update
  const handleIndustryUpdate = (): void => {
    if (selectedIndustry) {
      setIsUpdating(true);
      updateIndustry(`${routesConfig.ECOACH.SESSIONS}demo/reset/`, {
        method: 'put',
        data: {
          industry: selectedIndustry,
          user_id: user?.id,
        },
      });
      window.location.reload();
    }
  };

  // Handle closing the success message
  const handleCloseSnackbar = (): void => {
    setShowSuccess(false);
    setShowIndustrySuccess(false);
  };

  // If not an internal user, don't render the page content
  if (!user?.is_internal) {
    return null;
  }

  return (
    <Container.PageContainer>
      <Stack
        spacing={4}
        sx={{ maxWidth: '800px', margin: '0 auto', padding: '24px' }}
      >
        <Text.H2Bold>Demo Settings</Text.H2Bold>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Text.H3Bold>Reset Demo</Text.H3Bold>

              <Button
                variant="contained"
                onClick={resetTour}
                disabled={isResetting}
                sx={{ backgroundColor: colours.primary[600] }}
              >
                {isResetting ? 'Resetting...' : 'Reset Demo'}
              </Button>

              <Typography variant="body2" color="textSecondary">
                This will reset the demo tour and conversations to the initial
                state.
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Text.H3Bold>Change Industry</Text.H3Bold>

              <Typography variant="body2">
                Select an industry to update the demo content.
              </Typography>

              <FormControl fullWidth>
                <InputLabel id="industry-select-label">Industry</InputLabel>
                <Select
                  labelId="industry-select-label"
                  id="industry-select"
                  value={selectedIndustry}
                  label="Industry"
                  onChange={handleIndustryChange}
                >
                  {industries.length === 0 ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    industries.map((industry) => (
                      <MenuItem key={industry.id} value={industry.id}>
                        {industry.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleIndustryUpdate}
                  disabled={!selectedIndustry || isUpdating}
                  sx={{ backgroundColor: colours.primary[600] }}
                >
                  {isUpdating ? 'Updating...' : 'Confirm'}
                </Button>
              </Box>

              <Typography variant="body2" color="textSecondary">
                This will update the industry and update the demo content.
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <Snackbar
        open={showSuccess || showIndustrySuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Container.PageContainer>
  );
};

export default DemoSettings;
