import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Communication } from 'untitledui-js';

// components
import * as Buttons from 'components/_styles/Buttons.style';
import Dialog from '@mui/material/Dialog';

// styles
import colours from 'constants/colours';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import * as Text from 'components/_styles/Text.style';
import * as StyledChat from 'components/pageECoach/_styles/ChatBubble.style';

interface FeedbackFormProps {
  handleCreate: (feedback: {
    helpful: string;
    reason: string | null;
    other?: string;
  }) => void;
  feedbackStatus: {
    data?: {
      feedback_needed?: boolean;
    };
  };
}

interface FeedbackState {
  helpful: [string, [string, string]] | [];
  reason: string | null;
}

// TODO: Separate feedback form and thanks modal and use isVisible as visibility state instead of feedbackStatus
/**
 * feedbackStatus is used instead of isVisible to handle refresh when
 * thanks message is displayed and we want to request a new feedback
 * */
export default function FeedbackForm({
  feedbackStatus,
  handleCreate,
}: FeedbackFormProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  const [showOther, setShowOther] = useState<boolean>(false);
  const [otherReason, setOtherReason] = useState<string>('');
  const [showReason, setShowReason] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<FeedbackState>({
    helpful: [],
    reason: null,
  });
  const [showModal, setShowModal] = useState<boolean>(false);

  const resetForm = () => {
    setShowModal(false);
    setShowOther(false);
    setOtherReason('');
    setShowReason(false);
    setFeedback({ helpful: [], reason: null });
  };

  useEffect(() => {
    if (feedbackStatus?.data?.feedback_needed) setShowModal(true);
    else resetForm();
  }, [feedbackStatus]);

  const ANSWER_CHOICES = {
    YES: ['Yes', t('Yes')] as [string, string],
    NO: ['No', t('No')] as [string, string],
  };

  const DETAILS_CHOICES = {
    TOO_COMPLICATED: t('feedback.Too complicated'),
    NOT_RELEVANT: t('feedback.Not relevant'),
    NOT_ENOUGH_GUIDANCE: t('feedback.Not enough guidance'),
    OTHER: t('feedback.Other'),
  };

  const handleHelpful = (answerValue: string) => {
    const answer = Object.entries(ANSWER_CHOICES).find(
      ([, [value]]) => value === answerValue
    );

    if (answer) {
      setFeedback({
        helpful: answer as [string, [string, string]],
        reason: null,
      });
      setShowReason(answer[1] !== ANSWER_CHOICES.YES);
    }
  };

  const handleReason = (key: string, reason: string) => {
    if (key === 'OTHER') setShowOther(true);
    else setFeedback((prev) => ({ ...prev, reason }));
  };

  const getFeedbackOptions = () => (
    <>
      {Object.entries(ANSWER_CHOICES).map(([key, [value, label]]) => (
        <Buttons.Tertiary
          fullWidth
          key={key}
          onClick={() => handleHelpful(value)}
        >
          {label}
        </Buttons.Tertiary>
      ))}
    </>
  );

  const getReasonOptions = () => (
    <>
      {Object.entries(DETAILS_CHOICES).map(([key, value]) => (
        <Buttons.Tertiary
          fullWidth
          key={key}
          onClick={() => handleReason(key, value)}
        >
          {value}
        </Buttons.Tertiary>
      ))}
    </>
  );

  const getOtherInput = () => (
    <>
      <StyledPromptControls.InputTextArea
        id="outlined-multiline-static"
        multiline
        onChange={(input) => setOtherReason(input.target.value)}
        rows={3}
        sx={{ maxWidth: '100%', width: '800px' }}
        value={otherReason}
      />
      <div
        style={{
          alignContent: 'center',
          cursor: 'pointer',
          display: 'flex',
          flexWrap: 'unset',
          justifyContent: 'space-between',
        }}
      >
        <Text.P2Underline onClick={() => handleReason('OTHER_REASON', 'Other')}>
          {t('Skip')}
        </Text.P2Underline>
        <StyledChat.ButtonArea>
          <StyledChat.SubmitButton
            disabled={otherReason.length === 0}
            onClick={() => handleReason('OTHER_REASON', otherReason)}
            sx={{
              '&.Mui-disabled': {
                background: colours.neutralCool[100],
                color: '#c0c0c0',
              },
            }}
          >
            <Communication.Send01 strokeWidth={1} />
          </StyledChat.SubmitButton>
        </StyledChat.ButtonArea>
      </div>
    </>
  );

  useEffect(() => {
    if (
      feedback.helpful.length > 0 &&
      (feedback.helpful[1][0] === ANSWER_CHOICES.YES[0] || feedback.reason)
    ) {
      handleCreate({
        helpful: feedback.helpful[1][0],
        reason: feedback.reason,
      });
    }
  }, [feedback, ANSWER_CHOICES.YES, handleCreate]);

  const getContent = () => {
    if (showOther) return getOtherInput();
    if (showReason) return getReasonOptions();
    return getFeedbackOptions();
  };

  const getheader = () => {
    if (showReason)
      return t("feedback.What don't you like about these suggestions?");
    return t('feedback.Did you find Nurau helpful?');
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        resetForm();
      }}
      PaperComponent={StyledPromptControls.Card}
    >
      <StyledChat.Headers>
        <Text.SubheadingSemibold>{getheader()}</Text.SubheadingSemibold>
      </StyledChat.Headers>
      {getContent()}
    </Dialog>
  );
}
