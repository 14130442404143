import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

interface EscalationsProps {
  quantity: number;
  total: number;
}

interface IndexColourProps {
  type: 'prevented' | 'raised';
}

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    flex: 1;
    min-width: unset;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.neutralWarm[800]};
`;

export const TopStatDetail = styled('div')`
  display: flex;
  gap: 3px;

  svg {
    height: 16px;
    width: 16px;
    stroke-width: 3;
  }

  svg path {
    stroke: ${colours.mint[600]};
  }
`;

export const TopStatDetailNumber = styled(Text.P1Semibold)`
  color: ${colours.mint[600]};
`;

export const Visual = styled('div')`
  display: flex;
  gap: 3px;
  height: 20px;
  overflow: hidden;
  width: 100%;
`;

export const EscalationsPrevented = styled('div')<EscalationsProps>`
  background-color: ${colours.mint[600]};
  border-radius: ${(props) =>
    props.quantity === props.total ? '5px' : '5px 0 0 5px'};
  flex-basis: ${(props) => `${(props.quantity / props.total) * 100}%`};
  width: ${(props) => `${props.total}%`};
`;

export const EscalationsRaised = styled('div')<EscalationsProps>`
  background-color: ${colours.mint[200]};
  border-radius: 0 5px 5px 0;
  display: ${(props) => (props.quantity === 0 ? 'none' : 'flex')};
  flex: 1;
`;

export const SectionRow = styled('div')`
  display: flex;
  gap: 10px;
`;

export const IndexColour = styled('div')<IndexColourProps>`
  background-color: ${(props) =>
    props.type === 'prevented'
      ? `${colours.mint[600]}`
      : `${colours.mint[200]}`};
  border-radius: 30px;
  height: 15px;
  width: 15px;
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const DarkText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
`;

export default Card;
