import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Arrow } from 'untitledui-js';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Styled from 'components/pageinsights/_styles/EngagementBySite.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineData {
  topStat: string;
  industryComparison: string;
  montrealA1: number;
  montrealA2: number;
  torontoA1: number;
  pastLabel: string;
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function EngagementBySite(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      topStat: '68%',
      industryComparison: '10',
      montrealA1: -2,
      montrealA2: -1,
      torontoA1: 3,
      pastLabel: t('pastLabel.past month'),
    },
    threeMonth: {
      topStat: '73%',
      industryComparison: '15',
      montrealA1: 10,
      montrealA2: -1,
      torontoA1: 10,
      pastLabel: t('pastLabel.past 3 months'),
    },
    sixMonth: {
      topStat: '82%',
      industryComparison: '21',
      montrealA1: 20,
      montrealA2: -2,
      torontoA1: 20,
      pastLabel: t('pastLabel.past six months'),
    },
    oneYear: {
      topStat: '88%',
      industryComparison: '37',
      montrealA1: 30,
      montrealA2: -3,
      torontoA1: 30,
      pastLabel: t('pastLabel.past year'),
    },
    all: {
      topStat: '97%',
      industryComparison: '41',
      montrealA1: 40,
      montrealA2: -4,
      torontoA1: 40,
      pastLabel: t('pastLabel.all time'),
    },
  };

  const getStat = (number: number): React.ReactElement => {
    if (number > 0)
      return (
        <Styled.StatPaintedGreen>
          <Arrow.ArrowUp />
          <Text.P2Semibold>{number}%</Text.P2Semibold>
        </Styled.StatPaintedGreen>
      );

    return (
      <Styled.StatPaintedRed>
        <Arrow.ArrowDown />
        <Text.P2Semibold>{number}%</Text.P2Semibold>
      </Styled.StatPaintedRed>
    );
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('engagementBySite.Engagement by site')}
      </Text.P2Semibold>

      <Styled.SectionHighlighted>
        <Styled.TopStat>{data[timeline].topStat}</Styled.TopStat>
        <Styled.TopStatDetail>
          {t('engagementBySite.of onboarded users are active')}
        </Styled.TopStatDetail>
        <Styled.Divider />
        <Styled.TopStatDetailWhite>
          {t('engagementBySite.That is higher than your industry average', {
            number: data[timeline].industryComparison,
          })}
        </Styled.TopStatDetailWhite>
      </Styled.SectionHighlighted>

      <Styled.Section>
        <Styled.SectionRow>
          <Styled.SectionHeader>
            {t('engagementBySite.Site')}
          </Styled.SectionHeader>
          <Styled.SectionHeader>
            {t('engagementBySite.Engagement trends')}
          </Styled.SectionHeader>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>Montreal: A1</Styled.LightText>
          {getStat(data[timeline].montrealA1)}
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>Montreal: A2</Styled.LightText>
          {getStat(data[timeline].montrealA2)}
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.LightText>Toronto: A1</Styled.LightText>
          {getStat(data[timeline].torontoA1)}
        </Styled.SectionRow>
      </Styled.Section>

      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
