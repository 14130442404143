// useCompanySizeChoices.ts
import { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';

interface CompanySize {
  id: number;
  name: string;
}

const useCompanySizeChoices = (currentLanguage: string): CompanySize[] => {
  const { data, callApi } = useApi();
  const [companySizeChoices, setCompanySizeChoices] = useState<CompanySize[]>(
    []
  );

  useEffect(() => {
    callApi('/demo/company-size-choices', {
      method: 'get',
      headers: {
        'Accept-Language': currentLanguage,
      },
    });
  }, [callApi, currentLanguage]);

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      setCompanySizeChoices(data.response.company_sizes);
    }
  }, [data]);

  return companySizeChoices;
};

export default useCompanySizeChoices;
