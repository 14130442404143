import React, { ReactNode, ErrorInfo } from 'react';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/_pageLayout/_styles/ErrorBoundary.style';
import * as Text from 'components/_styles/Text.style';

function DefaultError(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Text.H5Extrabold>{t('pageLayout:error')}</Text.H5Extrabold>
    </Styled.Container>
  );
}

interface ErrorBoundaryProps {
  fallback?: JSX.Element;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error): {
    hasError: boolean;
    error: Error;
  } {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render(): ReactNode {
    const { fallback, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      if (fallback) return fallback;
      return <DefaultError />;
    }

    return children;
  }
}
