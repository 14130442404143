import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from 'assets/logo.svg';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Styled from 'components/pageinsights/_styles/GlobalEscalationLandscape.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineData {
  pastLabel: string;
  theme1: string;
  theme1Stat: string;
  theme2: string;
  theme2Stat: string;
  theme3: string;
  theme3Stat: string;
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function GlobalEscalationLandscape(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      pastLabel: t('pastLabel.past month'),
      theme1: t('themes.Employee relations'),
      theme1Stat: '30%',
      theme2: t('themes.Time management'),
      theme2Stat: '20%',
      theme3: t('themes.Team dynamics'),
      theme3Stat: '10%',
    },
    threeMonth: {
      pastLabel: t('pastLabel.past 3 months'),
      theme1: t('themes.Performance'),
      theme1Stat: '31%',
      theme2: t('themes.Recruitment'),
      theme2Stat: '21%',
      theme3: t('themes.Employee relations'),
      theme3Stat: '11%',
    },
    sixMonth: {
      pastLabel: t('pastLabel.past six months'),
      theme1: t('themes.Recruitment'),
      theme1Stat: '32%',
      theme2: t('themes.Health and safety'),
      theme2Stat: '22%',
      theme3: t('themes.Time management'),
      theme3Stat: '12%',
    },
    oneYear: {
      pastLabel: t('pastLabel.past year'),
      theme1: t('themes.Loss prevention'),
      theme1Stat: '33%',
      theme2: t('themes.Political acumen'),
      theme2Stat: '23%',
      theme3: t('themes.Customer service'),
      theme3Stat: '13%',
    },
    all: {
      pastLabel: t('pastLabel.all time'),
      theme1: t('themes.Sales'),
      theme1Stat: '34%',
      theme2: t('themes.Performance'),
      theme2Stat: '24%',
      theme3: t('themes.Time management'),
      theme3Stat: '14%',
    },
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('globalEscalationLandscape.Global escalation landscape')}
      </Text.P2Semibold>
      <Styled.SectionHeader>
        {t(
          'globalEscalationLandscape.Risk analysis based on in-app escalations'
        )}
      </Styled.SectionHeader>

      <Styled.Section>
        <Styled.SectionRow>
          <Styled.SectionLabel>
            <Styled.LightText>#1</Styled.LightText>
            <Styled.LightText>{data[timeline].theme1}</Styled.LightText>
          </Styled.SectionLabel>
          <Styled.StatPainted>+{data[timeline].theme1Stat}</Styled.StatPainted>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.SectionLabel>
            <Styled.LightText>#2</Styled.LightText>
            <Styled.LightText>{data[timeline].theme2}</Styled.LightText>
          </Styled.SectionLabel>
          <Styled.StatPainted>+{data[timeline].theme2Stat}</Styled.StatPainted>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.SectionLabel>
            <Styled.LightText>#3</Styled.LightText>
            <Styled.LightText>{data[timeline].theme3}</Styled.LightText>
          </Styled.SectionLabel>
          <Styled.StatPainted>+{data[timeline].theme3Stat}</Styled.StatPainted>
        </Styled.SectionRow>
      </Styled.Section>

      <Styled.SectionHighlighted>
        <Styled.SectionLabel>
          <Styled.Logo src={Logo} />
          <Text.P2Semibold>
            {t('globalEscalationLandscape.Nurau insights')}
          </Text.P2Semibold>
        </Styled.SectionLabel>
        <Text.P2>
          {t(
            'globalEscalationLandscape.of your managers have had conversations about in the past month.',
            {
              theme1Stat: data[timeline].theme1Stat,
              theme1: data[timeline].theme1.toLowerCase(),
            }
          )}
        </Text.P2>
        <Text.P2>
          {t('globalEscalationLandscape.This may indicate ambiguity in')}
          <Styled.Link>
            {t('globalEscalationLandscape.this policy')}
          </Styled.Link>
          .
        </Text.P2>
      </Styled.SectionHighlighted>

      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
