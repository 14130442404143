import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

interface ContainerProps {
  isActive?: boolean;
}

export const Container = styled('div')<ContainerProps>`
  align-items: left;
  background-color: ${(props) =>
    props.isActive ? `${colours.neutralWarm[300]}` : 'none'};
  border-left: ${(props) =>
    props.isActive ? `2px solid ${colours.primary[600]}` : 'none'};
  border-radius: 0 5px 5px 0;
  color: ${colours.neutralWarm[800]};
  cursor: pointer;
  display: flex;
  gap: 15px;
  padding: ${(props) => (props.isActive ? '15px 23px' : '15px 25px')};
  position: relative;

  svg {
    height: 22px;
    width: 22px;
    stroke-width: 2;
  }

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? `${colours.neutralWarm[300]}`
        : `${colours.neutralWarm[100]}`};
  }

  @media (max-width: ${device.laptop}) {
    border-radius: 0 10px 10px 0;
    padding: 25px;
  }
`;

export const InactiveContainer = styled(Container)`
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
`;

export const Label = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const Notification = styled('div')`
  align-items: center;
  background-color: ${colours.primary[600]};
  border-radius: 50px;
  box-shadow: ${shadows.XS};
  cursor: pointer;
  display: flex;
  min-height: 15px;
  justify-content: center;
  padding: 3px;
  min-width: 15px;
  max-height: fit-content;

  p {
    color: ${colours.shade[0]};
    font-size: 12px;
  }
`;
