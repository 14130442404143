import React from 'react';

// assets
import CircularProgress from '@mui/material/CircularProgress';

// styles
import * as Styled from 'components/_pageLayout/_styles/ApiLoading.style';

export default function ApiLoading(): JSX.Element {
  return (
    <Styled.Container>
      <CircularProgress />
    </Styled.Container>
  );
}
