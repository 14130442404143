import React, { ReactNode } from 'react';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// styles
import * as Styled from 'components/_pageLayout/_styles/ContentLayout.style';

interface ContentLayoutProps {
  children: ReactNode;
}

export default function ContentLayout({
  children,
}: ContentLayoutProps): JSX.Element {
  return (
    <ErrorBoundary>
      <Styled.ContentContainer>
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentContainer>
    </ErrorBoundary>
  );
}
