/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled('div')`
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  width: 100%;
`;
