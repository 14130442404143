import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import FinalCardImg from 'assets/finalCard.svg';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

interface IdentityForm {
  nickname: string;
  [key: string]: any; // Allow for other properties
}

interface EndCardProps {
  submittedIdentityForm: IdentityForm;
}

function EndCard({ submittedIdentityForm }: EndCardProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation('pageOnboarding');
  const posthog = usePostHog();

  const handleRedirect = useCallback(() => {
    history.push(routesConfig.CONVO_PAGE);
    posthog?.capture('user_completed_onboarding');
    window.location.reload();
  }, [history, posthog]);

  return (
    <Styled.Card>
      <Styled.TopSection>
        <img
          src={FinalCardImg}
          style={{ maxWidth: '220px', padding: '50px' }}
        />
        <Styled.BottomSection
          style={{ padding: '0 30px', textAlign: 'center' }}
        >
          <Text.H2>
            {t('done', {
              nickname: submittedIdentityForm.nickname,
            })}
          </Text.H2>
        </Styled.BottomSection>
        <Buttons.Primary
          aria-label={t('next')}
          centerText
          fullWidth
          onClick={handleRedirect}
          style={{ margin: '50px', maxWidth: '300px' }}
        >
          {t('getStarted')}
        </Buttons.Primary>
      </Styled.TopSection>
    </Styled.Card>
  );
}

export default EndCard;
