import { useUser } from 'contexts/UserContext';

interface User {
  nickname?: string;
  first_name: string;
}

function useUserNickname(): string {
  const { data: user } = useUser() as { data: User };
  return user.nickname || user.first_name;
}

export default useUserNickname;
