import { useEffect, useRef, RefObject } from 'react';

export default function useAutoScroll(steps: any): RefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && steps) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [steps]);

  return ref;
}
