import React, { createContext, useContext, useEffect, useState } from 'react';
import useApi from 'hooks/useApi';
import userRoles from 'constants/userRoles.json';
import { usePostHog } from 'posthog-js/react';

// Define basic types
interface UserContextType {
  data: any;
  callApi: any;
  userRole: string;
  setUserRole: React.Dispatch<React.SetStateAction<string>>;
  setOnboardingComplete: (complete: boolean) => void;
}

// Create the context with a default value
const UserContext = createContext<UserContextType>({
  data: null,
  callApi: async () => {
    throw new Error(
      'UserContext.callApi() was called before being initialized'
    );
  },
  userRole: '',
  setUserRole: () => {
    throw new Error(
      'UserContext.setUserRole() was called before being initialized'
    );
  },
  setOnboardingComplete: () => {
    throw new Error(
      'UserContext.setOnboardingComplete() was called before being initialized'
    );
  },
});

// Hook to use the context
const useUser = () => useContext(UserContext);

// Props interface
interface UserProviderProps {
  children: React.ReactNode;
}

interface UnauthorizedUser {
  status: number;
}

// Provider component
function UserProvider({ children }: UserProviderProps) {
  const { data, callApi } = useApi();
  const posthog = usePostHog();
  const [userRole, setUserRole] = useState('');
  const [unauthorizedUser, setUnauthorizedUser] =
    useState<UnauthorizedUser | null>(null);
  const [apiTimeoutId, setApiTimeoutId] = useState<NodeJS.Timeout | null>(null);

  // Fetch user data
  useEffect(() => {
    const authorization = localStorage.getItem('authorization');
    if (!authorization) {
      setUnauthorizedUser({ status: 401 });
      return;
    }

    callApi('/users/me/');

    const timeoutId = setTimeout(() => {
      console.error('API request timeout - forcing logout');
      localStorage.removeItem('authorization');
      setUnauthorizedUser({ status: 401 });
    }, 10000); // 10 seconds timeout

    setApiTimeoutId(timeoutId);

    // eslint-disable-next-line
    return () => { clearTimeout(timeoutId); };
  }, [callApi]);

  useEffect(() => {
    if (data.status === 'success' || data.status === 'error') {
      if (apiTimeoutId) {
        clearTimeout(apiTimeoutId);
        setApiTimeoutId(null);
      }

      if (data.status === 'error') {
        if (data.statusCode === 401) {
          localStorage.removeItem('authorization');
          setUnauthorizedUser({ status: 401 });
        }
      }
    }
  }, [data, apiTimeoutId]);

  // Set user role
  useEffect(() => {
    if (data.response?.member) setUserRole(userRoles.MEMBER);
  }, [data.response]);

  // Set up PostHog identification
  if (data.response && data.response.email) {
    const user = data.response;
    posthog.alias(user.email);
    posthog.identify(user.email, {
      id: user.id,
      email: user.email,
      is_demo: user.is_demo,
      categories: user.categories
        .filter((c: any) => c.category_field !== null)
        .map((c: any) => `${c.category} - ${c.category_field}`),
      scopes: user.scopes.map((scope: any) => scope.full_scope),
      company: user.organization.name,
      job_title: user.member.job_title,
      language: user.language,
      site: user.member.site,
    });
    posthog.group('company', user.organization.id, {
      name: user.organization.name,
    });
  }

  return (
    <UserContext.Provider
      value={{
        data: unauthorizedUser || data.response,
        callApi,
        userRole,
        setUserRole,
        setOnboardingComplete: () => {
          // Implementation of setOnboardingComplete
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, useUser, UserProvider };
