import React from 'react';
import { TFunction } from 'i18next';

// assets
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// styles
import * as Styled from 'components/pageDemoRegistration/_styles/RegistrationFormSubmitted.style';
import * as Text from 'components/_styles/Text.style';
import { Link } from '@mui/material';
import { Trans } from 'react-i18next';

interface RegistrationSuccessCardProps {
  t: TFunction;
}

const RegistrationSuccessCard: React.FC<RegistrationSuccessCardProps> = ({
  t,
}) => {
  // Helper function to safely cast translation results
  const translate = (key: string): string => t(key) as unknown as string;

  return (
    <Styled.Section>
      <Styled.SuccessCard>
        <GreenCheckIcon />
        <Text.H3>{translate("You're all set!")}</Text.H3>
        <Text.P3>
          {translate(
            "To access the app, simply click on the link provided in your inbox. Remember to check your spam folder if you don't see the email from us in your inbox."
          )}
        </Text.P3>

        <Text.P3>
          <Trans
            i18nKey="reachUsSuccess"
            components={[
              <Link key="0" href="mailto:info@nurau.com" underline="none" />,
            ]}
            ns="pageDemoRegistration"
          />
        </Text.P3>
        <Text.P3>{translate('Happy exploring!')}</Text.P3>
      </Styled.SuccessCard>
    </Styled.Section>
  );
};

export default RegistrationSuccessCard;
