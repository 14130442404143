import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/pageECoach/loaders/Loader';

// styles
import * as StyledDropdown from 'components/pageECoach/_styles/StatusDropdown.style';
import * as Text from 'components/_styles/Text.style';

type ResolutionStatus = 'active' | 'resolved' | 'skipped';

interface StatusDropdownProps {
  handleStatusChange: (status: ResolutionStatus, e: React.MouseEvent) => void;
  loadingOption: ResolutionStatus | null;
}

export default function StatusDropdown({
  handleStatusChange,
  loadingOption,
}: StatusDropdownProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  return (
    <StyledDropdown.StatusDropdown
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <StyledDropdown.DropdownCategory>
        <StyledDropdown.SelectionNeutral
          onClick={(e: React.MouseEvent) => handleStatusChange('skipped', e)}
        >
          <StyledDropdown.NeutralDecoration />
          {loadingOption === 'skipped' ? (
            <Loader inline />
          ) : (
            <Text.P2Semibold>
              {t('allChats.StatusDropdown.Remove as goal')}
            </Text.P2Semibold>
          )}
        </StyledDropdown.SelectionNeutral>
      </StyledDropdown.DropdownCategory>

      <StyledDropdown.DropdownCategory>
        <StyledDropdown.SelectionYellow
          onClick={(e: React.MouseEvent) => handleStatusChange('active', e)}
        >
          <StyledDropdown.YellowDecoration />
          {loadingOption === 'active' ? (
            <Loader inline />
          ) : (
            <Text.P2Semibold>
              {t('allChats.StatusDropdown.I want to discuss it more')}
            </Text.P2Semibold>
          )}
        </StyledDropdown.SelectionYellow>
      </StyledDropdown.DropdownCategory>

      <StyledDropdown.DropdownCategory>
        <StyledDropdown.SelectionGreen
          onClick={(e: React.MouseEvent) => handleStatusChange('resolved', e)}
        >
          <StyledDropdown.GreenDecoration />
          {loadingOption === 'resolved' ? (
            <Loader inline />
          ) : (
            <Text.P2Semibold>
              {t('allChats.StatusDropdown.Mark as done')}
            </Text.P2Semibold>
          )}
        </StyledDropdown.SelectionGreen>
      </StyledDropdown.DropdownCategory>
    </StyledDropdown.StatusDropdown>
  );
}
