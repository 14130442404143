interface ColorScale {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

interface ShadeScale {
  0: string;
  100: string;
}

interface ColourPalette {
  primary: ColorScale;
  error: ColorScale;
  warning: ColorScale;
  success: ColorScale;
  mint: ColorScale;
  teal: ColorScale;
  blue: ColorScale;
  violet: ColorScale;
  purple: ColorScale;
  neutralCool: ColorScale;
  neutralWarm: ColorScale;
  shade: ShadeScale;
}

export const colours: ColourPalette = {
  primary: {
    50: '#FFD9DA',
    100: '#FFB2B5',
    200: '#FF999C',
    300: '#FF8083',
    400: '#FF666A',
    500: '#FF4D52',
    600: '#D93439',
    700: '#AD1F23',
    800: '#6B1013',
    900: '#330809',
  },
  error: {
    50: '#FFE4D9',
    100: '#FFD4C2',
    200: '#FFB899',
    300: '#FFA680',
    400: '#FF9466',
    500: '#FF824D',
    600: '#D96736',
    700: '#AD4C23',
    800: '#6B2B10',
    900: '#331508',
  },
  warning: {
    50: '#FFF3CF',
    100: '#FFEDB8',
    200: '#FFE494',
    300: '#FFDC73',
    400: '#FFD34F',
    500: '#F2C333',
    600: '#D9AD2E',
    700: '#AD8A23',
    800: '#6B5410',
    900: '#332808',
  },
  success: {
    50: '#E9FFD4',
    100: '#E1FCC5',
    200: '#D0F5AB',
    300: '#C8F0A1',
    400: '#B5E882',
    500: '#9CDB5C',
    600: '#83C441',
    700: '#588F21',
    800: '#3C6315',
    900: '#1D3308',
  },
  mint: {
    50: '#D4FFE6',
    100: '#C5FCDD',
    200: '#ABF5CB',
    300: '#A1F0C3',
    400: '#82E8AE',
    500: '#5CDB93',
    600: '#41C47A',
    700: '#218F50',
    800: '#156337',
    900: '#08331A',
  },
  teal: {
    50: '#D9F2FF',
    100: '#BAE8FF',
    200: '#9EDFFF',
    300: '#85D6FF',
    400: '#5CC9FF',
    500: '#33BBFF',
    600: '#279ED9',
    700: '#1179AD',
    800: '#154F6B',
    900: '#062D40',
  },
  blue: {
    50: '#D9E6FF',
    100: '#BAD1FF',
    200: '#9EBEFF',
    300: '#85ADFF',
    400: '#5C92FF',
    500: '#3377FF',
    600: '#2780D9',
    700: '#1145AD',
    800: '#15326B',
    900: '#061A40',
  },
  violet: {
    50: '#E5D9FF',
    100: '#D6C2FF',
    200: '#BB99FF',
    300: '#AA80FF',
    400: '#9966FF',
    500: '#884DFF',
    600: '#6C36D9',
    700: '#5123AD',
    800: '#2E106B',
    900: '#160833',
  },
  purple: {
    50: '#FFD9FD',
    100: '#FFC2FC',
    200: '#FF99FA',
    300: '#FF80F9',
    400: '#FF66F7',
    500: '#FF4DF6',
    600: '#D936D1',
    700: '#AD23A6',
    800: '#6B1067',
    900: '#330831',
  },
  neutralCool: {
    50: '#F2F5F7',
    100: '#E9EDF2',
    200: '#D8E0E8',
    300: '#B9C6D4',
    400: '#9DABBC',
    500: '#7A8899',
    600: '#5D6A7A',
    700: '#425061',
    800: '#283442',
    900: '#161F29',
  },
  neutralWarm: {
    50: '#F7F7F5',
    100: '#F2F2F0',
    200: '#E8E7E3',
    300: '#D4D3CE',
    400: '#BCBBB6',
    500: '#999893',
    600: '#7A7A76',
    700: '#61605D',
    800: '#424240',
    900: '#292927',
  },
  shade: {
    0: '#FFFFFF',
    100: '#000000',
  },
};

export default colours;
