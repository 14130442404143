import styled from 'styled-components';
import Button from '@mui/material/Button';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const MultiCardSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 20px;
  box-shadow: ${shadows.M};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  max-width: 100%;
  padding: 30px;
  position: relative;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${colours.primary[500]};
  border-radius: 50px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;

  :hover {
    background-color: ${colours.primary[600]};
  }

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export { StyledButton as Button };
