interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  industry: string;
  additional_infos: {
    user_specific_industry: string;
    job_title: string;
    experience_in_years: string;
    company_size: string;
    team_size: string;
    issues: string;
  };
}

export const getInitialValues = (): FormValues => ({
  first_name: ``,
  last_name: ``,
  email: ``,
  language: ``,
  industry: ``,
  additional_infos: {
    user_specific_industry: ``,
    job_title: ``,
    experience_in_years: ``,
    company_size: ``,
    team_size: ``,
    issues: ``,
  },
});

export default getInitialValues;
