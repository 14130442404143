export const avatars: string[] = [
  'happy',
  'cat',
  'robot',
  'alien',
  'heart',
  'owl',
  'leaf',
  'music_note',
  'dog',
  'mermaid',
  'book',
  'sun',
  'bolt',
  'star',
  'unicorn',
];

export const colours: string[] = [
  '#d93439',
  '#d96736',
  '#d9ad2e',
  '#588f21',
  '#41c47a',
  '#279ed9',
  '#2762d9',
  '#6c36d9',
  '#d936d1',
];
