import React from 'react';
import * as Text from 'components/_styles/Text.style';
import { Arrow } from 'untitledui-js';
import { Stack, Divider } from '@mui/material';
import ConditionalWrapper from 'components/conditionalWrapper/_index';
import colours from 'constants/colours';
import { LoadingStatus } from '../../hooks/useEcoachItemsConsumer';
import * as Styled from './_styles/Report.style';
import LoadingAwareWrapper from './LoadingAwareItem';

interface Item {
  id: string;
  title: string;
  [key: string]: any;
}

interface SelectedItem {
  status: LoadingStatus;
  data?: Item;
}

interface ClickableListCardProps {
  initialText?: string;
  isNumbered?: boolean;
  ItemDetailsComponent: React.ComponentType<{ item: Item }>;
  items: Item[];
  ListItemComponent: React.ComponentType<{ item: Item }>;
  listTitle?: string;
  selectedItem: SelectedItem;
  selectItem: (id: string | null) => void;
}

export default function ClickableListCard({
  initialText,
  isNumbered,
  ItemDetailsComponent,
  items,
  ListItemComponent,
  listTitle,
  selectedItem,
  selectItem,
}: ClickableListCardProps): JSX.Element {
  if (selectedItem.status !== LoadingStatus.LOADED) {
    return (
      <div
        style={{
          border: `1px solid ${colours.neutralWarm[300]}`,
          borderRadius: '10px',
          padding: '10px 20px',
        }}
      >
        <Text.CaptionSemiboldCap>{listTitle || ''}</Text.CaptionSemiboldCap>
        {initialText && <Text.P2>{initialText}</Text.P2>}
        <Stack divider={<Divider flexItem />}>
          {items.map((item, index) => (
            <Styled.ActionItem key={item.id}>
              <LoadingAwareWrapper
                itemId={item.id}
                loadingId={selectedItem.data?.id}
                handleClick={() => selectItem(item.id)}
              >
                <ConditionalWrapper
                  condition={isNumbered}
                  wrapper={(children) => (
                    <Styled.NumberedContent>{children}</Styled.NumberedContent>
                  )}
                >
                  {isNumbered && (
                    <Styled.NumberBox>
                      <Styled.Number>{index + 1}</Styled.Number>
                    </Styled.NumberBox>
                  )}
                  <Styled.Content>
                    <ListItemComponent item={item} />
                  </Styled.Content>
                </ConditionalWrapper>
              </LoadingAwareWrapper>
            </Styled.ActionItem>
          ))}
        </Stack>
      </div>
    );
  }

  return (
    <div
      style={{
        border: `1px solid ${colours.neutralWarm[300]}`,
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <Styled.Header onClick={() => selectItem(null)}>
        <Arrow.ChevronLeft />
        <Text.SubheadingSemibold>
          {selectedItem.data.title}
        </Text.SubheadingSemibold>
      </Styled.Header>
      <ItemDetailsComponent item={selectedItem.data} />
    </div>
  );
}
