import React, { useEffect } from 'react';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/sidePanel/_styles/SidePanel.style';

interface LanguageToggleProps {
  getUser: (path: string) => void;
}

interface UserData {
  language: string;
}

export default function LanguageToggle({
  getUser,
}: LanguageToggleProps): JSX.Element {
  const { data: submission, callApi: updateUser } = useApi();
  const { data: user } = useUser() as { data: UserData };

  const switchLanguage = () => {
    const newLanguage = user.language === 'en' ? 'fr' : 'en';
    const params = { language: newLanguage };
    updateUser('/users/language/', {
      method: 'put',
      data: params,
    });
  };

  const languageLabel = user?.language === 'en' ? 'Français' : 'English';

  useEffect(() => {
    if (submission.status === 'success') getUser('/users/me');
  }, [submission, getUser]);

  return (
    <div style={{ cursor: 'pointer' }} onClick={switchLanguage}>
      <Styled.BottomText>{languageLabel}</Styled.BottomText>
    </div>
  );
}
