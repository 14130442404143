import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

interface SectionProps {
  eCoach?: boolean;
}

interface SubmitButtonProps {
  disabled?: boolean;
}

export const ChatBubble = styled(Cards.Card)`
  p {
    color: ${colours.neutralCool[800]};
  }
`;

export const Label = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[800]};
`;

export const Section = styled('div')<SectionProps>`
  align-items: ${(props) => (props.eCoach ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  width: 100%;
`;

// ecoach chat styles

export const ECoachMessage = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 25px;
  width: fit-content;

  p {
    color: ${colours.neutralWarm[800]};
  }
`;

export const Headers = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// user chat styles

export const EditInputSection = styled('div')`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserMessage = styled(Cards.Card)`
  border: 0.5px solid ${colours.neutralWarm[100]};
  border-radius: 20px;
  box-shadow: unset;
  gap: 10px;
  padding: 30px;
  width: fit-content;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }
`;

export const DescriptionText = styled(Text.P2)`
  color: ${colours.neutralCool[800]};
  white-space: pre-line;
`;

export const ButtonArea = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(IconButton)<SubmitButtonProps>`
  background-color: ${colours.primary[500]};
  border-radius: 10px;

  &:hover {
    background-color: ${colours.primary[600]};
  }
  svg path {
    stroke: ${(props) =>
      props.disabled ? colours.neutralCool[300] : colours.shade[0]};
  }
`;

export const LogoText = styled('img')`
  box-sizing: unset;
  height: 18px;
  max-width: 82px;
  width: fit-content;
`;
