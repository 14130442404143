import React from 'react';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import { Stack } from '@mui/material';

// context
import { useUser } from 'contexts/UserContext';

// demo
import DemoPage from 'components/pageinsights/_pageDemo';
import UserPage from 'components/pageinsights/_pageUser';

// styles
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';

export default function Insights() {
  const { data: user } = useUser();

  return (
    <ErrorBoundary>
      <PageLayout.Page>
        <Stack spacing={4}>{user.is_demo ? <DemoPage /> : <UserPage />}</Stack>
      </PageLayout.Page>
    </ErrorBoundary>
  );
}
