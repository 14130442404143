import React from 'react';
import { usePostHog } from 'posthog-js/react';

// components
import DatesAndStatus from 'components/pageECoach/allChats/DatesAndStatus';
import DeleteButton from 'components/pageECoach/allChats/DeleteButton';
import Status from 'components/pageECoach/allChats/Status';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';
import { Divider } from 'components/pageECoach/_styles/Divider.style';

interface Chat {
  id: string;
  goal?: string;
  title: string;
  resolution_status: string;
  created_at: string;
  modified_at: string;
  [key: string]: any;
}

interface CardProps {
  chat: Chat;
  index: number;
  getConversations: () => void;
  getSession: (sessionId: string) => void;
  openDeleteModal: (e: React.MouseEvent, chat: Chat) => void;
  setLoadingTrue: () => void;
}

export default function Card({
  chat,
  index,
  getConversations,
  getSession,
  openDeleteModal,
  setLoadingTrue,
}: CardProps): JSX.Element {
  const posthog = usePostHog();

  const clickSession = (pastSessionId: string): void => {
    setLoadingTrue();
    getSession(pastSessionId);
    posthog?.capture('user_clicked_past_session');
  };

  return (
    <AllChats.ActiveConvosCard
      onClick={() => clickSession(chat.id)}
      className={`${index === 0 ? 'demo-tour-step-9 demo-tour-step-10' : ''}`}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <div
          style={{
            display: 'flex',
            gap: '30px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ maxWidth: '70ch' }}>
            <Text.P3>{chat.goal || chat.title}</Text.P3>
          </div>
          <DeleteButton chat={chat} openDeleteModal={openDeleteModal} />
        </div>
        <Divider />
      </div>
      <AllChats.BottomSection>
        <DatesAndStatus chat={chat} />
        {chat.resolution_status === 'active' && (
          <Status
            chat={chat}
            clickSession={clickSession}
            getConversations={getConversations}
          />
        )}
      </AllChats.BottomSection>
    </AllChats.ActiveConvosCard>
  );
}
