import React, { useEffect } from 'react';

// components
import { Files } from 'untitledui-js';

import ApiLoading from 'components/_pageLayout/ApiLoading';
import ShareButton from 'components/pageECoach/documentDisplay/DocumentShareButton';

// hooks
import useApi from 'hooks/useApi';
import useBrowserDetection from 'hooks/useBrowserDetection';
import { isAndroid, isMobile } from 'react-device-detect';

// styles
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';

interface DocumentRowProps {
  docParentId?: string;
  docTitle?: string;
  language?: string;
  onPage?: boolean;
  session?: string;
  bucketUrl?: string;
}

interface ApiResponse {
  status: string;
  response: {
    url: string;
  };
}

export default function DocumentRow({
  docParentId,
  docTitle,
  language,
  onPage,
  session,
  bucketUrl,
}: DocumentRowProps): JSX.Element {
  const { data, callApi: getDocument } = useApi();
  const browser = useBrowserDetection();

  const getExtensionFromUrl = (): string => {
    if (!bucketUrl) return 'docx';
    const extension = bucketUrl.toLowerCase().split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'ppt':
      case 'pptx':
        return 'ppt';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'photo';
      case 'doc':
      case 'docx':
      default:
        return 'docx';
    }
  };

  const docPageURL = `/documents/${docParentId}/${getExtensionFromUrl()}?language=${language}`;
  const docPageURLWithSource = `${docPageURL}&source=${session}`;
  const isBucketUrl = bucketUrl?.startsWith('https');

  useEffect(() => {
    const getDocumentType = () => {
      if (!bucketUrl) return 'pdf';
      const extension = bucketUrl.toLowerCase().split('.').pop();
      switch (extension) {
        case 'pdf':
          return 'pdf';
        case 'ppt':
        case 'pptx':
          return 'powerpoint';
        case 'png':
        case 'jpg':
        case 'jpeg':
          return 'photo';
        case 'doc':
        case 'docx':
        default:
          return 'word';
      }
    };

    getDocument(docPageURL, getDocumentType());
  }, [getDocument, docPageURL, bucketUrl]);

  if (data.status === 'loading') return <ApiLoading />;

  const routerLink = () =>
    isBucketUrl ? (
      <Styled.StyledLink
        href={bucketUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {docTitle}
      </Styled.StyledLink>
    ) : (
      <Styled.StyledLink
        as={Styled.RouterLink}
        href={docPageURLWithSource}
        to={docPageURLWithSource}
        target="_blank"
        rel="noopener noreferrer"
      >
        {docTitle}
      </Styled.StyledLink>
    );

  const downloadLink = () =>
    isBucketUrl ? (
      <Styled.StyledLink
        href={bucketUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {docTitle}
      </Styled.StyledLink>
    ) : (
      <Styled.StyledLink
        download={docTitle}
        href={(data as ApiResponse).response.url}
        style={{ textDecoration: 'none', fontSize: `${onPage && '18px'}` }}
      >
        {docTitle}
      </Styled.StyledLink>
    );

  const shouldUseRouterLink = (): boolean => {
    const extension = getExtensionFromUrl();
    return isBucketUrl || extension === 'pdf';
  };

  const renderContent = () => {
    const link = shouldUseRouterLink() ? routerLink() : downloadLink();
    const shareButton = shouldUseRouterLink() && (
      <ShareButton docURL={docPageURL} bucketUrl={bucketUrl} />
    );

    if (
      onPage &&
      (isAndroid ||
        ['safari', 'edge', 'ie'].includes(browser) ||
        !(browser === 'safari' && !isMobile))
    ) {
      return (
        <Styled.StyledCard>
          <div style={{ width: 'fit-content' }}>
            <Files.File05 size={'80'} strokeWidth={1.25} />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            {downloadLink()}
            {shareButton}
          </div>
        </Styled.StyledCard>
      );
    }
    return (
      <Styled.Row>
        {link}
        {shareButton}
      </Styled.Row>
    );
  };

  return renderContent();
}
