import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define a simple interface for children props
interface Props {
  children?: React.ReactNode;
}

// Simple style provider component with TypeScript
const StyleProvider: React.FC<Props> = ({ children }) => {
  // Create the theme
  const theme = createTheme();

  // Apply customizations with type assertions to avoid TypeScript errors
  (theme.palette as any).primary = {
    main: '#ff6b61',
    contrastText: 'white',
  };

  (theme.palette.text as any).primary = 'rgba(0, 0, 0, 0.8)';

  theme.palette.background.default = '#f9f9f9';

  theme.typography.h2.fontSize = '18px';

  (theme.typography as any).h3 = {
    fontSize: '16px',
    fontWeight: '700',
  };

  (theme.typography as any).h4 = {
    fontSize: '14px',
    fontWeight: 500,
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyleProvider;
