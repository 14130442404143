import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// styles
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';

interface ConversationItem {
  description?: string;
  prep?: string[];
  reactions?: string[];
  example_language?: string[];
  [key: string]: any;
}

interface ConversationItemDetailsProps {
  item: ConversationItem;
}

export default function ConversationItemDetails({
  item,
}: ConversationItemDetailsProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('convo_item_loaded');
  }, [posthog]);

  if (!item) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '15px 0 15px 30px',
      }}
    >
      <Text.P2>{item?.description}</Text.P2>
      <Styled.Content>
        <Text.P2Semibold>
          {t('convoPrep.ConversationItemDetails.How to prepare')}
        </Text.P2Semibold>
        <Styled.List>
          {item?.prep?.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>
          {t(
            'convoPrep.ConversationItemDetails.Anticipating and handling reactions'
          )}
        </Text.P2Semibold>
        <Styled.List>
          {item?.reactions?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>
          {t('convoPrep.ConversationItemDetails.Example dialogue')}
        </Text.P2Semibold>
        <Styled.List>
          {item?.example_language?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </div>
  );
}
