import React from 'react';
import dayjs from 'helpers/dayjs';

// components
import DividerWithText from 'components/pageECoach/cardTemplates/DividerWithText';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

interface ChatDatestampProps {
  index?: number;
  timestamp?: string;
  prevTimestamp?: string;
}

function ChatDatestamp({
  index,
  timestamp,
  prevTimestamp,
}: ChatDatestampProps): JSX.Element {
  const lang = useUserLanguage();
  dayjs.locale(lang);

  const format = lang === 'en' ? 'ddd, MMM D, YYYY' : 'ddd D MMM YYYY';
  const timestampString = dayjs(timestamp).locale(lang).format(format);
  const prevTimestampString = dayjs(prevTimestamp).locale(lang).format(format);

  if (timestampString === prevTimestampString || index === 0) return <></>;
  return <DividerWithText text={timestampString} />;
}

export default ChatDatestamp;
