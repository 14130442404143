import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

// TypeScript interfaces
interface ContainerProps {
  hubSelection: string;
}

interface NewConvoButtonProps {
  isActive?: boolean;
}

export const Container = styled('div')<ContainerProps>`
  align-items: left;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 30px;
  width: 370px;
  min-width: 370px;
  max-width: 370px;

  @media (max-width: ${device.laptop}) {
    background: rgba(41, 41, 39, 0.7);
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    max-width: unset;
    min-width: unset;
    padding: 0;
    visibility: ${(props) =>
      props.sidePanelOpen?.toString() === 'true' ? 'visible' : 'hidden'};
    width: 100%;
    z-index: 100;
  }
`;

export const InnerContainer = styled('div')`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  overflow: auto;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    background-color: ${colours.neutralWarm[200]};
    box-sizing: border-box;
    box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.5);
    height: 100%;
    max-width: 400px;
    padding: 15px;
    width: 90%;
  }
`;

export const Navigation = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
`;

export const BottomText = styled(Text.P3Semibold)`
  color: ${colours.neutralWarm[800]};
  cursor: pointer;

  &:hover {
    color: ${colours.neutralWarm[900]};
  }
`;

export const OpenMenu = styled('div')`
  display: none;

  @media (max-width: ${device.laptop}) {
    align-items: center;
    background-color: ${colours.neutralWarm[50]};
    border-bottom: 0.5px solid ${colours.neutralWarm[300]};
    box-sizing: border-box;
    color: ${colours.neutralWarm[700]};
    cursor: pointer;
    display: flex;
    gap: 5px;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
`;

export const NewConvoButton = styled('div')<NewConvoButtonProps>`
  align-items: left;
  background: linear-gradient(
        to top right,
        ${colours.neutralWarm[50]},
        ${colours.neutralWarm[50]}
      )
      padding-box,
    linear-gradient(to top right, #202c3c, #9abfed) border-box;
  border: ${(props) =>
    props.isActive ? '2px solid transparent;' : '1px solid transparent;'};
  border-radius: 20px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;

  padding: ${(props) => (props.isActive ? '19px' : '20px')};

  &:hover {
    border: 2px solid transparent;
    padding: 19px;
  }
`;
