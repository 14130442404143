import styled from 'styled-components';
import { IconButton } from '@mui/material';
import device from 'constants/devices';

interface AvatarSelectProps {
  selected?: boolean;
}

export const AvatarSelectedArea = styled('div')`
  align-items: center;
  border-bottom: 1px solid #f4f4f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

export const AvatarArea = styled('div')`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${device.mobileL}) {
    justify-content: space-around;
  }
`;

export const TopSection = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  overflow: auto;
  z-index: 2;

  @media (max-width: ${device.tablet}) {
    margin-top: 10px;
    overflow: unset;
  }
`;

export const Cell = styled('div')`
  display: flex;
  flex-basis: 33%;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SelectedAvatar = styled('div')`
  align-items: center;
  border: 1px solid #f4f4f2;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 150px;
  width: 150px;
`;

export const AvatarSelect = styled('div')<AvatarSelectProps>`
  align-items: center;
  border: ${(props) => (props.selected ? '1px solid #f4f4f2' : 'none')};
  border-radius: 100px;
  display: flex;
  gap: 10px;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 90px;

  :hover {
    background-color: #f4f4f2;
    cursor: pointer;
  }
`;

export const Tabs = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
  }
`;

export const TabButton = styled(IconButton)`
  background-color: #ff4d52;
  border-radius: 12px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 45px;
  min-width: 180px;
  padding: 5px 30px;
  text-transform: uppercase;

  :hover {
    background-color: #ffc2c4;
  }

  @media (max-width: ${device.mobileL}) {
    margin: 10px 0;
    width: 100%;
  }
`;
