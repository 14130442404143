import styled, { css } from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';

interface SubmitProps {
  disabled?: boolean;
}

export const HeaderBlock = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Card = styled(Cards.BasicCard)`
  gap: 30px;
  border-radius: 30px;
  padding: 30px;
  max-width: unset;
  max-width: 1200px;

  @media (max-width: ${device.tablet}) {
    box-shadow: none;
  }
`;

export const Submit = styled(Buttons.Primary)<SubmitProps>`
  padding-right: 50px;
  padding-left: 50px;

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${colours.neutralCool[50]};
      color: ${colours.shade[0]};
      pointer-events: none;
      content: '';

      path {
        height: 17px;
        width: 17px;
      }

      svg path {
        stroke: ${colours.neutralCool[300]};
      }
    `}
`;
