import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FlagCan from 'components/pageinsights/_assets/flag-canada.png';
import FlagUs from 'components/pageinsights/_assets/flag-us.png';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageinsights/_styles/RegionalEscalationLandscape.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineData {
  pastLabel: string;
  torontoTheme1: string;
  torontoTheme1Stat: string;
  torontoTheme1StatTrend: number;
  torontoTheme2: string;
  torontoTheme2Stat: string;
  torontoTheme2StatTrend: number;
  torontoTheme3: string;
  torontoTheme3Stat: string;
  torontoTheme3StatTrend: number;

  pittsburghTheme1: string;
  pittsburghTheme1Stat: string;
  pittsburghTheme1StatTrend: number;
  pittsburghTheme2: string;
  pittsburghTheme2Stat: string;
  pittsburghTheme2StatTrend: number;
  pittsburghTheme3: string;
  pittsburghTheme3Stat: string;
  pittsburghTheme3StatTrend: number;

  montrealTheme1: string;
  montrealTheme1Stat: string;
  montrealTheme1StatTrend: number;
  montrealTheme2: string;
  montrealTheme2Stat: string;
  montrealTheme2StatTrend: number;
  montrealTheme3: string;
  montrealTheme3Stat: string;
  montrealTheme3StatTrend: number;
}

interface TimelineDataRecord {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function RegionalEscalationLandscape(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataRecord = {
    oneMonth: {
      pastLabel: t('pastLabel.past month'),
      torontoTheme1: t('themes.Employee relations'),
      torontoTheme1Stat: '30%',
      torontoTheme1StatTrend: 30,
      torontoTheme2: t('themes.Recruitment'),
      torontoTheme2Stat: '20%',
      torontoTheme2StatTrend: -20,
      torontoTheme3: t('themes.Team dynamics'),
      torontoTheme3Stat: '10%',
      torontoTheme3StatTrend: 10,

      pittsburghTheme1: t('themes.Employee relations'),
      pittsburghTheme1Stat: '30%',
      pittsburghTheme1StatTrend: 30,
      pittsburghTheme2: t('themes.Time management'),
      pittsburghTheme2Stat: '20%',
      pittsburghTheme2StatTrend: 20,
      pittsburghTheme3: t('themes.Team dynamics'),
      pittsburghTheme3Stat: '10%',
      pittsburghTheme3StatTrend: 10,

      montrealTheme1: t('themes.Performance'),
      montrealTheme1Stat: '30%',
      montrealTheme1StatTrend: -30,
      montrealTheme2: t('themes.Health and safety'),
      montrealTheme2Stat: '20%',
      montrealTheme2StatTrend: 20,
      montrealTheme3: t('themes.Team dynamics'),
      montrealTheme3Stat: '10%',
      montrealTheme3StatTrend: -10,
    },
    threeMonth: {
      pastLabel: t('pastLabel.past 3 months'),
      torontoTheme1: t('themes.Performance'),
      torontoTheme1Stat: '31%',
      torontoTheme1StatTrend: 31,
      torontoTheme2: t('themes.Recruitment'),
      torontoTheme2Stat: '21%',
      torontoTheme2StatTrend: -21,
      torontoTheme3: t('themes.Employee relations'),
      torontoTheme3Stat: '11%',
      torontoTheme3StatTrend: 11,

      pittsburghTheme1: t('themes.Customer service'),
      pittsburghTheme1Stat: '31%',
      pittsburghTheme1StatTrend: -31,
      pittsburghTheme2: t('themes.Recruitment'),
      pittsburghTheme2Stat: '21%',
      pittsburghTheme2StatTrend: 21,
      pittsburghTheme3: t('themes.Employee relations'),
      pittsburghTheme3Stat: '11%',
      pittsburghTheme3StatTrend: 11,

      montrealTheme1: t('themes.Performance'),
      montrealTheme1Stat: '31%',
      montrealTheme1StatTrend: -31,
      montrealTheme2: t('themes.Recruitment'),
      montrealTheme2Stat: '21%',
      montrealTheme2StatTrend: -21,
      montrealTheme3: t('themes.Loss prevention'),
      montrealTheme3Stat: '11%',
      montrealTheme3StatTrend: -11,
    },
    sixMonth: {
      pastLabel: t('pastLabel.past six months'),
      torontoTheme1: t('themes.Recruitment'),
      torontoTheme1Stat: '32%',
      torontoTheme1StatTrend: 32,
      torontoTheme2: t('themes.Health and safety'),
      torontoTheme2Stat: '22%',
      torontoTheme2StatTrend: 22,
      torontoTheme3: t('themes.Time management'),
      torontoTheme3Stat: '12%',
      torontoTheme3StatTrend: 12,

      pittsburghTheme1: t('themes.Recruitment'),
      pittsburghTheme1Stat: '32%',
      pittsburghTheme1StatTrend: -32,
      pittsburghTheme2: t('themes.Health and safety'),
      pittsburghTheme2Stat: '22%',
      pittsburghTheme2StatTrend: 22,
      pittsburghTheme3: t('themes.Time management'),
      pittsburghTheme3Stat: '12%',
      pittsburghTheme3StatTrend: -12,

      montrealTheme1: t('themes.Recruitment'),
      montrealTheme1Stat: '32%',
      montrealTheme1StatTrend: 32,
      montrealTheme2: t('themes.Health and safety'),
      montrealTheme2Stat: '22%',
      montrealTheme2StatTrend: 22,
      montrealTheme3: t('themes.Time management'),
      montrealTheme3Stat: '12%',
      montrealTheme3StatTrend: -12,
    },
    oneYear: {
      pastLabel: t('pastLabel.past year'),
      torontoTheme1: t('themes.Loss prevention'),
      torontoTheme1Stat: '33%',
      torontoTheme1StatTrend: 33,
      torontoTheme2: t('themes.Political acumen'),
      torontoTheme2Stat: '23%',
      torontoTheme2StatTrend: -23,
      torontoTheme3: t('themes.Customer service'),
      torontoTheme3Stat: '13%',
      torontoTheme3StatTrend: 13,

      pittsburghTheme1: t('themes.Sales'),
      pittsburghTheme1Stat: '33%',
      pittsburghTheme1StatTrend: 33,
      pittsburghTheme2: t('themes.Political acumen'),
      pittsburghTheme2Stat: '23%',
      pittsburghTheme2StatTrend: 23,
      pittsburghTheme3: t('themes.Customer service'),
      pittsburghTheme3Stat: '13%',
      pittsburghTheme3StatTrend: -13,

      montrealTheme1: t('themes.Loss prevention'),
      montrealTheme1Stat: '33%',
      montrealTheme1StatTrend: 33,
      montrealTheme2: t('themes.Political acumen'),
      montrealTheme2Stat: '23%',
      montrealTheme2StatTrend: 23,
      montrealTheme3: t('themes.Customer service'),
      montrealTheme3Stat: '13%',
      montrealTheme3StatTrend: -13,
    },
    all: {
      pastLabel: t('pastLabel.all time'),
      torontoTheme1: t('themes.Customer service'),
      torontoTheme1Stat: '34%',
      torontoTheme1StatTrend: -34,
      torontoTheme2: t('themes.Performance'),
      torontoTheme2Stat: '24%',
      torontoTheme2StatTrend: -24,
      torontoTheme3: t('themes.Time management'),
      torontoTheme3Stat: '14%',
      torontoTheme3StatTrend: -14,

      pittsburghTheme1: t('themes.Sales'),
      pittsburghTheme1Stat: '34%',
      pittsburghTheme1StatTrend: 34,
      pittsburghTheme2: t('themes.Performance'),
      pittsburghTheme2Stat: '24%',
      pittsburghTheme2StatTrend: -24,
      pittsburghTheme3: t('themes.Time management'),
      pittsburghTheme3Stat: '14%',
      pittsburghTheme3StatTrend: -14,

      montrealTheme1: t('themes.Sales'),
      montrealTheme1Stat: '34%',
      montrealTheme1StatTrend: 34,
      montrealTheme2: t('themes.Performance'),
      montrealTheme2Stat: '24%',
      montrealTheme2StatTrend: -24,
      montrealTheme3: t('themes.Time management'),
      montrealTheme3Stat: '14%',
      montrealTheme3StatTrend: 14,
    },
  };

  const getContent = (region: string): React.ReactElement => (
    <Styled.SectionHighlighted>
      <Styled.SectionRow>
        <Styled.SectionLabel>
          <Styled.LightText>#1</Styled.LightText>
          <Styled.LightText>
            {data[timeline][`${region}Theme1`]}
          </Styled.LightText>
        </Styled.SectionLabel>
        <Styled.Stats>
          <Styled.Stat>{data[timeline][`${region}Theme1Stat`]}</Styled.Stat>
          <Styled.StatPainted
            number={data[timeline][`${region}Theme1StatTrend`] as number}
          >
            {data[timeline][`${region}Theme1StatTrend`]}%
          </Styled.StatPainted>
        </Styled.Stats>
      </Styled.SectionRow>

      <Styled.SectionRow>
        <Styled.SectionLabel>
          <Styled.LightText>#2</Styled.LightText>
          <Styled.LightText>
            {data[timeline][`${region}Theme2`]}
          </Styled.LightText>
        </Styled.SectionLabel>
        <Styled.Stats>
          <Styled.Stat>{data[timeline][`${region}Theme2Stat`]}</Styled.Stat>
          <Styled.StatPainted
            number={data[timeline][`${region}Theme2StatTrend`] as number}
          >
            {data[timeline][`${region}Theme2StatTrend`]}%
          </Styled.StatPainted>
        </Styled.Stats>
      </Styled.SectionRow>

      <Styled.SectionRow>
        <Styled.SectionLabel>
          <Styled.LightText>#3</Styled.LightText>
          <Styled.LightText>
            {data[timeline][`${region}Theme3`]}
          </Styled.LightText>
        </Styled.SectionLabel>
        <Styled.Stats>
          <Styled.Stat>{data[timeline][`${region}Theme3Stat`]}</Styled.Stat>
          <Styled.StatPainted
            number={data[timeline][`${region}Theme3StatTrend`] as number}
          >
            {data[timeline][`${region}Theme3StatTrend`]}%
          </Styled.StatPainted>
        </Styled.Stats>
      </Styled.SectionRow>

      <Buttons.Secondary centerText>
        {t('regionalEscalationLandscape.Push coaching')}
      </Buttons.Secondary>
    </Styled.SectionHighlighted>
  );

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('regionalEscalationLandscape.Top risks by region')}
      </Text.P2Semibold>
      <Styled.Accordions>
        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagCan} />
              <Text.P2Semibold>Toronto, Canada</Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('toronto')}</AccordionDetails>
        </Styled.AccordionSection>
        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagUs} />
              <Text.P2Semibold>Pittsburgh, Pennsylvania, USA</Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('pittsburgh')}</AccordionDetails>
        </Styled.AccordionSection>
        <Styled.AccordionSection>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Styled.AccordionLabel>
              <Styled.Flag src={FlagCan} />
              <Text.P2Semibold>Montréal, Canada</Text.P2Semibold>
            </Styled.AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>{getContent('montreal')}</AccordionDetails>
        </Styled.AccordionSection>
      </Styled.Accordions>

      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
