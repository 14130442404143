import { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';

// The actual API response format
interface IndustriesResponse {
  [key: string]: string;
}

// Simplified industry type
export interface Industry {
  id: string;
  name: string;
}

const useIndustries = (currentLanguage: string): Industry[] => {
  const { data, callApi } = useApi<IndustriesResponse>();
  const [industries, setIndustries] = useState<Industry[]>([]);

  useEffect(() => {
    callApi('/demo/industries', {
      method: 'get',
      headers: {
        'Accept-Language': currentLanguage,
      },
    });
  }, [callApi, currentLanguage]);

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      // Transform the API response to a simple array of industries
      const formattedIndustries = Object.entries(data.response).map(
        ([id, name]) => ({
          id,
          name,
        })
      );
      setIndustries(formattedIndustries);
    }
  }, [data]);

  return industries;
};

export default useIndustries;
