import { useEffect, useState } from 'react';
import {
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
  isIE,
} from 'react-device-detect';

type Browser = 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'unknown';

const useBrowserDetection = (): Browser => {
  const [browser, setBrowser] = useState<Browser>('unknown');

  useEffect(() => {
    if (isChrome) setBrowser('chrome');
    else if (isFirefox) setBrowser('firefox');
    else if (isSafari) setBrowser('safari');
    else if (isEdge) setBrowser('edge');
    else if (isIE) setBrowser('ie');
    // Add more browser checks as needed
  }, []);

  return browser;
};

export default useBrowserDetection;
