import React from 'react';

// components
import ConversationItem from 'components/pageECoach/convoPrep/ConversationItem';
import ClickableListCard from 'components/pageECoach/ClickableListCard';
import ConversationItemDetails from 'components/pageECoach/convoPrep/ConversationItemDetails';
// import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/loaders/Loader';

// hooks
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';

interface StepOutput {
  output_type: number;
  suggestion_items?: any[];
  [key: string]: any;
}

interface Session {
  id: string;
  [key: string]: any;
}

// Create a wrapper component that adapts ConversationItem to the expected interface
const ConversationItemWrapper = ({ item }: { item: any }): JSX.Element => {
  // Ensure the item has the required properties for ConversationItem
  const conversationStep = {
    ...item,
    short_description: item.short_description || '',
  };

  return <ConversationItem item={conversationStep} />;
};

interface ConversationItemsCardProps {
  activeSession: Session;
  stepOutput: StepOutput;
}

export default function ConversationItemsCard({
  activeSession,
  stepOutput,
}: ConversationItemsCardProps): JSX.Element {
  const { items, selectedItem, selectItem } = useEcoachItemsConsumer(
    activeSession.id,
    stepOutput
  );

  return (
    <>
      <ECoachResponse status={items.status} loader={<Loader />}>
        <ClickableListCard
          isNumbered
          ItemDetailsComponent={ConversationItemDetails}
          items={items.data}
          ListItemComponent={ConversationItemWrapper}
          selectedItem={selectedItem}
          selectItem={selectItem}
        />
        {/* //FIXME */}
        {/* {stepOutput.suggestion_items.documents.length > 0 && (
          <DocumentList
            documentList={stepOutput.suggestion_items.documents}
            session={activeSession.id}
          />
        )} */}
      </ECoachResponse>
    </>
  );
}
