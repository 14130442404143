import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routesConfig from 'constants/routesConfig.json';

// assets & styles
import { Arrow } from 'untitledui-js';
import * as Header from 'components/pageECoach/_styles/Toolbar.style';

interface ReturnToSessionButtonProps {
  session?: string;
}

export default function ReturnToSessionButton({
  session,
}: ReturnToSessionButtonProps) {
  const { t } = useTranslation('pageEcoach');
  const history = useHistory();

  const redirectToSession = useCallback(() => {
    if (session) {
      history.push(`${routesConfig.CONVO_PAGE}/${session}`);
    }
  }, [history, session]);

  return (
    <Header.ToolbarButton
      onClick={redirectToSession}
      style={{ paddingRight: '15px' }}
    >
      <Arrow.ChevronLeft size={'15'} />
      {t('suggestions.ReturnToSessionButton.Return to conversation')}
    </Header.ToolbarButton>
  );
}
