import React from 'react';

// assets
import { Security } from 'untitledui-js';

// styles
import * as Styled from 'components/sidePanel/_styles/PanelNav.style';
import * as Text from 'components/_styles/Text.style';

interface PanelNavProps {
  action?: () => void;
  demoTour?: string;
  icon: () => JSX.Element;
  isActive?: boolean;
  inactive?: boolean;
  label: string;
  notification?: number;
}

export default function PanelNav({
  action,
  demoTour,
  icon,
  inactive,
  isActive,
  label,
  notification,
}: PanelNavProps): JSX.Element {
  if (inactive)
    return (
      <Styled.InactiveContainer className={demoTour}>
        <Styled.Label>
          <Security.Lock01 size={'15'} />
          <Text.P3Semibold>{label}</Text.P3Semibold>
        </Styled.Label>
      </Styled.InactiveContainer>
    );

  return (
    <Styled.Container className={demoTour} isActive={isActive} onClick={action}>
      <Styled.Label>
        {icon()}
        <Text.P3Semibold>{label}</Text.P3Semibold>
      </Styled.Label>
      {notification > 0 && (
        <Styled.Notification>
          <Text.P1Semibold>{notification}</Text.P1Semibold>
        </Styled.Notification>
      )}
    </Styled.Container>
  );
}
