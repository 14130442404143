import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Buttons from 'components/_styles/Buttons.style';

export const Dropdown = styled('div')`
  background-color: ${colours.warning[500]};
  border-radius: 20px;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const DropdownButton = styled(Buttons.Secondary)`
  background-color: ${colours.warning[500]};
  border-radius: 20px;
  color: ${colours.neutralWarm[900]};
  justify-content: space-between;

  svg path {
    stroke: ${colours.neutralWarm[900]};
  }

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-height: unset;
    max-width: 100%;
    width: 100%;
  }

  &:hover {
    background-color: ${colours.warning[400]};
  }

  &:focus {
    background-color: ${colours.warning[400]};
  }

  &:active {
    background-color: ${colours.warning[400]};
  }
`;

export const StatusDropdown = styled('div')`
  background-color: ${colours.neutralWarm[100]};
  border: 0.5px solid ${colours.neutralWarm[300]};
  border-radius: 20px;
  box-shadow: ${shadows.XL};
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 20px 20px 20px;
  position: absolute;
  right: 0;
  top: 50px;
  width: fit-content;
  z-index: 50;
`;

export const DropdownCategory = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const DropdownSelection = styled('div')`
  align-items: center;
  background-color: ${colours.shade[0]};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  white-space: nowrap;
  width: fit-content;

  @media (max-width: ${device.tablet}) {
    padding: 15px 25px;
  }
`;

export const SelectionNeutral = styled(DropdownSelection)`
  background-color: ${colours.neutralWarm[400]};

  p {
    color: ${colours.neutralWarm[900]};
  }

  svg path {
    stroke: ${colours.neutralWarm[900]};
  }

  &:hover {
    background-color: ${colours.neutralWarm[800]};

    p {
      color: ${colours.shade[0]};
    }

    svg path {
      stroke: ${colours.shade[0]};
    }
  }
`;

export const SelectionYellow = styled(DropdownSelection)`
  background-color: ${colours.warning[500]};

  p {
    color: ${colours.warning[900]};
  }

  svg path {
    stroke: ${colours.warning[900]};
  }

  &:hover {
    background-color: ${colours.warning[800]};

    p {
      color: ${colours.shade[0]};
    }

    svg path {
      stroke: ${colours.shade[0]};
    }
  }
`;

export const SelectionGreen = styled(DropdownSelection)`
  background-color: ${colours.success[500]};

  p {
    color: ${colours.success[900]};
  }

  svg path {
    stroke: ${colours.success[900]};
  }

  &:hover {
    background-color: ${colours.success[700]};

    p {
      color: ${colours.shade[0]};
    }

    svg path {
      stroke: ${colours.shade[0]};
    }
  }
`;

export const StatusDecoration = styled('div')`
  border-radius: 30px;
  height: 10px;
  width: 10px;
`;

export const NeutralDecoration = styled(StatusDecoration)`
  background-color: ${colours.neutralWarm[200]};
`;

export const YellowDecoration = styled(StatusDecoration)`
  background-color: ${colours.warning[200]};
`;

export const GreenDecoration = styled(StatusDecoration)`
  background-color: ${colours.success[200]};
`;
