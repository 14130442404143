import { useEffect, useState } from 'react';
import routesConfig from 'constants/routesConfig.json';
import useWebsocketSetup from './useWebsocketSetup';

// Use a type for TypeScript type checking
export type FeedbackLoadingStatus = 'loading' | 'loaded' | 'error' | 'idle';

// Export constants that can be used as values
export const FeedbackLoadingStatus = {
  LOADING: 'loading' as FeedbackLoadingStatus,
  LOADED: 'loaded' as FeedbackLoadingStatus,
  ERROR: 'error' as FeedbackLoadingStatus,
  IDLE: 'idle' as FeedbackLoadingStatus,
};

export interface FeedbackStatusType {
  loadingStatus: FeedbackLoadingStatus;
  data: {
    feedback_needed: boolean;
  } | null;
}

interface WebSocketMessage {
  action: string;
  data?: any;
}

// eslint-disable-next-line import/prefer-default-export
export const useFeedbackConsumer = () => {
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatusType>({
    loadingStatus: FeedbackLoadingStatus.IDLE,
    data: null,
  });

  const { sendJsonMessage, lastJsonMessage } = useWebsocketSetup(
    routesConfig.ECOACH.FEEDBACK_WS
  );

  useEffect(() => {
    if (lastJsonMessage === null) return;

    const { action, data } = lastJsonMessage as WebSocketMessage;

    if (action === 'feedback_status') {
      setFeedbackStatus({
        loadingStatus: FeedbackLoadingStatus.LOADED,
        data,
      });
    }
  }, [lastJsonMessage]);

  const fetchFeedbackStatus = (sessionId: string): void => {
    sendJsonMessage({
      action: 'feedback_status',
      request_id: Date.now(),
      session_pk: sessionId,
    });
  };

  const tryCreate = (sessionId: string, feedbackData: any): void => {
    sendJsonMessage({
      action: 'try_create',
      request_id: Date.now(),
      session_pk: sessionId,
      data: feedbackData,
    });
  };

  return { feedbackStatus, fetchFeedbackStatus, tryCreate };
};
