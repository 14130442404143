import styled, { css } from 'styled-components';
import colours from 'constants/colours';

interface ToggleButtonProps {
  selected: boolean;
}

export const ToggleButtonGroup = styled('div')`
  background-color: ${colours.neutralWarm[100]};
  border-radius: 30px;
  display: flex;
  flex-grow: 1;
  gap: 5px;
  max-height: 30px;
  min-height: 30px;
  padding: 5px;
  width: 100%;
`;

export const ToggleButton = styled('div')<ToggleButtonProps>`
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;

  ${(props) =>
    props.selected.toString() === 'true' &&
    css`
      background-color: ${colours.shade[0]};
    `}

  p {
    color: ${(props) =>
      props.selected.toString() === 'true'
        ? `${colours.neutralWarm[900]}`
        : `${colours.neutralWarm[700]}`};
  }
`;

export default ToggleButtonGroup;
