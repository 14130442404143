import styled from 'styled-components';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;
  padding: 0 20px;
  width: 100%;
`;

export const OnboardingLogo = styled.div`
  align-items: left;
  box-sizing: border-box;
  display: flex;
  padding: 0 40px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 15px;
  }
`;

export const LogoText = styled.img`
  display: block;
  height: 25px;
  margin-right: 12px;
  width: auto;
`;

export const LogoIcon = styled.img`
  display: block;
  height: 40px;
  max-width: 40px;
  width: auto;
`;

export const OnboardingP = styled(Text.P3)`
  color: black;
`;

export default Page;
