import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

interface SectionProps {
  eCoach?: boolean;
}

export const Section = styled('div')<SectionProps>`
  align-items: ${(props) => (props.eCoach ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }
`;

export const Card = styled(Cards.Card)`
  background: linear-gradient(279deg, #36475a 1.35%, #1a2634 102.58%);
  background-position: center;
  background-size: cover;
  gap: 10px;
  max-width: 530px;
  padding: 20px;
`;

export const Label = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[50]};
`;

export const Icon = styled('div')`
  align-items: center;
  border-radius: 50px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 5px;
  width: 30px;

  svg {
    height: 35px;
    width: 35px;
  }
`;

export const TextSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Description = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[900]};
`;
