// React essentials
import React from 'react';
import { Formik, FormikHelpers } from 'formik';

// Localization
import { useTranslation } from 'react-i18next';

// Form validation and initial values
import { getInitialValues } from 'components/pageDemoRegistration/initialValues';
import getValidationSchema from 'components/pageDemoRegistration/validationSchema';

// Custom hooks
import useRegister from 'hooks/demo/useRegister';

// Handlers
import {
  onRegistrationError,
  onRegistrationSuccess,
} from 'components/pageDemoRegistration/registrationHandlers';

// Components and styles
import { Card } from 'components/pageDemoRegistration/_styles/RegistrationCard.style';
import IntroCard from 'components/pageDemoRegistration/introCard/IntroCard';
import RegistrationForm from 'components/pageDemoRegistration/RegistrationForm';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  industry: string;
  additional_infos: {
    user_specific_industry: string;
    job_title: string;
    experience_in_years: string;
    company_size: string;
    team_size: string;
    issues: string;
  };
}

const DemoRegistration: React.FC = () => {
  const { t } = useTranslation('pageDemoRegistration');
  const { register } = useRegister(onRegistrationSuccess, onRegistrationError);

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    register(values, helpers);
  };

  return (
    <>
      <IntroCard t={t} />
      <Card id="registration">
        <Formik
          initialValues={getInitialValues()}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleSubmit}
        >
          {(formikProps) => <RegistrationForm {...formikProps} />}
        </Formik>
      </Card>
    </>
  );
};

export default DemoRegistration;
