interface FormikHelpers {
  setSubmitting: (isSubmitting: boolean) => void;
  setStatus: (status: any) => void;
  setFieldError?: (field: string, message: string) => void;
}

interface ApiResponse extends Response {
  clone: () => ApiResponse;
}

export const onRegistrationSuccess = (
  response: ApiResponse,
  { setSubmitting, setStatus }: FormikHelpers
): void => {
  setSubmitting(false);
  setStatus({
    success: true,
  });
};

export const onRegistrationError = (
  response: ApiResponse,
  { setSubmitting, setStatus, setFieldError }: FormikHelpers
): void => {
  const { status } = response;

  if (status === 400) {
    const clonedResponse = response.clone();

    clonedResponse
      .json()
      .then((data: Record<string, any>) => {
        if (data.additional_infos) {
          Object.keys(data.additional_infos).forEach((key) => {
            setFieldError?.(
              `additional_infos.${key}`,
              data.additional_infos[key]
            );
          });

          // eslint-disable-next-line no-param-reassign
          delete data.additional_infos;
        }

        Object.keys(data).forEach((key) => {
          setFieldError?.(key, data[key]);
        });
      })
      .catch((error) => console.error(`Error parsing JSON: `, error));
  } else {
    setStatus({ apiError: `An error occured` });
  }

  setSubmitting(false);
};
