import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';
import Accordion from '@mui/material/Accordion';

interface StatPaintedProps {
  number: number;
}

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  min-width: 450px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    flex: 1;
    min-width: unset;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Accordions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const AccordionSection = styled(Accordion)({
  boxShadow: 'none',

  '&.MuiAccordionSummary-root': {
    padding: '0 0',
  },

  '&.MuiAccordionDetails-root': {
    padding: '8px 0',
  },

  '&::before': {
    display: 'none',
  },

  '&.Mui-expanded': {
    backgroundColor: `${colours.neutralWarm[100]}`,
    borderRadius: '10px',
  },
});

export const AccordionLabel = styled('div')`
  display: flex;
  gap: 10px;
`;

export const Flag = styled('img')`
  border-radius: 3px;
  height: 18px;
  width: 30px;
`;

export const SectionHighlighted = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.mint[900]};
`;

export const TopStatDetail = styled(Text.P1Semibold)`
  color: ${colours.mint[900]};
`;

export const TopStatDetailWhite = styled(Text.P1Semibold)`
  color: ${colours.shade[0]};
`;

export const SectionHeader = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
  font-weight: 900;
`;

export const SectionRow = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const SectionLabel = styled('div')`
  display: flex;
  gap: 10px;
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const DarkText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
`;

export const Link = styled('span')`
  color: ${colours.blue[500]};
  cursor: pointer;
  text-decoration: underline;
`;

export const Stats = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const Stat = styled(Text.P2Semibold)`
  align-items: center;
  display: flex;
  font-weight: 900;
`;

export const StatPainted = styled(Text.P2)<StatPaintedProps>`
  align-items: center;
  color: ${(props) =>
    props.number > 0 ? colours.mint[600] : colours.primary[500]};
  display: flex;
  font-size: 12px;
`;
