import styled from 'styled-components';
import Buttons from '@mui/material/Button';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Button = styled(Buttons)`
  border-radius: 20px;
  box-shadow: none;
  color: ${colours.shade[0]};
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  justify-content: ${(props) => (props.centerText ? 'center' : 'start')};
  gap: 10px;
  line-height: 20px;
  padding: 15px;
  text-transform: unset;
  white-space: nowrap;
  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};

  // inactive
  cursor: ${(props) => props.inactive && 'not-allowed'};
  opacity: ${(props) => props.inactive && '0.5'};
  pointer-events: ${(props) => props.inactive && 'none'};

  svg path {
    stroke: ${colours.shade[0]};
  }

  @media (max-width: ${device.tablet}) {
    border-radius: 15px;
    flex-grow: 1;
    gap: 10px;
    width: 100%;
  }
`;

export const Primary = styled(Button)`
  background-color: ${colours.primary[600]};
  max-height: 60px;
  padding: 18px 25px;

  svg {
    height: 22px;
    width: 22px;
    stroke-width: 2;
  }

  &:hover,
  &:focus {
    background-color: ${colours.primary[700]};
  }

  &:active {
    background-color: ${colours.primary[800]};
  }

  @media (max-width: ${device.tablet}) {
    border-radius: 20px;
    max-height: 74px;
    padding: 25px;
  }
`;

export const PrimaryIcon = styled(Primary)`
  flex-grow: 0;
  width: fit-content;
`;

export const Secondary = styled(Button)`
  background-color: ${colours.neutralWarm[700]};
  border-radius: 30px;
  max-height: 50px;
  padding: 13px 25px;

  svg {
    height: 20px;
    width: 20px;
    stroke-width: 2;
  }

  &:hover,
  &:focus {
    background-color: ${colours.neutralWarm[800]};
  }

  &:active {
    background-color: ${colours.neutralWarm[900]};
  }
`;

export const SecondaryIcon = styled(Secondary)`
  flex-grow: 0;
  width: fit-content;
`;

export const Tertiary = styled(Button)`
  background-color: transparent;
  border: 1px solid ${colours.neutralWarm[800]};
  border-radius: 30px;
  color: ${colours.neutralWarm[800]};
  max-height: 50px;
  padding: 13px 25px;

  svg {
    height: 20px;
    width: 20px;
    stroke-width: 2;
  }

  svg path {
    stroke: ${colours.neutralWarm[800]};
  }

  &:hover,
  &:focus {
    background-color: ${colours.neutralWarm[100]};
    border: 1px solid ${colours.neutralWarm[900]};
    color: ${colours.neutralWarm[900]};

    svg path {
      stroke: ${colours.neutralWarm[900]};
    }
  }

  &:active {
    background-color: ${colours.neutralWarm[200]};
    border: 1px solid ${colours.shade[100]};
    color: ${colours.shade[100]};

    svg path {
      stroke: ${colours.shade[100]};
    }
  }
`;

export const TertiaryIcon = styled(Tertiary)`
  flex-grow: 0;
  width: fit-content;
`;
