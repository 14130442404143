import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const D1 = styled('h1')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 96px;
  line-height: 96px;
  margin: 0;
`;

export const D1Semibold = styled(D1)`
  font-weight: 500;
`;

export const D1Bold = styled(D1)`
  font-weight: 700;
`;

export const D1Extrabold = styled(D1)`
  font-weight: 900;
`;

export const D2 = styled('h1')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  line-height: 60px;
  margin: 0;
`;

export const D2Semibold = styled(D2)`
  font-weight: 500;
`;

export const D2Bold = styled(D2)`
  font-weight: 700;
`;

export const D2Extrabold = styled(D2)`
  font-weight: 900;
`;

export const H1 = styled('h1')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  line-height: 56px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const H1Semibold = styled(H1)`
  font-weight: 500;
`;

export const H1Bold = styled(H1)`
  font-weight: 700;
`;

export const H1Extrabold = styled(H1)`
  font-weight: 900;
`;

export const H2 = styled('h2')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  line-height: 44px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 33px;
    line-height: 40px;
  }
`;

export const H2Semibold = styled(H2)`
  font-weight: 500;
`;

export const H2Bold = styled(H2)`
  font-weight: 700;
`;

export const H2Extrabold = styled(H2)`
  font-weight: 900;
`;

export const H3 = styled('h3')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 33px;
  line-height: 40px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const H3Semibold = styled(H3)`
  font-weight: 500;
`;

export const H3Bold = styled(H3)`
  font-weight: 700;
`;

export const H3Extrabold = styled(H3)`
  font-weight: 900;
`;

export const H4 = styled('h4')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  line-height: 34px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 23px;
    line-height: 28px;
  }
`;

export const H4Semibold = styled(H4)`
  font-weight: 500;
`;

export const H4Bold = styled(H4)`
  font-weight: 700;
`;

export const H4Extrabold = styled(H4)`
  font-weight: 900;
`;

export const H5 = styled('h5')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  line-height: 28px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 19px;
    line-height: 23px;
  }
`;

export const H5Semibold = styled(H5)`
  font-weight: 500;
`;

export const H5Bold = styled(H5)`
  font-weight: 700;
`;

export const H5Extrabold = styled(H5)`
  font-weight: 900;
`;

export const H6 = styled('h6')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  line-height: 23px;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const H6Semibold = styled(H6)`
  font-weight: 500;
`;

export const H6Bold = styled(H6)`
  font-weight: 700;
`;

export const H6Extrabold = styled(H6)`
  font-weight: 900;
`;

export const Subheading = styled('p')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const SubheadingSemibold = styled(Subheading)`
  font-weight: 500;
`;

export const SubheadingUnderline = styled(Subheading)`
  text-decoration: underline;
`;

export const P1 = styled('p')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
`;

export const P1Underline = styled(P1)`
  text-decoration: underline;
`;

export const P1Semibold = styled(P1)`
  font-weight: 500;
`;

export const P2 = styled('p')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const P2Semibold = styled(P2)`
  font-weight: 500;
`;

export const P2Underline = styled(P2)`
  text-decoration: underline;
`;

export const P3 = styled('p')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

export const P3Semibold = styled(P3)`
  font-weight: 500;
`;

export const P3Underline = styled(P3)`
  text-decoration: underline;
`;

export const Caption = styled('p')`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
`;

export const CaptionSemibold = styled(Caption)`
  font-weight: 500;
`;

export const CaptionBold = styled(Caption)`
  font-weight: 700;
`;

export const CaptionSemiboldCap = styled(Caption)`
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const CaptionCap = styled(Caption)`
  text-transform: uppercase;
  letter-spacing: 1px;
`;
