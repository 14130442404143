import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

// hooks
import useApi from 'hooks/useApi';

// style
import * as Styled from 'components/sidePanel/_styles/SidePanel.style';

export default function Logout(): JSX.Element {
  const { data: logoutCall, callApi } = useApi();
  const posthog = usePostHog();
  const { t } = useTranslation('sidePanel');

  const handleLogout = () => {
    callApi('/logout/', {
      method: 'post',
      data: {},
    });
    localStorage.removeItem('authorization');
    posthog?.capture('user_clicks_logout');
    posthog?.reset();
  };

  useEffect(() => {
    if (logoutCall.status === 'success' || logoutCall.status === 'error')
      window.location.reload();
  }, [logoutCall]);

  return (
    <div onClick={handleLogout}>
      <Styled.BottomText>{t('logout')}</Styled.BottomText>
    </div>
  );
}
