import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';

// data
import helperPrompts, {
  PromptOption,
} from 'components/pageECoach/promptHelper/prompts';

// styles
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';

interface QuestionGoalProps {
  handleSelectedGoal: (option: PromptOption | string) => void;
  selectedGoal: PromptOption | string | null;
  selectedTheme: string;
}

export default function QuestionGoal({
  handleSelectedGoal,
  selectedGoal,
  selectedTheme,
}: QuestionGoalProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  const getActiveConvos = () =>
    helperPrompts[selectedTheme].map((option) => (
      <ClickableInput
        inputAction={() => handleSelectedGoal(option)}
        inputText={t(`promptHelper.${option.label}`)}
        inputType="light"
        key={option.label}
      />
    ));

  const eCoachMessage = () => (
    <ChatBubble
      simpleString={t("promptHelper.Understood. What's your goal?")}
    />
  );

  if (selectedGoal)
    return (
      <>
        {eCoachMessage()}
        <ChatBubble
          simpleString={t(
            `promptHelper.${
              typeof selectedGoal === 'object'
                ? selectedGoal.label
                : 'Something else'
            }`
          )}
          sentByUser
        />
      </>
    );

  return (
    <>
      {eCoachMessage()}
      <ChatBubble sentByUser>
        <StyledPromptControls.ButtonsColumn>
          {getActiveConvos()}
          <ClickableInput
            inputAction={() => handleSelectedGoal('Something else')}
            inputText={t('promptHelper.Something else')}
            inputType="light"
            key="other"
          />
        </StyledPromptControls.ButtonsColumn>
      </ChatBubble>
    </>
  );
}
