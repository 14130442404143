import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Dialog from '@mui/material/Dialog';
import { PaperProps } from '@mui/material/Paper';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

interface DemoDialogProps {
  closeModal: () => void;
  isOpenConfirmDialog: boolean;
}

// Create a wrapper component that accepts PaperProps
const StyledPaper = (props: PaperProps) => <Cards.BaseCard {...props} />;

const DemoDialog: React.FC<DemoDialogProps> = ({
  closeModal,
  isOpenConfirmDialog,
}) => {
  const { t } = useTranslation('pageEcoach');

  return (
    <Dialog
      onClose={closeModal}
      open={isOpenConfirmDialog}
      PaperComponent={StyledPaper}
    >
      <Text.P2>{t('allChats.DemoDialog.message')}</Text.P2>
      <Buttons.Primary
        href="https://meetings.hubspot.com/justin-lessard-wajcer"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('allChats.DemoDialog.Book a call')}
      </Buttons.Primary>
    </Dialog>
  );
};

export default DemoDialog;
