import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Arrow } from 'untitledui-js';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Styled from 'components/pageinsights/_styles/ReducedEscalations.style';
import * as Text from 'components/_styles/Text.style';

interface TimelineData {
  pastLabel: string;
  topStat: string;
  pastMonth: string;
  escalationsPrevented: string;
  escalationsRaised: string;
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function ReducedEscalations(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      pastLabel: t('pastLabel.past month'),
      topStat: '23',
      pastMonth: '23',
      escalationsPrevented: '23',
      escalationsRaised: '5',
    },
    threeMonth: {
      pastLabel: t('pastLabel.past 3 months'),
      topStat: '65',
      pastMonth: '31',
      escalationsPrevented: '65',
      escalationsRaised: '13',
    },
    sixMonth: {
      pastLabel: t('pastLabel.past six months'),
      topStat: '134',
      pastMonth: '2',
      escalationsPrevented: '134',
      escalationsRaised: '25',
    },
    oneYear: {
      pastLabel: t('pastLabel.past year'),
      topStat: '335',
      pastMonth: '3',
      escalationsPrevented: '335',
      escalationsRaised: '36',
    },
    all: {
      pastLabel: t('pastLabel.all time'),
      topStat: '462',
      pastMonth: '4',
      escalationsPrevented: '462',
      escalationsRaised: '43',
    },
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('preventedEscalations.Prevented escalations')}
      </Text.P2Semibold>
      <Styled.Section>
        <Styled.TopStat>{data[timeline].topStat}</Styled.TopStat>
        <Styled.TopStatDetail>
          <Arrow.ArrowUp />
          <Styled.TopStatDetailNumber>
            {data[timeline].pastMonth}
          </Styled.TopStatDetailNumber>
          <Styled.LightText>{data[timeline].pastLabel}</Styled.LightText>
        </Styled.TopStatDetail>
      </Styled.Section>

      <Styled.Section>
        <Styled.Visual>
          <Styled.EscalationsPrevented
            quantity={Number(data[timeline].escalationsPrevented)}
            total={
              Number(data[timeline].escalationsPrevented) +
              Number(data[timeline].escalationsRaised)
            }
          />
          <Styled.EscalationsRaised
            quantity={Number(data[timeline].escalationsRaised)}
            total={
              Number(data[timeline].escalationsPrevented) +
              Number(data[timeline].escalationsRaised)
            }
          />
        </Styled.Visual>

        <Styled.SectionRow>
          <Styled.IndexColour type="prevented" />
          <Styled.DarkText>
            {data[timeline].escalationsPrevented}
          </Styled.DarkText>
          <Styled.LightText>
            {t('preventedEscalations.escalations prevented')}
          </Styled.LightText>
        </Styled.SectionRow>

        <Styled.SectionRow>
          <Styled.IndexColour type="raised" />
          <Styled.DarkText>{data[timeline].escalationsRaised}</Styled.DarkText>
          <Styled.LightText>
            {t('preventedEscalations.escalations raised')}
          </Styled.LightText>
        </Styled.SectionRow>
      </Styled.Section>
      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
