import React from 'react';
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';
import { OutputType } from '../utils';
import ECoachResponse from '../cardTemplates/ECoachResponse';
import ClickableListCard from '../ClickableListCard';
import SuggestionItemDetails from './SuggestionItemDetails';
import SuggestionItem from './SuggestionItem';
import ReturnToSessionButton from './ReturnToSessionButton';

interface StepOutput {
  output_type: number;
  suggestion_items?: any[];
}

interface SuggestionItemsCardProps {
  activeSession?: string;
  stepOutput?: StepOutput;
}

// Wrapper components to match ClickableListCard's expected props
const ListItemWrapper = ({ item }: { item: any }) => (
  <SuggestionItem item={item} />
);
const ItemDetailsWrapper = ({ item }: { item: any }) => (
  <SuggestionItemDetails item={item} />
);

export default function SuggestionItemsCard({
  activeSession,
  stepOutput,
}: SuggestionItemsCardProps) {
  const { items, selectedItem, selectItem } = useEcoachItemsConsumer(
    activeSession || '',
    stepOutput || { output_type: OutputType.SUGGESTIONS }
  );

  return (
    <ECoachResponse>
      <ReturnToSessionButton session={activeSession} />
      <ClickableListCard
        items={items.data || []}
        selectedItem={selectedItem}
        selectItem={selectItem}
        ListItemComponent={ListItemWrapper}
        ItemDetailsComponent={ItemDetailsWrapper}
      />
    </ECoachResponse>
  );
}
