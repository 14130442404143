import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { ReactComponent as Smiley } from 'components/pageinsights/_assets/smiley.svg';

import * as Styled from 'components/pageinsights/_styles/Satisfaction.style';
import * as Text from 'components/_styles/Text.style';

export default function Satisfaction() {
  const { t } = useTranslation('pageInsights');
  return (
    <Styled.Card>
      <Text.P2Semibold>{t('satisfaction.Satisfaction')}</Text.P2Semibold>
      <Styled.Section>
        <Smiley style={{ height: '65px' }} />
        <Styled.Stats>
          <Styled.TopStat>97%</Styled.TopStat>
          <Styled.TopStatDetail>
            {t('satisfaction.Rated Nurau as helpful')}
          </Styled.TopStatDetail>
        </Styled.Stats>
      </Styled.Section>
    </Styled.Card>
  );
}
