import React, { useState } from 'react';
import { Arrow } from 'untitledui-js';
import { useTranslation } from 'react-i18next';

import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';

import TimelineToggle from 'components/pageinsights/demo/TimelineToggle';

import * as Styled from 'components/pageinsights/_styles/Conversations.style';
import * as Text from 'components/_styles/Text.style';

// Add this interface before TimelineData
interface ChartDataItem {
  conversations: number;
}

interface TimelineData {
  topStat: string;
  pastPercent: string;
  pastLabel: string;
  chartData: ChartDataItem[];
}

interface TimelineDataMap {
  oneMonth: TimelineData;
  threeMonth: TimelineData;
  sixMonth: TimelineData;
  oneYear: TimelineData;
  all: TimelineData;
  [key: string]: TimelineData;
}

export default function Conversations(): React.ReactElement {
  const { t } = useTranslation('pageInsights');
  const [timeline, setTimeline] = useState<string>('oneMonth');

  const data: TimelineDataMap = {
    oneMonth: {
      topStat: '821',
      pastPercent: '100%',
      pastLabel: t('pastLabel.past month'),
      chartData: [
        { conversations: 111 },
        { conversations: 222 },
        { conversations: 333 },
        { conversations: 555 },
        { conversations: 821 },
      ],
    },
    threeMonth: {
      topStat: '1834',
      pastPercent: '45%',
      pastLabel: t('pastLabel.past 3 months'),
      chartData: [
        { conversations: 190 },
        { conversations: 200 },
        { conversations: 780 },
        { conversations: 1000 },
        { conversations: 1834 },
      ],
    },
    sixMonth: {
      topStat: '3854',
      pastPercent: '48%',
      pastLabel: t('pastLabel.past six months'),
      chartData: [
        { conversations: 390 },
        { conversations: 900 },
        { conversations: 1280 },
        { conversations: 2200 },
        { conversations: 3854 },
      ],
    },
    oneYear: {
      topStat: '5846',
      pastPercent: '66%',
      pastLabel: t('pastLabel.past year'),
      chartData: [
        { conversations: 1190 },
        { conversations: 2000 },
        { conversations: 3780 },
        { conversations: 5000 },
        { conversations: 5846 },
      ],
    },
    all: {
      topStat: '7805',
      pastPercent: '75%',
      pastLabel: t('pastLabel.all time'),
      chartData: [
        { conversations: 1190 },
        { conversations: 2000 },
        { conversations: 3780 },
        { conversations: 5000 },
        { conversations: 7805 },
      ],
    },
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>{t('Conversations')}</Text.P2Semibold>
      <Styled.Section>
        <Styled.TopStat>{data[timeline].topStat}</Styled.TopStat>
        <Styled.TopStatDetail>
          <Arrow.ArrowUp />
          <Styled.TopStatDetailNumber>
            {data[timeline].pastPercent}
          </Styled.TopStatDetailNumber>
          <Styled.LightText>{data[timeline].pastLabel}</Styled.LightText>
        </Styled.TopStatDetail>
      </Styled.Section>

      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          width={500}
          height={400}
          data={data[timeline].chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Tooltip />
          <Area
            type="monotone"
            dataKey="conversations"
            stroke="#218F50"
            fill="#41C47A"
            fillOpacity={1}
          />
        </AreaChart>
      </ResponsiveContainer>

      <TimelineToggle timeline={timeline} setTimeline={setTimeline} />
    </Styled.Card>
  );
}
