import { useState, useEffect } from 'react';

const useAnimatedEllipsis = (interval: number = 500): string => {
  const [ellipsis, setEllipsis] = useState<string>('');

  useEffect(() => {
    const timer = setInterval(() => {
      setEllipsis((prev) => {
        if (prev.length >= 3) return '.';
        return `${prev}.`;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  return ellipsis;
};

export default useAnimatedEllipsis;
