import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useViewportHeight from 'hooks/useViewportHeight';
import useTourState from 'hooks/useTourState';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Container from 'components/sidePanel/_styles/DemoMessage.style';
import * as Text from 'components/_styles/Text.style';
import { Link } from '@mui/material';
import { Shapes } from 'untitledui-js';

export default function DemoMessage(): React.ReactElement {
  const { t } = useTranslation('sidePanel');
  const { data: user } = useUser();
  const [isTourOpen] = useTourState({
    isDemoUser: user?.is_demo,
  });
  const { setIsOpen, setCurrentStep, currentStep, steps } = useTour();

  const startTour = () => {
    setIsOpen(true);
    const isFinalStep = currentStep === steps.length - 1;
    if (isFinalStep) setCurrentStep(0);
  };

  useEffect(() => {
    setIsOpen(isTourOpen);
  }, [isTourOpen, setIsOpen]);

  useViewportHeight();

  if (!user?.is_demo) return <></>;

  return (
    <Container.DemoMessage>
      <Text.P2Semibold>
        <Trans
          i18nKey="demoBanner"
          t={t}
          components={[
            <Link
              key="0"
              href="https://meetings.hubspot.com/justin-lessard-wajcer"
              underline="none"
              target="_blank"
            />,
          ]}
          ns="pageLayout"
        />
      </Text.P2Semibold>
      <Buttons.Secondary onClick={startTour}>
        <Shapes.Star06 />
        {t('Take a tour')}
      </Buttons.Secondary>
    </Container.DemoMessage>
  );
}
