import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import DocumentRow from 'components/pageECoach/documentDisplay/DocumentRow';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import ShareButton from 'components/pageECoach/documentDisplay/DocumentShareButton';
import ReturnToSessionButton from 'components/pageECoach/suggestions/ReturnToSessionButton';

// hooks
import useApi from 'hooks/useApi';
import useBrowserDetection from 'hooks/useBrowserDetection';
import { isAndroid, isMobile } from 'react-device-detect';

// styles
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';
import * as Text from 'components/_styles/Text.style';

interface DocumentMetadata {
  url: string;
  title: string;
  publication_date?: string;
}

interface DocumentResponse {
  metadata: DocumentMetadata;
  url: string;
}

interface ApiData {
  status: string;
  response: DocumentResponse;
}

interface RouteParams {
  id: string;
}

export default function DocumentPage(): JSX.Element {
  const { data, callApi: getDocument } = useApi();
  const params = useParams<RouteParams>();
  const browser = useBrowserDetection();
  const { id } = params;
  const { t } = useTranslation('pageEcoach');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get('language');
  const source = queryParams.get('source');
  const docPageURL = `/documents/${id}/pdf?language=${language}`;

  useEffect(() => {
    getDocument(docPageURL, 'pdf');
  }, [getDocument, docPageURL]);

  if (data.status === 'loading') return <ApiLoading />;

  if (
    ['edge', 'ie'].includes(browser) ||
    isAndroid ||
    (browser === 'safari' && isMobile)
  )
    return (
      <DocumentRow
        language={language}
        onPage
        docParentId={id}
        bucketUrl={(data as ApiData).response.metadata.url}
        docTitle={(data as ApiData).response.metadata.title}
      />
    );

  return (
    <ErrorBoundary>
      <PageLayout.Page>
        <Styled.PageHeader>
          {source && <ReturnToSessionButton session={source} />}
          <ShareButton docURL={docPageURL} onPage />
        </Styled.PageHeader>
        <Text.P2 style={{ fontStyle: 'italic' }}>
          {t('documentDisplay.DocumentPage.Last updated doc')}{' '}
          {(data as ApiData).response.metadata?.publication_date}
        </Text.P2>
        <iframe
          src={(data as ApiData).response.url}
          style={{ width: '100%', height: '100vh' }}
        />
      </PageLayout.Page>
    </ErrorBoundary>
  );
}
