import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// components
import Card from 'components/pageinsights/Card';
import Loader from 'components/pageECoach/loaders/Loader';
import TopCard from 'components/pageinsights/TopCard';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageECoach/_styles/Insights.style';
import * as Text from 'components/_styles/Text.style';

interface Insight {
  site: string;
  [key: string]: any;
}

interface InsightsResponse {
  onboarding_rate: number;
  session_count: number;
  recent_sessions_count: number;
  insights: Insight[];
}

interface ApiResponse<T> {
  status: 'loading' | 'success' | 'error';
  response?: T;
}

const Insights: React.FC = () => {
  const { data: insights, callApi: getInsights } =
    useApi<ApiResponse<InsightsResponse>>();
  const { t } = useTranslation('pageInsights');

  useEffect(() => {
    getInsights(`${routesConfig.ECOACH.INSIGHTS}?days=30`);
  }, [getInsights]);

  if (insights?.status === 'loading') return <Loader inline />;
  if (insights?.status !== 'success')
    return (
      <Styled.TopCard>
        <Text.P3>
          {t(
            'insights.Your insights cannot be loaded right now. Please, try again later.'
          )}
        </Text.P3>
      </Styled.TopCard>
    );

  return (
    <>
      <TopCard
        onboardingRate={insights.response.onboarding_rate}
        sessionCount={insights.response.session_count}
        recentSessionsCount={insights.response.recent_sessions_count}
      />
      <Styled.TeamCardSection>
        {insights.response.insights.map((insight) => (
          <Card key={insight.site} insight={insight} />
        ))}
      </Styled.TeamCardSection>
    </>
  );
};

export default Insights;
