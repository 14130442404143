import React from 'react';
import { useTranslation } from 'react-i18next';

import { AreaChart, Area, ResponsiveContainer } from 'recharts';

import * as Styled from 'components/pageinsights/_styles/LabourCostReductions2.style';
import * as Text from 'components/_styles/Text.style';

export default function LabourCostReductions2() {
  const { t } = useTranslation('pageInsights');
  const data = {
    topStat: '0',
    pastPercent: '0%',
    pastLabel: t('pastLabel.past month'),
    chartData: [
      { onboarding: '111', costReductions: 111000 },
      { onboarding: '222', costReductions: 222000 },
      { onboarding: '333', costReductions: 333000 },
      { onboarding: '555', costReductions: 555000 },
      { onboarding: '800', costReductions: 800000 },
    ],
    chartData2: [
      { onboarding: '800', costReductions: 800000 },
      { onboarding: '912', costReductions: 912000 },
      { onboarding: '1123', costReductions: 1123000 },
      { onboarding: '1555', costReductions: 1555000 },
      { onboarding: '2000', costReductions: 2000000 },
    ],
  };

  return (
    <Styled.Card>
      <Text.P2Semibold>
        {t('labourCostReductions2.Labour cost reductions')}
      </Text.P2Semibold>
      <Styled.Section>
        <Styled.TopStat>$322,312.99</Styled.TopStat>
        <Styled.TopStatDetail>
          <Styled.LightText>
            {t('labourCostReductions2.cost reductions to date')}
          </Styled.LightText>
        </Styled.TopStatDetail>
      </Styled.Section>

      <Styled.SectionHeader>
        {t(
          'labourCostReductions2.Projected cost reductions based on onboarding'
        )}
      </Styled.SectionHeader>

      <Styled.Section>
        <Styled.Charts>
          <ResponsiveContainer width="75%" height={80}>
            <AreaChart
              height={200}
              data={data.chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Area
                type="monotone"
                dataKey="costReductions"
                stroke="#218F50"
                fill="#41C47A"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>

          <Styled.Checkpoint>
            <Styled.CheckpointChip>
              <Styled.CheckpointChipText>$322.3K</Styled.CheckpointChipText>
              <Styled.CheckpointChipDot />
            </Styled.CheckpointChip>
            <Styled.CheckpointLabel>
              <Styled.CheckpointLabelStat>123</Styled.CheckpointLabelStat>
              <Styled.CheckpointLabelText>
                {t('labourCostReductions2.Currently onboarded')}
              </Styled.CheckpointLabelText>
            </Styled.CheckpointLabel>
          </Styled.Checkpoint>

          <ResponsiveContainer width="25%" height={200}>
            <AreaChart
              height={200}
              data={data.chartData2}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Area
                type="monotone"
                dataKey="costReductions"
                stroke="#218F50"
                fill="#ABF5CB"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>

          <Styled.Checkpoint>
            <Styled.CheckpointChip>
              <Styled.CheckpointChipText>$751.8K</Styled.CheckpointChipText>
              <Styled.CheckpointChipDot />
            </Styled.CheckpointChip>
            <Styled.CheckpointLabel>
              <Styled.CheckpointLabelStat>150</Styled.CheckpointLabelStat>
              <Styled.CheckpointLabelText>Total</Styled.CheckpointLabelText>
            </Styled.CheckpointLabel>
          </Styled.Checkpoint>
        </Styled.Charts>
      </Styled.Section>
    </Styled.Card>
  );
}
