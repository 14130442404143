import React from 'react';
import { useTranslation } from 'react-i18next';

// demo
import Conversations from 'components/pageinsights/demo/Conversations';
import EngagementBySite from 'components/pageinsights/demo/EngagementBySite';
import Onboarding from 'components/pageinsights/demo/Onboarding';
import RegionalEscalationLandscape from 'components/pageinsights/demo/RegionalEscalationLandscape';
import GlobalEscalationLandscape from 'components/pageinsights/demo/GlobalEscalationLandscape';
import LabourCostReductions1 from 'components/pageinsights/demo/LabourCostReductions1';
import LabourCostReductions2 from 'components/pageinsights/demo/LabourCostReductions2';
import ReducedEscalations from 'components/pageinsights/demo/ReducedEscalations';
import Satisfaction from 'components/pageinsights/demo/Satisfaction';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';

const Insights: React.FC = () => {
  const { t } = useTranslation('pageInsights');
  return (
    <>
      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        <Buttons.Secondary centerText>{t('Export insights')}</Buttons.Secondary>
      </div>
      <Text.H3Bold>{t('Return on investment analysis')}</Text.H3Bold>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
        <LabourCostReductions1 />
        <LabourCostReductions2 />
      </div>

      <Text.H3Bold>
        {t('Escalation management and risk prevention')}
      </Text.H3Bold>
      <ReducedEscalations />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
        <GlobalEscalationLandscape />
        <RegionalEscalationLandscape />
      </div>

      <Text.H3Bold>{t('User engagement monitoring')}</Text.H3Bold>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <Onboarding />
          <EngagementBySite />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '30px',
          }}
        >
          <Satisfaction />
          <Conversations />
        </div>
      </div>
    </>
  );
};

export default Insights;
