import styled from 'styled-components';
import colours from 'constants/colours';

export const DemoMessage = styled('div')`
  background-color: ${colours.neutralWarm[300]};
  border-radius: 20px;
  box-sizing: border-box;
  color: ${colours.neutralWarm[800]};
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  top: 0;
  width: 100%;
  z-index: 10;

  svg {
    height: 20px;
    width: 20px;
  }
  svg path {
    fill: ${colours.neutralWarm[800]};
  }
`;

export default DemoMessage;
