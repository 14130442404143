import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// styles
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

function IntroCard(): JSX.Element {
  const { t } = useTranslation('pageAbout');

  const instructionRows = [
    t('intro.instruction1'),
    t('intro.instruction2'),
    t('intro.instruction3'),
  ];

  const getRow = (row: string, key: number): JSX.Element => (
    <Styled.InstructionRow key={key}>
      <GreenCheckIcon />
      <Text.P2>{row}</Text.P2>
    </Styled.InstructionRow>
  );

  return (
    <>
      <Styled.Content>
        <Styled.AboutSection>
          <Styled.AboutDescription>
            <Text.P3Semibold>{t('intro.subheader1')}</Text.P3Semibold>
            <Text.P2>{t('intro.about1')}</Text.P2>
            <Text.P2>{t('intro.about2')}</Text.P2>
          </Styled.AboutDescription>
        </Styled.AboutSection>
        <Styled.InstructionSection>
          <Text.P3Semibold>{t('intro.subheader2')}</Text.P3Semibold>
          {instructionRows.map((row, key) => getRow(row, key))}
        </Styled.InstructionSection>
      </Styled.Content>
    </>
  );
}

export default IntroCard;
