import styled from 'styled-components';
import colours from 'constants/colours';
import shadows from 'constants/dropShadows';

interface ToolbarButtonProps {
  active?: boolean;
}

// Header Section
export const Toolbar = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
`;

export const ToolbarButton = styled('div')<ToolbarButtonProps>`
  align-items: center;
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 30px;
  box-shadow: ${shadows.XS};
  color: ${(props) =>
    props.active ? colours.primary[600] : colours.neutralCool[500]};
  cursor: ${(props) => !props.active && 'pointer'};
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  gap: 5px;
  line-height: 18px;
  max-height: 44px;
  padding: 10px;
  pointer-events: ${(props) => props.active && 'none'};
  user-select: none;
  width: fit-content;

  svg {
    height: 15;
    width: 15;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${(props) =>
      props.active ? colours.primary[600] : colours.neutralCool[500]};
  }

  &:hover {
    color: ${(props) => !props.active && colours.neutralCool[800]};
    border: ${(props) =>
      !props.active && `0.5px solid ${colours.neutralWarm[500]};`};

    svg path {
      stroke: ${(props) =>
        props.active ? colours.primary[600] : colours.neutralCool[800]};
    }
  }

  &:focus {
    color: ${(props) => !props.active && colours.neutralCool[800]};
    border: ${(props) =>
      !props.active && `0.5px solid ${colours.neutralWarm[500]};`};
  }

  &:active {
    color: ${(props) => !props.active && colours.neutralCool[800]};
    border: ${(props) =>
      !props.active && `0.5px solid ${colours.neutralWarm[500]};`};
  }
`;
