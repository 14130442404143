import React from 'react';
import { TFunction } from 'i18next';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { FormControl, MenuItem } from '@mui/material';

interface LanguageOption {
  value: string;
  label: string;
}

interface LanguageFieldProps {
  t: TFunction;
  currentLanguage: string;
  handleLanguageChange: (language: string) => void;
}

const languageOptions: LanguageOption[] = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
];

export default function LanguageField({
  t,
  currentLanguage,
  handleLanguageChange,
}: LanguageFieldProps): JSX.Element {
  return (
    <FormControl fullWidth>
      <Field
        component={Select}
        name="language"
        label={t('Language')}
        value={currentLanguage}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          handleLanguageChange(event.target.value as string)
        }
      >
        {languageOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}
