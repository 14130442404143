import { useState, useEffect } from 'react';

/**
 * Custom hook to manage tour state with localStorage
 * @param options - Configuration options
 * @returns Tour open state and function to close the tour
 */
interface UseTourStateOptions {
  isDemoUser: boolean;
  storageKey?: string;
}

type UseTourStateReturn = [boolean, () => void];

const useTourState = ({
  isDemoUser,
  storageKey = 'hasCompletedTour',
}: UseTourStateOptions): UseTourStateReturn => {
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);

  // Check localStorage on mount to determine if tour should be shown
  useEffect(() => {
    if (isDemoUser) {
      const hasCompletedTour = localStorage.getItem(storageKey);
      if (!hasCompletedTour) {
        setIsTourOpen(true);
      }
    }
  }, [isDemoUser, storageKey]);

  // Function to handle tour completion
  const hasSeenTour = (): void => {
    localStorage.setItem(storageKey, 'true');
    setIsTourOpen(false);
  };

  return [isTourOpen, hasSeenTour];
};

export default useTourState;
