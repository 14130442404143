import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Communication, Users } from 'untitledui-js';

// styles
import * as Insights from 'components/pageECoach/_styles/Insights.style';
import * as Text from 'components/_styles/Text.style';

interface TopCardProps {
  onboardingRate: number;
  sessionCount: number;
  recentSessionsCount: number;
}

export default function TopCard({
  onboardingRate,
  sessionCount,
  recentSessionsCount,
}: TopCardProps): JSX.Element {
  const { t } = useTranslation('pageInsights');

  return (
    <Insights.TopCard>
      <Text.P2Semibold>{t('insights.Across your teams')}</Text.P2Semibold>
      <Insights.InsightsSection>
        <Insights.Insight>
          <Insights.InsightData>{onboardingRate}</Insights.InsightData>
          <Insights.InsightLabel>
            <Users.Users01 />
            <Text.P2Semibold>{t('insights.Onboarded')}</Text.P2Semibold>
          </Insights.InsightLabel>
        </Insights.Insight>
        <Insights.Insight>
          <Insights.InsightData>{sessionCount}</Insights.InsightData>
          <Insights.InsightLabel>
            <Communication.MessageTextSquare01 />
            <Text.P2Semibold>
              {t('insights.Total conversations')}
            </Text.P2Semibold>
          </Insights.InsightLabel>
        </Insights.Insight>
        <Insights.Insight>
          <Insights.InsightData>{recentSessionsCount}</Insights.InsightData>
          <Insights.InsightLabel>
            <Communication.MessageTextSquare01 />
            <Text.P2Semibold>{t('insights.Past 30 days')}</Text.P2Semibold>
          </Insights.InsightLabel>
        </Insights.Insight>
      </Insights.InsightsSection>
    </Insights.TopCard>
  );
}
