import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import LogoText from 'assets/logoWhiteText.svg';

// components
import { Stack } from '@mui/material';

// helpers
import resetApp from 'helpers/resetApp';

// styles
import * as Styled from 'components/sidePanel/_styles/TopCard.style';
import * as Text from 'components/_styles/Text.style';
import { P2Semibold } from 'components/_styles/Text.style';

interface TopCardProps {
  allConversationsLength?: number;
  demoTour?: string;
  inProgressLength?: number;
  setHubSelection?: (selection: string) => void;
  user: {
    nickname?: string;
    first_name: string;
    last_name: string;
    organization: {
      name: string;
    };
  };
}

export default function TopCard({
  allConversationsLength,
  demoTour,
  inProgressLength,
  user,
}: TopCardProps): React.ReactElement {
  const { t } = useTranslation('sidePanel');

  return (
    <Stack className={demoTour} spacing={4}>
      <Styled.Card>
        <Styled.LogoText onClick={resetApp} src={LogoText} />
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <Text.P2>
            {user.nickname || user.first_name} {user.last_name[0]}.
          </Text.P2>
          <Text.P2
            style={{ borderLeft: '1px solid white', paddingLeft: '5px' }}
          >
            {user.organization.name}
          </Text.P2>
        </div>
        <Styled.Divider />
        <Styled.InsightsSection>
          <Styled.Insight>
            <Styled.InsightData>{allConversationsLength}</Styled.InsightData>
            <Styled.InsightLabel>
              <P2Semibold>{t('Conversations')}</P2Semibold>
            </Styled.InsightLabel>
          </Styled.Insight>
          <Styled.Insight>
            <Styled.InsightData>{inProgressLength}</Styled.InsightData>
            <Styled.InsightLabel>
              <P2Semibold>{t('In progress')}</P2Semibold>
            </Styled.InsightLabel>
          </Styled.Insight>
        </Styled.InsightsSection>
      </Styled.Card>
    </Stack>
  );
}
