import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// constants
import routesConfig from 'constants/routesConfig.json';

// components
import Card from 'components/pageECoach/allChats/Card';
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';
import EmptyState from 'components/pageECoach/allChats/EmptyState';
import { Stack } from '@mui/material';
import UnsubscribeConfirmation from 'components/pageECoach/allChats/UnsubscribeConfirmation';
import StatusTabs from 'components/pageECoach/allChats/StatusTabs';

// context
import { useConvoHistory } from 'contexts/ConvoHistoryContext';

// hooks
import useApi from 'hooks/useApi';
import useJumpToTop from 'hooks/useJumpToTop';

// styles
import * as Cards from 'components/pageECoach/_styles/Cards.style';

interface Chat {
  id: string;
  resolution_status: string;
  [key: string]: any;
}

interface AllChatsProps {
  activeSession: string;
  setActiveSession: (session: string | null) => void;
  setLoadingTrue: () => void;
  resetChatGuideData: () => void;
}

export default function AllChats({
  activeSession,
  setActiveSession,
  setLoadingTrue,
}: AllChatsProps): React.ReactElement {
  const { data: conversations, getConversations } = useConvoHistory();
  const { data: updatedSessions, callApi: updateSession } = useApi();
  const { callApi: unsubscribeFromEmail } = useApi();
  const { t } = useTranslation('pageEcoach');
  const history = useHistory();
  const posthog = usePostHog();
  const location = useLocation();
  const pageStartRef = useJumpToTop() as React.RefObject<HTMLDivElement>;
  const searchParams = new URLSearchParams(location.search);
  const unsubscribe = searchParams.get('unsubscribe');

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [activeTab, setActiveTab] = useState('All conversations');
  const [sessionId, setSessionId] = useState<string | undefined>();

  const closeDeleteModal = () => setIsOpenConfirmDialog(false);
  const openDeleteModal = (e: React.MouseEvent, pastSession: Chat) => {
    e.stopPropagation();
    setSessionId(pastSession.id);
    setIsOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (unsubscribe === 'true')
      unsubscribeFromEmail('/users/subscription/', {
        method: 'put',
        data: { subscribed: false },
      });
  }, [unsubscribe, unsubscribeFromEmail]);

  useEffect(() => {
    if (updatedSessions.status === 'success') getConversations();
  }, [getConversations, updatedSessions]);

  const hideSession = () => {
    if (sessionId) {
      updateSession(`${routesConfig.ECOACH.SESSIONS}${sessionId}/hide`, 'put');
      posthog?.capture('user_removed_session');
      if (sessionId === activeSession) setActiveSession(null);
      closeDeleteModal();
    }
  };

  const getSession = useCallback(
    (id: string) => {
      history.push(`${routesConfig.CONVO_PAGE}/${id}`);
    },
    [history]
  );

  const ongoingChats = conversations.filter(
    (chat: Chat) => chat.resolution_status === 'active'
  );

  const draftChats = conversations.filter(
    (chat: Chat) => chat.resolution_status === 'draft'
  );

  const otherChats = conversations.filter(
    (chat: Chat) => !['active', 'draft'].includes(chat.resolution_status)
  );

  return (
    <Stack spacing={4}>
      {unsubscribe && <UnsubscribeConfirmation />}
      <div ref={pageStartRef} />
      <StatusTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'All conversations' && (
        <Cards.MultiCardSection className="demo-tour-step-6">
          {conversations.length > 0 ? (
            conversations.map((chat, i) => (
              <Card
                chat={chat}
                getConversations={getConversations}
                getSession={getSession}
                index={i}
                key={chat.id}
                openDeleteModal={openDeleteModal}
                setLoadingTrue={setLoadingTrue}
              />
            ))
          ) : (
            <EmptyState tab="all conversations" />
          )}
        </Cards.MultiCardSection>
      )}

      {activeTab === 'In progress' && (
        <Cards.MultiCardSection className="demo-tour-step-6">
          {ongoingChats.length > 0 ? (
            ongoingChats.map((chat, i) => (
              <Card
                chat={chat}
                getConversations={getConversations}
                getSession={getSession}
                index={i}
                key={chat.id}
                openDeleteModal={openDeleteModal}
                setLoadingTrue={setLoadingTrue}
              />
            ))
          ) : (
            <EmptyState tab="in progress" />
          )}
        </Cards.MultiCardSection>
      )}

      {activeTab === 'Resolved' && (
        <Cards.MultiCardSection>
          {otherChats.length > 0 ? (
            otherChats.map((chat, i) => (
              <Card
                chat={chat}
                getConversations={getConversations}
                getSession={getSession}
                index={i}
                key={chat.id}
                openDeleteModal={openDeleteModal}
                setLoadingTrue={setLoadingTrue}
              />
            ))
          ) : (
            <EmptyState tab="resolved" />
          )}
        </Cards.MultiCardSection>
      )}

      {activeTab === 'Drafts' && (
        <Cards.MultiCardSection>
          {draftChats.length > 0 ? (
            draftChats.map((chat, i) => (
              <Card
                chat={chat}
                getConversations={getConversations}
                getSession={getSession}
                index={i}
                key={chat.id}
                openDeleteModal={openDeleteModal}
                setLoadingTrue={setLoadingTrue}
              />
            ))
          ) : (
            <EmptyState tab="drafts" />
          )}
        </Cards.MultiCardSection>
      )}

      {isOpenConfirmDialog && (
        <ConfirmDialog
          cancelLabel={t('No')}
          successLabel={t('Yes')}
          successCallback={() => hideSession()}
          contentText={t(
            'allChats.Are you sure you want to delete this session?'
          )}
          handleClose={closeDeleteModal}
          isOpen={true}
        />
      )}
    </Stack>
  );
}
