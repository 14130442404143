import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border: 0.5px solid ${colours.neutralWarm[200]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  min-width: 350px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    border: none;
    box-shadow: ${shadows.XS};
    min-width: unset;
  }
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopStat = styled(Text.H3Extrabold)`
  color: ${colours.neutralWarm[800]};
`;

export const TopStatDetail = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const Divider = styled('div')`
  border-bottom: 0.5px solid ${colours.neutralWarm[300]};
  flex: 1;
`;

export const SectionHeader = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
  font-weight: 900;
`;

export const SectionRow = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const LightText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[700]};
`;

export const DarkText = styled(Text.P1Semibold)`
  color: ${colours.neutralWarm[800]};
`;

export default Card;
