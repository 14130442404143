import type { CSSProperties } from 'react';
import React from 'react';
import routesConfig from 'constants/routesConfig.json';
import { useTranslation } from 'react-i18next';

// assets
import LogoText from 'assets/logoBlackText.svg';

// styles
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

// hooks
import { useUser } from 'contexts/UserContext';
import useTourState from './useTourState';

// Define a History interface if it's not available
interface History {
  push: (path: string | { search: string }) => void;
  replace: (path: string) => void;
}

interface TourStep {
  position?: 'center' | 'top' | 'right' | 'bottom' | 'left';
  selector?: string;
  content: React.ReactNode;
  action?: () => void;
  styles?: {
    maskArea?: (base: CSSProperties) => CSSProperties & { rx?: number };
    [key: string]:
      | ((base: CSSProperties) => CSSProperties & { rx?: number })
      | undefined;
  };
  stepInteraction?: boolean;
}

const includeLogo = (content: React.ReactNode): React.ReactElement => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
    <img src={LogoText} style={{ width: '150px' }} />
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {content}
    </div>
  </div>
);

const includeText = (content: React.ReactNode): React.ReactElement => (
  <Text.P2>{content}</Text.P2>
);

type TFunction = (key: string) => string;

interface TourContent {
  [key: string]: (t: TFunction) => React.ReactNode;
}

const tourContent: TourContent = {
  step1: (t) =>
    includeLogo(
      <>
        <Text.H5Semibold>{t('tour.Welcome to Nurau!')}</Text.H5Semibold>
        <Text.P2>
          {t(
            "tour.We combine emotional intelligence with your company's policies to help you lead effectively."
          )}
        </Text.P2>
      </>
    ),
  step2: (t) =>
    includeLogo(
      <Text.P2>
        {t(
          "tour.We'll be your guide through challenging conversations, providing real-time coaching to help you inspire and engage your team."
        )}
      </Text.P2>
    ),
  step3: (t) =>
    includeText(t('tour.This dashboard shows your engagement level.')),
  step4: (t) =>
    includeText(
      t(
        'tour.When you start a new conversation, it will show as active under All conversations.'
      )
    ),
  step5: (t) =>
    includeText(
      t(
        'tour.Here are your active conversations. Nurau will help you transform challenging situations into productive outcomes.'
      )
    ),
  step6: (t) =>
    includeText(
      t(
        'tour.Your conversations are completely private. Nothing you share will be seen by your manager or anyone at your company.'
      )
    ),
  step7: (t) =>
    includeText(
      t(
        'tour.Looking to hone in on key leadership skills? Tap into Journeys, our series of short microlearnings.'
      )
    ),
  step8: (t) =>
    includeText(
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Text.P2>
          {t(
            'tour.Click here to view your insights dashboard, where you will find key data, like your'
          )}
        </Text.P2>
        <Text.P2>
          <b>{t('tour.Return on investment analysis')}</b>
          {t(
            "tour.Key stats about your company's labour cost reductions in comparison to other companies in your industry."
          )}
        </Text.P2>
        <Text.P2>
          <b>{t('tour.Escalation management and risk prevention')}</b>
          {t(
            'tour.Be the first to know about site specific challenges and risks, along with how to best mitigate them.'
          )}
        </Text.P2>
        <Text.P2>
          <b>{t('tour.User engagement monitoring')}</b>
          {t('tour.Satisfaction and platform usage.')}
        </Text.P2>
        <div
          style={{
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            padding: '20px',
            backgroundColor: colours.blue[50],
          }}
        >
          <Text.P2>
            {t(
              "tour.You're on a demo account, so we've pre-populated your dashboard with mock data in order for you to get an idea of the sorts of insights you will get."
            )}
          </Text.P2>
        </div>
      </div>
    ),
  step9: (t) =>
    includeText(
      t(
        "tour.Ready to get started? Choose an active conversation from your list that you'd like guidance on. Whether it's addressing performance, handling conflicts, or having sensitive discussions, we are here to help."
      )
    ),
  step10: (t) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Text.P2>
        {t(
          "tour.You're on a demo account, so we've added some example conversations for you to play with."
        )}
      </Text.P2>
      <Text.P2>
        {t('tour.Select what you like to do with this conversation:')}
      </Text.P2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.mint[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t(
              "tour.Mark as complete - if you've finished addressing this situation"
            )}
          </Text.P2>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.warning[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t("tour.Keep in progress - if you'll need more guidance later")}
          </Text.P2>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '5px',
              backgroundColor: `${colours.neutralCool[500]}`,
              borderRadius: '30px',
            }}
          ></div>
          <Text.P2>
            {t('tour.Remove from goals - if this is no longer a priority')}
          </Text.P2>
        </div>
      </div>
      <div style={{ height: '60px' }}></div>
    </div>
  ),
};

interface TranslatedContentProps {
  contentKey: string;
}

const TranslatedContent: React.FC<TranslatedContentProps> = ({
  contentKey,
}) => {
  const { t } = useTranslation('pageDemoRegistration');
  return <>{tourContent[contentKey](t)}</>;
};

export default function useDemoProductTourSteps(history: History): TourStep[] {
  const { data: user } = useUser();
  const [, hasSeenTour] = useTourState({
    isDemoUser: user?.is_demo,
  });

  const hideDot = {
    maskArea: (base: CSSProperties): CSSProperties & { rx?: number } => ({
      ...base,
      display: 'none', // Hides the mask for this step
    }),
  };

  const tourStartActions = () => {
    hasSeenTour();
    history.replace(routesConfig.ALL_CONVOS_PAGE);
  };

  const showSidePanel = (show?: boolean) => {
    history.push(`${routesConfig.ALL_CONVOS_PAGE}?showpanel=${show}`);
  };

  const tourSteps: TourStep[] = [
    {
      position: 'center',
      content: <TranslatedContent contentKey="step1" />,
      action: () => tourStartActions(),
      styles: hideDot,
    },
    {
      position: 'center',
      content: <TranslatedContent contentKey="step2" />,
      styles: hideDot,
      action: () => showSidePanel(true),
    },
    {
      selector: '.demo-tour-step-3',
      content: <TranslatedContent contentKey="step3" />,
      action: () => showSidePanel(true),
    },
    {
      selector: '.demo-tour-step-4',
      content: <TranslatedContent contentKey="step4" />,
      action: () => showSidePanel(true),
    },
    {
      selector: '.demo-tour-step-5',
      content: <TranslatedContent contentKey="step5" />,
      action: () => showSidePanel(true),
    },
    {
      selector: '.demo-tour-step-6',
      content: <TranslatedContent contentKey="step6" />,
      action: () => showSidePanel(false),
    },
    {
      selector: '.demo-tour-step-7',
      content: <TranslatedContent contentKey="step7" />,
      stepInteraction: true,
      action: () => showSidePanel(true),
    },
    {
      selector: '.demo-tour-step-8',
      content: <TranslatedContent contentKey="step8" />,
      stepInteraction: true,
      action: () => showSidePanel(true),
    },
    {
      position: 'center',
      content: <TranslatedContent contentKey="step9" />,
      action: () => showSidePanel(false),
      styles: hideDot,
    },
    {
      selector: '.demo-tour-step-10',
      content: <TranslatedContent contentKey="step10" />,
      action: () => showSidePanel(false),
    },
  ];

  return tourSteps;
}
