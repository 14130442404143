import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ClickableInput from 'components/pageECoach/ClickableInput';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';

// hooks
import { useUser } from 'contexts/UserContext';

// styles
import * as ChatStartStyle from 'components/pageECoach/_styles/ChatStart.style';

interface ChatLandingProps {
  activatePolicyHelper: () => void;
  onSubmit: (text: string) => void;
  startConvoPrep: () => void;
  openPromptHelper: () => void;
}

export default function ChatLanding({
  activatePolicyHelper,
  onSubmit,
  startConvoPrep,
  openPromptHelper,
}: ChatLandingProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  const emergencyLink = (): void => {
    const docPageURL = `/documents/195b172d-f368-4e53-83af-a634027ef450/pdf?language=${user.language}`;
    window.open(docPageURL, '_blank');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <ChatStartStyle.Section>
        <StyledPromptControls.ButtonsColumn>
          <ClickableInput
            inputAction={startConvoPrep}
            inputText={t('chatStart.ChatLanding.Prepare for a conversation')}
          />
          <ClickableInput
            inputAction={activatePolicyHelper}
            inputText={t('chatStart.ChatLanding.Get answers about policies')}
          />
          <ClickableInput
            inputAction={openPromptHelper}
            inputText={t('chatStart.ChatLanding.Explore other ideas')}
          />
          {user.organization?.name === 'Ardene' && (
            <ClickableInput
              inputAction={emergencyLink}
              inputText={t(
                'chatStart.ChatLanding.Click here in case of emergency'
              )}
            />
          )}
        </StyledPromptControls.ButtonsColumn>
      </ChatStartStyle.Section>
      <Chatbar autoFocus onSubmit={onSubmit} />
    </div>
  );
}
