import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Card = styled(Cards.Card)`
  align-items: left;
  background: linear-gradient(279deg, #36475a 1.35%, #1a2634 102.58%);
  background-position: center;
  background-size: cover;
  height: unset;
  flex: 1;
  gap: 20px;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px;

  h3,
  p {
    color: ${colours.neutralCool[50]};
  }

  svg path {
    stroke: ${colours.neutralCool[50]};
  }

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const LogoText = styled('img')`
  box-sizing: unset;
  cursor: pointer;
  height: 22px;
  width: 100px;
`;

export const Divider = styled('div')`
  border-bottom: 0.5px solid ${colours.neutralCool[600]};
  display: flex;
`;

export const InsightsSection = styled('div')`
  display: flex;
  gap: 60px;
`;

export const Insight = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InsightLabel = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;

  svg {
    height: 17px;
    width: 17px;
  }
`;

export const InsightData = styled(Text.H3Bold)`
  font-size: 30px;
  line-height: 30px;
`;
