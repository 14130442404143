/**
 * Returns the appropriate support link based on the user's language
 * @param language - The user's language code ('en' for English, any other value for French)
 * @returns The support link URL string
 */
export default function supportLink(language: string): string {
  return language === `en`
    ? `https://nurau.atlassian.net/servicedesk/customer/portal/1/article/561938434`
    : `https://nurau.atlassian.net/servicedesk/customer/portal/1/article/604766221`;
}
