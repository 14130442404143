import React from 'react';

// components
import { General } from 'untitledui-js';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import colours from 'constants/colours';

interface Chat {
  id: string;
  resolution_status: string;
  created_at: string;
  modified_at: string;
  [key: string]: any;
}

interface DeleteButtonProps {
  chat: Chat;
  openDeleteModal: (e: React.MouseEvent, chat: Chat) => void;
}

export default function DeleteButton({
  chat,
  openDeleteModal,
}: DeleteButtonProps): JSX.Element {
  return (
    <AllChats.DeleteIcon>
      <General.Trash01
        onClick={(e: React.MouseEvent) => openDeleteModal(e, chat)}
        stroke={colours.neutralCool[600]}
        size="16"
        whileHover={colours.primary[500]}
      />
    </AllChats.DeleteIcon>
  );
}
