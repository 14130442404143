import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import colours from 'constants/colours';

interface SelectInputProps {
  selectopen: string;
}

export const Input = styled(TextField)`
  & > div {
    background-color: white;
    border-radius: 25px;
  }
`;

export const SelectInput = styled(TextField)<SelectInputProps>`
  & > div {
    background-color: white;
    border-radius: ${(props) =>
      props.selectopen === 'true' ? '25px 25px 0 0' : '25px'};
  }
`;

export const OtherPronounsInput = styled(TextField)`
  & > div {
    background-color: white;
    border-radius: 25px;
  }
`;

export const ListItem = styled(MenuItem)`
  background-color: #f5f5f3;
  color: ${colours.neutralCool[900]};
  font-weight: 500;
  font-size: 16px;
`;

export default Input;
