import styled from 'styled-components';
import { P2 } from 'components/_styles/Text.style';

export const AboutDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DescriptionText = styled(P2)`
  margin-top: 10px;
`;

export const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;
`;

export const ListRow = styled.div`
  display: flex;
  gap: 20px;
`;

export const ListIcon = styled.img`
  object-fit: contain;
  width: 45px;
`;

export const ListDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BottomSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonSection = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
`;

export default AboutDescription;
