import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import Message from 'components/pageECoach/_assets/message-icon.png';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

interface EmptyStateProps {
  tab: string;
}

export default function EmptyState({ tab }: EmptyStateProps): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  const getTitle = () => {
    if (tab === 'all conversations')
      return t('allChats.EmptyState.allConversationsTitle');
    if (tab === 'in progress') return t('allChats.EmptyState.inProgressTitle');
    if (tab === 'resolved') return t('allChats.EmptyState.resolvedTitle');
    if (tab === 'drafts') return t('allChats.EmptyState.draftsTitle');
    return '';
  };

  const getText = () => {
    if (tab === 'all conversations')
      return t('allChats.EmptyState.allConversations');
    if (tab === 'in progress') return t('allChats.EmptyState.inProgress');
    if (tab === 'resolved') return t('allChats.EmptyState.resolved');
    if (tab === 'drafts') return t('allChats.EmptyState.drafts');
    return '';
  };

  return (
    <AllChats.EmptyStateContainer>
      <AllChats.EmptyStateIcon src={Message} />
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Text.H6Bold>{getTitle()}</Text.H6Bold>
        <Text.P3>{getText()}</Text.P3>
      </div>
    </AllChats.EmptyStateContainer>
  );
}
