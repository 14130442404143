import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import useApi from 'hooks/useApi';
import routesConfig from 'constants/routesConfig.json';
import { useUser } from 'contexts/UserContext';

// Define interfaces for the context
interface ConversationData {
  response: any; // Replace with a more specific type if available
}

interface ConvoHistoryContextType {
  data: any; // Replace with a more specific type if available
  getConversations: () => Promise<void>;
}

interface ConvoHistoryProviderProps {
  children: ReactNode;
}

// Create the context with a default value
const ConvoHistoryContext = createContext<ConvoHistoryContextType>({
  data: [], // Initialize as empty array instead of null
  getConversations: () => Promise.resolve(),
});

// Hook to use the context
const useConvoHistory = () => useContext(ConvoHistoryContext);

// Provider component
function ConvoHistoryProvider({
  children,
}: ConvoHistoryProviderProps): React.ReactElement {
  const { data, callApi } = useApi<ConversationData>();
  const { data: user } = useUser();

  const getConversations = useCallback(async (): Promise<void> => {
    if (user) {
      await callApi(routesConfig.ECOACH.SESSIONS);
    }
  }, [callApi, user]);

  // Fetch conversations only if user exists
  useEffect(() => {
    if (user) {
      getConversations();
    }
  }, [getConversations, user]);

  return (
    <ConvoHistoryContext.Provider
      value={{
        data: data.response || [], // Provide empty array as fallback
        getConversations,
      }}
    >
      {children}
    </ConvoHistoryContext.Provider>
  );
}

export { ConvoHistoryContext, useConvoHistory, ConvoHistoryProvider };
