import useWebSocket from 'react-use-websocket';

interface AuthToken {
  token: string;
}

export default function useWebsocketSetup(socketUrl: string) {
  const authData = localStorage.getItem('authorization');
  const authToken: AuthToken = authData ? JSON.parse(authData) : { token: '' };

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    process.env.REACT_APP_WEBSOCKET_URL + socketUrl,
    {
      onOpen: () => console.log('WebSocket Connected'),
      onError: (event: WebSocketEventMap['error']) =>
        console.log('WebSocket error:', event),
      onMessage: (event: WebSocketEventMap['message']) =>
        console.log('WebSocket message:', event),
      queryParams: { token: authToken.token },
      // Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: () => true,
    }
  );

  return { sendJsonMessage, lastJsonMessage, readyState };
}
