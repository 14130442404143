import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Card1 from 'components/pageOnboarding/assets/card1.png';

// styles
import * as Styled from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

interface IdentityFormIntroCardProps {
  markComplete: (step: number) => void;
}

function IdentityFormIntroCard({
  markComplete,
}: IdentityFormIntroCardProps): JSX.Element {
  const { t } = useTranslation('pageOnboarding');

  const nextPageClick = (): void => markComplete(1);

  return (
    <Styled.Card>
      <Styled.ImageArea>
        <Styled.ImageOverflowed src={Card1} />
      </Styled.ImageArea>
      <Styled.BottomSection>
        <Styled.InnerSection>
          <Text.H5Bold>{t('unique')}</Text.H5Bold>
        </Styled.InnerSection>
      </Styled.BottomSection>
      <Styled.Navigation>
        <div></div>
        <Styled.NavButton aria-label={t('next')} onClick={nextPageClick}>
          <NavigateNextIcon />
        </Styled.NavButton>
      </Styled.Navigation>
    </Styled.Card>
  );
}

export default IdentityFormIntroCard;
