import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

export default function UnsubscribeConfirmation(): JSX.Element {
  const { t } = useTranslation('pageEcoach');

  const [hideNotification, setHideNotification] = useState<boolean>(false);

  if (hideNotification) return <></>;
  return (
    <AllChats.Notification>
      <Text.P2Semibold>
        {t(
          "allChats.UnsubscribeConfirmation.You've successfully unsubscribed from your weekly review."
        )}
      </Text.P2Semibold>
      <General.XClose onClick={() => setHideNotification(true)} />
    </AllChats.Notification>
  );
}
